import type { Plan } from '~/plan';

export const plans: Plan[] = [
  {
    id: 'starter',
    name: 'Starter',
    priceText: '£99 / month',
    features: [
      'Up to 10 members',
      'Unlimited contents',
      'Email notifications',
      'Real-time updates',
      'Auto-generated summaries',
    ],
    notIncludedFeatures: [
      'SMS notifications',
      'Mobile App (iOS)',
      'Audio summaries',
      'Multiple languages',
    ],
  },
  {
    id: 'essential',
    name: 'Essential',
    priceText: '£199 / month',
    isPopular: true,
    features: [
      'Up to 50 members',
      'Unlimited contents',
      'Email notifications',
      'Real-time updates',
      'Auto-generated summaries',
      'SMS notifications',
      'Mobile App (iOS)',
      'Audio summaries',
      'Multiple languages',
    ],
    notIncludedFeatures: [],
  },
  {
    id: 'custom',
    name: 'Custom',
    priceText: 'Contact Sales',
    features: [
      'Unlimited members',
      'Custom org structure',
      'Custom notifications',
      'Custom reports',
      'Custom integrations',
      'Advanced analytics',
      'Training & onboarding',
      'Priority support',
    ],
    description: 'Customise your plan to fit your needs',
  },
];

export const planLevels = ['starter', 'enterprise'];

export const getPlanLevel = (planId: string) => {
  const index = planLevels.indexOf(planId);

  return index;
};

export const requiresPlanLevel = (
  planId: string | null | undefined,
  requiredPlanLevel: number,
) => {
  if (!planId) return false;

  const planLevel = getPlanLevel(planId);

  return planLevel >= requiredPlanLevel - 1;
};
