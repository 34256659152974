import {
  useMediaDeviceSelect,
  usePersistentUserChoices,
} from '@livekit/components-react';
import { VideoCodec } from 'livekit-client';
import { useEffect } from 'react';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { getMeetingConnection } from '~/api';
import { useUser } from '~/auth/hooks';
import { ConnectionDetails } from '~/livekit';

const hqStorage = localStorage.getItem('meeting-hq');
const codecStorage = localStorage.getItem('meeting-codec');

const initialState = {
  hasJoined: false,
  userName: localStorage.getItem('meeting-user-name') || '',
  connection: null as ConnectionDetails | null,
  hq: hqStorage ? hqStorage === 'true' : true,
  codec: (codecStorage || 'vp9') as VideoCodec,
  justLeftMeeting: false,
};

export const meetingsStore = create(
  combine(initialState, (set) => ({
    setUserName: (name: string) => {
      localStorage.setItem('meeting-user-name', name);
      set({ userName: name });
    },

    join: async ({ meetingId }: { meetingId: string }) => {
      const connection = await getMeetingConnection({ meetingId });

      localStorage.setItem('meeting-connection', JSON.stringify(connection));
      localStorage.setItem('meeting-joined', 'true');

      set({ connection, hasJoined: true });
    },

    leave: () => {
      localStorage.removeItem('meeting-joined');
      localStorage.removeItem('meeting-connection');
      set({ hasJoined: false, connection: null, justLeftMeeting: true });
    },
  })),
);

export function useMeetings() {
  const store = meetingsStore();
  const { user } = useUser();
  const { devices: videoDevices } = useMediaDeviceSelect({
    kind: 'videoinput',
  });
  const { devices: audioDevices } = useMediaDeviceSelect({
    kind: 'audioinput',
  });
  const { devices: audioOutputDevices } = useMediaDeviceSelect({
    kind: 'audiooutput',
  });
  const {
    saveVideoInputEnabled,
    saveVideoInputDeviceId,
    saveAudioInputEnabled,
    saveAudioInputDeviceId,
    userChoices: {
      videoDeviceId: userVideoDeviceId,
      videoEnabled,
      audioDeviceId: userAudioDeviceId,
      audioEnabled,
    },
  } = usePersistentUserChoices();

  const getFirstAudioDeviceId = () => {
    let deviceId: string | null = null;

    audioDevices.forEach((device) => {
      if (device.deviceId === 'default') {
        deviceId = device.deviceId;
      }
    });

    if (!deviceId) {
      deviceId = audioDevices[0]?.deviceId || null;
    }

    return deviceId;
  };

  const firstVideoDeviceId = videoDevices[0]?.deviceId || null;
  const firstAudioDeviceId = getFirstAudioDeviceId();
  const videoDeviceId = userVideoDeviceId || firstVideoDeviceId;
  const audioDeviceId = userAudioDeviceId || firstAudioDeviceId;

  useEffect(() => {
    store.setUserName(user.name);
  }, [user.name]); // eslint-disable-line

  return {
    ...store,
    videoDevices,
    audioDevices,
    audioOutputDevices,
    firstVideoDeviceId,
    firstAudioDeviceId,
    videoDeviceId,
    audioDeviceId,
    videoEnabled,
    audioEnabled,
    saveVideoInputEnabled,
    saveVideoInputDeviceId,
    saveAudioInputEnabled,
    saveAudioInputDeviceId,
  };
}
