import { useState } from 'react';

export function useSelectMembers<
  M extends { id: string } | { member: { id: string } },
>(members: M[]) {
  const [memberIds, _setMemberIds] = useState(
    members.map((member) => {
      if ('member' in member) {
        return member.member.id;
      }

      return member.id;
    }),
  );

  const setMemberId = (memberId: string) => {
    _setMemberIds((prevMemberIds) => {
      if (prevMemberIds.includes(memberId)) {
        return prevMemberIds.filter(
          (prevMemberId) => prevMemberId !== memberId,
        );
      }

      return [...prevMemberIds, memberId];
    });
  };

  return {
    memberIds,
    setMemberId,
  };
}
