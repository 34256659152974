import { RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react';
import { Outlet } from 'react-router-dom';
import { OrgGuard } from '~/org/components';
import { PlanGuard } from '~/plan/components';

export const DashboardGuard = () => {
  return (
    <>
      <SignedIn>
        <OrgGuard>
          <PlanGuard>
            <Outlet />
          </PlanGuard>
        </OrgGuard>
      </SignedIn>

      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
};
