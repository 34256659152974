import { z } from 'zod';

/////////////////////////////////////////
// INCIDENT AUDIO SCHEMA
/////////////////////////////////////////

export const IncidentAudioSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  incidentId: z.string(),
  languageId: z.string(),
  status: z.string(),
  text: z.string(),
})

export type IncidentAudio = z.infer<typeof IncidentAudioSchema>

export default IncidentAudioSchema;
