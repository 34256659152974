import { defineCopy } from '~/copy';

export default {
  createOrg: defineCopy({
    default: 'Create organisation',
    'de-DE': 'Organisation erstellen',
    'es-ES': 'Crear organización',
    'it-IT': 'Crea organizzazione',
    'fr-FR': 'Créer une organisation',
  }),
};
