import { Outlet } from 'react-router-dom';
import { Banks } from '~/bank/components';
import { useHasBankConnections } from '~/bank/hooks';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';

export const BanksGuard = () => {
  const { isLoading, hasConnection } = useHasBankConnections();
  const copy = useCopy();

  if (isLoading) {
    return (
      <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
        <div className="h-10 w-10">
          <div className="loader" />
        </div>
      </div>
    );
  }

  if (!hasConnection) {
    return (
      <DashboardLayout headerTitle={copy.get('connectToBank')} size="sm">
        <Banks />
      </DashboardLayout>
    );
  }

  return (
    <>
      <Outlet />
    </>
  );
};
