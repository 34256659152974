import { FrequencySelector } from '~/components';
import { useCopy } from '~/copy/hooks';

export const FormFrequency = ({
  frequency,
  setFrequency,
  omitOneTime = false,
}: {
  frequency: string;
  setFrequency: (value: string) => void;
  omitOneTime?: boolean;
}) => {
  const copy = useCopy();

  return (
    <div className="space-y-4">
      <div>{copy.get('frequency')}</div>

      <div className="rounded-xl border p-6">
        <FrequencySelector
          frequency={frequency}
          setFrequency={setFrequency}
          omitOneTime={omitOneTime}
        />
      </div>
    </div>
  );
};
