import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Button, Separator, Switch } from '~/components';
import { useCopy } from '~/copy/hooks';
import { User } from '~/types';

export const MembersSelect = <M extends { member: User }>({
  members,
  memberIds,
  onMemberSelect,
  children,
  noMembers,
}: {
  members: M[];
  memberIds: string[];
  onMemberSelect: (memberId: string) => void;
  children?: React.ReactNode;
  noMembers?: React.ReactNode;
}) => {
  const copy = useCopy();
  const navigate = useNavigate();

  if (!members.length)
    return (
      noMembers || (
        <div className="mx-auto w-full max-w-5xl space-y-8 rounded-xl border p-8 text-center md:space-y-10">
          <h2 className="text-lg md:text-xl lg:text-2xl">
            {copy.get('inviteMember')}
          </h2>

          <div>
            <Button
              className="w-40 md:w-52"
              label="inviteMember"
              variant="outline"
              onMouseUp={() => navigate('/members/invite')}
            />
          </div>
        </div>
      )
    );

  return (
    <>
      <div className="overflow-hidden rounded-lg border">
        {members.map((member, memberIndex) => {
          const isSelected = memberIds.includes(member.member.id);

          return (
            <div
              className="cursor-pointer select-none hover:bg-muted/40"
              key={member.member.id}
              onClick={() => onMemberSelect(member.member.id)}
            >
              {memberIndex !== 0 && <Separator />}

              <div className="flex flex-col gap-4 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-6 xl:py-8">
                <Switch checked={isSelected} />

                <motion.div
                  className="flex flex-col gap-1 lg:flex-row lg:items-center lg:gap-14"
                  initial={{ opacity: isSelected ? 1 : 0.5 }}
                  animate={{
                    opacity: isSelected ? 1 : 0.5,
                  }}
                >
                  <div className="flex items-center gap-4">
                    <div className="font-semibold">{member.member.name}</div>
                  </div>

                  <div className="text-muted-foreground">
                    {member.member.email}
                  </div>

                  {member.member.phone && (
                    <div className="text-muted-foreground lg:ml-auto">
                      {member.member.phone}
                    </div>
                  )}
                </motion.div>
              </div>
            </div>
          );
        })}
      </div>

      {children}
    </>
  );
};
