import { z } from 'zod';

/////////////////////////////////////////
// SHARE GROUP SCHEMA
/////////////////////////////////////////

export const ShareGroupSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  resourceId: z.string(),
  resourceType: z.string(),
  groupId: z.string(),
})

export type ShareGroup = z.infer<typeof ShareGroupSchema>

export default ShareGroupSchema;
