import { CopyDefinitions, CopyKey } from '~/copy';
import { copy } from '~/copy/copy';
import { useLanguage } from '~/language/hooks';

export function useCopy(): {
  get: (
    key: CopyKey,
    replace?: Record<string, string | number>,
  ) => CopyDefinitions[keyof CopyDefinitions]['default'];
} {
  const { language } = useLanguage();

  const get = <K extends keyof CopyDefinitions>(
    key: K,
    replace?: Record<string, string | number>,
  ) => {
    const item = copy[key];

    const value = (item[language.id] ||
      item.default) as CopyDefinitions[K]['default'] as string;

    if (replace) {
      return Object.entries(replace).reduce((acc, [key, value]) => {
        return acc.replace(`{${key}}`, String(value));
      }, value);
    }

    return value;
  };

  return { get };
}
