import {
  TrackReference,
  TrackReferenceOrPlaceholder,
  useTrackMutedIndicator,
  VideoTrack,
} from '@livekit/components-react';
import {
  Badge,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '~/components';
import { useSize } from '~/hooks';
import {
  useMeeting,
  useMeetingSettings,
  useParticipants,
} from '~/meeting/hooks';
import { cn } from '~/style';
import { Meeting } from '~/types';

export const Participants = ({ meetingId }: { meetingId: string }) => {
  const { isVisible, setIsVisible, videoTracks } = useParticipants();
  const { meeting } = useMeeting(meetingId);
  const { isLg } = useSize();

  if (isLg) {
    return (
      <div className="hide-scrollbar fixed bottom-0 left-0 top-0 z-20 w-80 overflow-auto p-4">
        <ParticipantsList videoTracks={videoTracks} meeting={meeting} />
      </div>
    );
  }

  return (
    <Sheet open={isVisible} onOpenChange={setIsVisible}>
      <SheetContent className="hide-scrollbar overflow-auto">
        <button></button>

        <SheetHeader>
          <SheetTitle className="hidden">Participants</SheetTitle>

          <ParticipantsList videoTracks={videoTracks} meeting={meeting} />
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

const ParticipantsList = ({
  videoTracks,
  meeting,
}: {
  videoTracks: TrackReferenceOrPlaceholder[];
  meeting: Meeting | null;
}) => {
  return (
    <div className="grid gap-6">
      {videoTracks.map((videoTrack, videoTrackIndex) => {
        return (
          <Participant
            key={videoTrackIndex}
            videoTrack={videoTrack}
            meeting={meeting}
          />
        );
      })}
    </div>
  );
};

const Participant = ({
  videoTrack,
  meeting,
}: {
  videoTrack: TrackReferenceOrPlaceholder;
  meeting: Meeting | null;
}) => {
  const { isMuted } = useTrackMutedIndicator(videoTrack);
  const { mirrorVideo } = useMeetingSettings();
  const isHost = meeting?.hostId === videoTrack.participant.identity;
  const shouldMirror = videoTrack.participant.isLocal && mirrorVideo;

  return (
    <div className="relative aspect-video overflow-hidden rounded-xl border bg-muted">
      {isMuted ? (
        <div className="flex h-full w-full items-center justify-center">
          <svg
            className="mx-auto w-1/4 text-muted-foreground/70"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      ) : (
        <VideoTrack
          className={cn({
            'scale-x-[-1]': shouldMirror,
          })}
          trackRef={videoTrack as TrackReference}
        />
      )}

      <div className="absolute left-0 top-0 h-full w-full">
        <div className="absolute bottom-2 right-2">
          <Badge
            className={cn(
              'text-foreground',
              {
                'bg-muted': !isHost,
              },
              {
                'bg-primary text-primary-foreground':
                  videoTrack.participant.isLocal,
              },
              {
                'bg-secondary text-secondary-foreground': isHost,
              },
            )}
          >
            {isHost
              ? 'Host'
              : videoTrack.participant.isLocal
                ? 'You'
                : videoTrack.participant.name}
          </Badge>
        </div>
      </div>
    </div>
  );
};
