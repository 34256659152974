import { defineCopy } from '~/copy';

export default {
  ask: defineCopy({
    default: 'Ask',
    'de-DE': 'Fragen',
    'es-ES': 'Preguntar',
    'it-IT': 'Chiedere',
    'fr-FR': 'Demander',
  }),
  howCanIHelp: defineCopy({
    default: 'How can I help...',
    'de-DE': 'Wie kann ich helfen...',
    'es-ES': '¿Cómo puedo ayudar...',
    'it-IT': 'Come posso aiutare...',
    'fr-FR': 'Comment puis-je aider...',
  }),
};
