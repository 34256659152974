import { Link as RouterLink } from 'react-router-dom';

export const Link = ({
  href,
  className,
  children,
}: {
  href: string;
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <RouterLink to={href} className={className}>
      {children}
    </RouterLink>
  );
};
