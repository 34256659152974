import { z } from 'zod';

/////////////////////////////////////////
// INCIDENT ALERT SCHEMA
/////////////////////////////////////////

export const IncidentAlertSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  incidentId: z.string(),
  status: z.string(),
})

export type IncidentAlert = z.infer<typeof IncidentAlertSchema>

export default IncidentAlertSchema;
