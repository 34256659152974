import { z } from 'zod';

/////////////////////////////////////////
// GROUP SCHEMA
/////////////////////////////////////////

export const GroupSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  userId: z.string(),
  name: z.string(),
})

export type Group = z.infer<typeof GroupSchema>

export default GroupSchema;
