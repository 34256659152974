import { z } from 'zod';

/////////////////////////////////////////
// INCIDENT SCHEMA
/////////////////////////////////////////

export const IncidentSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  userId: z.string(),
  date: z.number().int(),
  description: z.string(),
  title: z.string().nullable(),
  location: z.string().nullable(),
  summary: z.string().nullable(),
  isResolved: z.boolean(),
})

export type Incident = z.infer<typeof IncidentSchema>

export default IncidentSchema;
