import { defineCopy } from '~/copy';

export default {
  recentActions: defineCopy({
    default: 'Recent actions',
    'de-DE': 'Letzte Aktionen',
    'es-ES': 'Acciones recientes',
    'it-IT': 'Azioni recenti',
    'fr-FR': 'Actions récentes',
  }),
  history: defineCopy({
    default: 'History',
    'de-DE': 'Verlauf',
    'es-ES': 'Historial',
    'it-IT': 'Cronologia',
    'fr-FR': 'Historique',
  }),
};
