import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { cookies } from '~/cookie';
import { getLanguageFromCookie, getLanguageWithDefault } from '~/language';
import { invalidateQueries } from '~/query';

const initialState = {
  language: getLanguageFromCookie(),
};

export const languageStore = create(
  combine(initialState, (set) => ({
    selectLanguage: async (languageId: string) => {
      const language = getLanguageWithDefault(languageId);

      cookies.set('language', language.id);

      set({ language });
      invalidateQueries([]);
    },
  })),
);

export function useLanguage() {
  const store = languageStore();

  return { ...store };
}
