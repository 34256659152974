import { z } from 'zod';

/////////////////////////////////////////
// BANK CONNECTION SCHEMA
/////////////////////////////////////////

export const BankConnectionSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  userId: z.string(),
  bankId: z.string(),
  requisitionId: z.string(),
  isConnected: z.boolean(),
  connectionUrl: z.string(),
})

export type BankConnection = z.infer<typeof BankConnectionSchema>

export default BankConnectionSchema;
