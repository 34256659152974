export const features = [
  {
    title: 'Effortless Document Organisation',
    description:
      'Keep all your documents in one place for seamless access and organisation.',
  },
  {
    title: 'Secure Team Password Sharing',
    description:
      'Safeguard and share passwords easily to enhance collaboration and safety.',
  },
  {
    title: 'Task and Project Management',
    description:
      "Organise tasks and projects efficiently to boost your team's productivity.",
  },
  {
    title: 'HD Video Meetings',
    description:
      'Experience high-definition meetings for better communication and understanding.',
  },
  {
    title: 'Emergency Incident Reporting',
    description:
      'Manage incidents quickly and effectively with your entire team on board.',
  },
];
