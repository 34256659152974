import { times } from 'lodash';
import { useState } from 'react';
import { cn } from '~/style';

const frequencies = ['Daily', 'Weekly', 'Monthly', 'Yearly'];

const allDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
const orderedDays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const allMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const orderedMonths = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const Schedule = ({
  frequency,
  setFrequency,
  days,
  setDays,
  dates,
  setDates,
  months,
  setMonths,
}: {
  frequency: string;
  setFrequency: (frequency: string) => void;
  days: string;
  setDays: (days: string) => void;
  dates: string;
  setDates: (dates: string) => void;
  months: string;
  setMonths: (months: string) => void;
}) => {
  const [selectedDays, setSelectedDays] = useState<string[]>(
    days ? days.split(',') : [],
  );
  const [selectedDates, setSelectedDates] = useState<string[]>(
    dates ? dates.split(',') : [],
  );
  const [selectedMonths, setSelectedMonths] = useState<string[]>(
    months ? months.split(',') : [],
  );

  return (
    <div className="space-y-8 md:space-y-10">
      <div className="space-y-4">
        <div>Frequency</div>

        <div className="rounded-xl border p-6">
          <FrequencySelector
            frequency={frequency}
            setFrequency={setFrequency}
            setDays={setDays}
            setMonths={setMonths}
            setDates={setDates}
            setSelectedDays={setSelectedDays}
            setSelectedDates={setSelectedDates}
            setSelectedMonths={setSelectedMonths}
          />
        </div>
      </div>

      {frequency === 'weekly' && (
        <div className="space-y-4">
          <div>Days</div>

          <div className="rounded-xl border p-6">
            <DaysSelector
              setDays={setDays}
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
            />
          </div>
        </div>
      )}

      {frequency === 'yearly' && (
        <div className="space-y-4">
          <div>Months</div>

          <div className="rounded-xl border p-6">
            <MonthsSelector
              setMonths={setMonths}
              selectedMonths={selectedMonths}
              setSelectedMonths={setSelectedMonths}
            />
          </div>
        </div>
      )}

      {(frequency === 'monthly' || frequency === 'yearly') && (
        <div className="space-y-4">
          <div>Dates</div>

          <div className="rounded-xl border p-6">
            <DateSelector
              setDates={setDates}
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const FrequencySelector = ({
  frequency,
  setFrequency,
  setDays,
  setMonths,
  setDates,
  setSelectedDays,
  setSelectedDates,
  setSelectedMonths,
}: {
  frequency: string;
  setFrequency: (frequency: string) => void;
  setDays: (days: string) => void;
  setMonths: (months: string) => void;
  setDates: (dates: string) => void;
  setSelectedDays: (days: string[]) => void;
  setSelectedDates: (dates: string[]) => void;
  setSelectedMonths: (months: string[]) => void;
}) => {
  return (
    <div className="grid grid-cols-2 gap-2 md:grid-cols-4">
      {frequencies.map((frequencyLabel) => {
        const isSelected = frequency === frequencyLabel.toLowerCase();

        return (
          <div
            className={cn(
              'flex h-10 w-full cursor-pointer select-none items-center justify-center rounded-md bg-muted/70',
              {
                'bg-primary text-primary-foreground': isSelected,
                'hover:bg-muted': !isSelected,
              },
            )}
            onClick={() => {
              setDays('');
              setMonths('');
              setDates('');
              setSelectedDays([]);
              setSelectedDates([]);
              setSelectedMonths([]);
              setFrequency(frequencyLabel.toLowerCase());
            }}
            key={frequencyLabel}
          >
            {frequencyLabel}
          </div>
        );
      })}
    </div>
  );
};

const DaysSelector = ({
  setDays,
  selectedDays,
  setSelectedDays,
}: {
  setDays: (days: string) => void;
  selectedDays: string[];
  setSelectedDays: (days: string[]) => void;
}) => {
  const setDay = (day: string) => {
    let updatedDays;

    if (selectedDays.includes(day)) {
      updatedDays = selectedDays.filter((d) => d !== day);
    } else {
      updatedDays = [...selectedDays, day];
    }

    updatedDays.sort((a, b) => orderedDays.indexOf(a) - orderedDays.indexOf(b));

    setSelectedDays(updatedDays);
    setDays(updatedDays.join(','));
  };

  return (
    <div className="grid grid-cols-3 gap-2 md:grid-cols-5">
      {allDays.map((day) => {
        const isSelected = selectedDays.includes(day.toLowerCase());

        return (
          <div
            className={cn(
              'flex h-10 cursor-pointer select-none items-center justify-center rounded-md bg-muted/70',
              {
                'bg-primary text-primary-foreground': isSelected,
                'hover:bg-muted': !isSelected,
              },
            )}
            key={day}
            onClick={() => setDay(day.toLowerCase())}
          >
            {day.slice(0, 3)}
          </div>
        );
      })}
    </div>
  );
};

export const DateSelector = ({
  setDates,
  selectedDates,
  setSelectedDates,
}: {
  setDates: (dates: string) => void;
  selectedDates: string[];
  setSelectedDates: (dates: string[]) => void;
}) => {
  const setDate = (date: string) => {
    let updatedDates;

    if (selectedDates.includes(date)) {
      updatedDates = selectedDates.filter((d) => d !== date);
    } else {
      updatedDates = [...selectedDates, date];
    }

    updatedDates.sort((a, b) => parseInt(a) - parseInt(b));

    setSelectedDates(updatedDates);
    setDates(updatedDates.join(','));
  };

  return (
    <div className="flex flex-wrap gap-2">
      {times(31, (index) => {
        const date = (index + 1).toString();
        const isSelected = selectedDates.includes(date);

        return (
          <div
            className={cn(
              'flex h-10 w-10 cursor-pointer select-none items-center justify-center rounded-md bg-muted/70',
              {
                'bg-primary text-primary-foreground': isSelected,
                'hover:bg-muted': !isSelected,
              },
            )}
            key={date}
            onClick={() => setDate(date)}
          >
            {date}
          </div>
        );
      })}
    </div>
  );
};

const MonthsSelector = ({
  setMonths,
  selectedMonths,
  setSelectedMonths,
}: {
  setMonths: (months: string) => void;
  selectedMonths: string[];
  setSelectedMonths: (months: string[]) => void;
}) => {
  const setMonth = (month: string) => {
    let updatedMonths;

    if (selectedMonths.includes(month)) {
      updatedMonths = selectedMonths.filter((m) => m !== month);
    } else {
      updatedMonths = [...selectedMonths, month];
    }

    updatedMonths.sort(
      (a, b) => orderedMonths.indexOf(a) - orderedMonths.indexOf(b),
    );

    setSelectedMonths(updatedMonths);
    setMonths(updatedMonths.join(','));
  };

  return (
    <div className="grid grid-cols-3 gap-2 md:grid-cols-4">
      {allMonths.map((month) => {
        const isSelected = selectedMonths.includes(month.toLowerCase());

        return (
          <div
            className={cn(
              'flex h-10 cursor-pointer select-none items-center justify-center rounded-md bg-muted/70',
              {
                'bg-primary text-primary-foreground': isSelected,
                'hover:bg-muted': !isSelected,
              },
            )}
            key={month}
            onClick={() => setMonth(month.toLowerCase())}
          >
            {month.slice(0, 3)}
          </div>
        );
      })}
    </div>
  );
};
