import { defineCopy } from '~/copy';

export default {
  incidents: defineCopy({
    default: 'Incidents',
    'de-DE': 'Vorfälle',
    'es-ES': 'Incidentes',
    'it-IT': 'Incidenti',
    'fr-FR': 'Incidents',
  }),
  incident: defineCopy({
    default: 'Incident',
    'de-DE': 'Vorfall',
    'es-ES': 'Incidente',
    'it-IT': 'Incidente',
    'fr-FR': 'Incident',
  }),
  update: defineCopy({
    default: 'Update',
    'de-DE': 'Aktualisierung',
    'es-ES': 'Actualización',
    'it-IT': 'Aggiornamento',
    'fr-FR': 'Mise à jour',
  }),
  reportIncident: defineCopy({
    default: 'Report incident',
    'de-DE': 'Vorfall melden',
    'es-ES': 'Reportar incidente',
    'it-IT': 'Segnala incidente',
    'fr-FR': 'Signaler un incident',
  }),
  noIncidents: defineCopy({
    default: 'No incidents have been reported',
    'de-DE': 'Es wurden keine Vorfälle gemeldet',
    'es-ES': 'No se han reportado incidentes',
    'it-IT': 'Non sono stati segnalati incidenti',
    'fr-FR': "Aucun incident n'a été signalé",
  }),
  resolve: defineCopy({
    default: 'Resolve',
    'de-DE': 'Lösen',
    'es-ES': 'Resolver',
    'it-IT': 'Risolvere',
    'fr-FR': 'Résoudre',
  }),
  resolved: defineCopy({
    default: 'Resolved',
    'de-DE': 'Gelöst',
    'es-ES': 'Resuelto',
    'it-IT': 'Risolto',
    'fr-FR': 'Résolu',
  }),
  unresolve: defineCopy({
    default: 'Unresolve',
    'de-DE': 'Nicht lösen',
    'es-ES': 'No resolver',
    'it-IT': 'Annulla risoluzione',
    'fr-FR': 'Annuler la résolution',
  }),
  viewResolved: defineCopy({
    default: 'View resolved',
    'de-DE': 'Gelöste anzeigen',
    'es-ES': 'Ver resueltos',
    'it-IT': 'Visualizza risolti',
    'fr-FR': 'Voir les résolus',
  }),
  noIncidentsResolved: defineCopy({
    default: 'No incidents have been resolved',
    'de-DE': 'Es wurden keine Vorfälle gelöst',
    'es-ES': 'No se han resuelto incidentes',
    'it-IT': 'Nessun incidente è stato risolto',
    'fr-FR': "Aucun incident n'a été résolu",
  }),
  sendAlert: defineCopy({
    default: 'Send alert',
    'de-DE': 'Alarm senden',
    'es-ES': 'Enviar alerta',
    'it-IT': 'Invia allerta',
    'fr-FR': 'Envoyer une alerte',
  }),
  description: defineCopy({
    default: 'Description',
    'de-DE': 'Beschreibung',
    'es-ES': 'Descripción',
    'it-IT': 'Descrizione',
    'fr-FR': 'Description',
  }),
  summary: defineCopy({
    default: 'Summary',
    'de-DE': 'Zusammenfassung',
    'es-ES': 'Resumen',
    'it-IT': 'Sommario',
    'fr-FR': 'Résumé',
  }),
  viewAlerts: defineCopy({
    default: 'View alerts',
    'de-DE': 'Alarme anzeigen',
    'es-ES': 'Ver alertas',
    'it-IT': 'Visualizza allarmi',
    'fr-FR': 'Voir les alertes',
  }),
  actionResolveIncident: defineCopy({
    default: 'This action will resolve the incident.',
    'de-DE': 'Diese Aktion löst den Vorfall.',
    'es-ES': 'Esta acción resolverá el incidente.',
    'it-IT': 'Questa azione risolverà l’incidente.',
    'fr-FR': "Cette action résoudra l'incident.",
  }),
  actionUnresolveIncident: defineCopy({
    default: 'This action will unresolve the incident.',
    'de-DE': 'Diese Aktion hebt die Lösung des Vorfalls auf.',
    'es-ES': 'Esta acción deshará la resolución del incidente.',
    'it-IT': 'Questa azione annullerà la risoluzione dell’incidente.',
    'fr-FR': "Cette action annulera la résolution de l'incident.",
  }),
  actionDeleteIncident: defineCopy({
    default:
      'This action will permanently delete the incident. This cannot be undone.',
    'de-DE':
      'Diese Aktion löscht den Vorfall dauerhaft. Dies kann nicht rückgängig gemacht werden.',
    'es-ES':
      'Esta acción eliminará permanentemente el incidente. Esto no se puede deshacer.',
    'it-IT':
      'Questa azione eliminerà definitivamente l’incidente. Questo non può essere annullato.',
    'fr-FR':
      "Cette action supprimera définitivement l'incident. Cela ne peut pas être annulé.",
  }),
  deleteIncident: defineCopy({
    default: 'Delete incident',
    'de-DE': 'Vorfall löschen',
    'es-ES': 'Eliminar incidente',
    'it-IT': 'Elimina incidente',
    'fr-FR': "Supprimer l'incident",
  }),
  editIncident: defineCopy({
    default: 'Edit incident',
    'de-DE': 'Vorfall bearbeiten',
    'es-ES': 'Editar incidente',
    'it-IT': 'Modifica incidente',
    'fr-FR': "Modifier l'incident",
  }),
  incidentTitle: defineCopy({
    default: 'Incident title',
    'de-DE': 'Vorfalltitel',
    'es-ES': 'Título del incidente',
    'it-IT': 'Titolo dell’incidente',
    'fr-FR': "Titre de l'incident",
  }),
  sendAlertToMembers: defineCopy({
    default: 'Send incident alert to members',
    'de-DE': 'Alarm an Mitglieder senden',
    'es-ES': 'Enviar alerta de incidente a los miembros',
    'it-IT': 'Invia allerta incidente ai membri',
    'fr-FR': 'Envoyer une alerte d’incident aux membres',
  }),
  thisWillSendAlert: defineCopy({
    default:
      'This will send an alert to all members of this org.<br/>Are you sure you want to proceed?',
    'de-DE':
      'Dies sendet einen Alarm an alle Mitglieder dieser Gruppe.<br/>Möchten Sie wirklich fortfahren?',
    'es-ES':
      'Esto enviará una alerta a todos los miembros de este grupo.<br/>¿Estás seguro de que deseas continuar?',
    'it-IT':
      'Questo invierà un allarme a tutti i membri di questo gruppo.<br/>Sei sicuro di voler procedere?',
    'fr-FR':
      'Cela enverra une alerte à tous les membres de ce orge.<br/>Êtes-vous sûr de vouloir continuer?',
  }),
  sendAlertToXMembers: defineCopy({
    default: 'Send alert to {membersTotal} member{s}',
    'de-DE': 'Alarm an {membersTotal} Mitglied{e}',
    'es-ES': 'Enviar alerta a {membersTotal} miembro{s}',
    'it-IT': 'Invia allarme a {membersTotal} membr{i}',
    'fr-FR': 'Envoyer une alerte à {membersTotal} membre{s}',
  }),
};
