import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { Transactions } from '~/transaction/components';
import { useTransactions } from '~/transaction/hooks';

export const TransactionsPage = () => {
  const { transactions } = useTransactions();
  const copy = useCopy();

  if (!transactions) return null;

  return (
    <DashboardLayout headerTitle={copy.get('transactions')}>
      <div className="mx-auto max-w-5xl">
        <Transactions />
      </div>
    </DashboardLayout>
  );
};
