import { z } from 'zod';

/////////////////////////////////////////
// CONTENT TRANSLATION SCHEMA
/////////////////////////////////////////

export const ContentTranslationSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  contentId: z.string(),
  languageId: z.string(),
  title: z.string(),
  text: z.string(),
  location: z.string().nullable(),
  summary: z.string().nullable(),
  textInstructions: z.string().nullable(),
})

export type ContentTranslation = z.infer<typeof ContentTranslationSchema>

export default ContentTranslationSchema;
