import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { deleteReminder, getReminder, updateReminder } from '~/api';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  BasicButton,
  Form,
  FormInput,
  FormSubmitButton,
  FormTextarea,
  OriginalButton,
} from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { useParams } from '~/hooks';
import { Schedule } from '~/reminder/components';
import { sentry } from '~/sentry';
import { ShareButton } from '~/share/components';
import { Reminder } from '~/types';

export const EditReminderPage = () => {
  const { reminderId } = useParams<{ reminderId: string }>();
  const [reminder, setReminder] = useState<Reminder | null>(null);

  useEffect(() => {
    const fn = async () => {
      const { reminder } = await getReminder({ reminderId });

      setReminder(reminder);
    };

    fn();
  }, [reminderId]);

  if (!reminder) return null;

  return <EditReminder reminder={reminder} />;
};

export const EditReminder = ({ reminder }: { reminder: Reminder }) => {
  const [title, setTitle] = useState(reminder.title);
  const [description, setDescription] = useState(reminder.description || '');
  const [frequency, setFrequency] = useState(reminder.frequency || '');
  const [days, setDays] = useState(reminder.days || '');
  const [dates, setDates] = useState(reminder.dates || '');
  const [months, setMonths] = useState(reminder.months || '');
  const copy = useCopy();
  const navigate = useNavigate();

  const update = useMutation({
    mutationFn: async () => {
      await updateReminder({
        reminderId: reminder.id,
        updateData: {
          title,
          description,
          frequency,
          days,
          dates,
          months,
        },
      });
    },
    onSuccess: async () => {
      analytics.track('reminder.update');

      navigate('/reminders');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  const submitUpdate = () => {
    if (!title) return;

    update.mutate();
  };

  return (
    <DashboardLayout
      headerTitle={copy.get('editReminder')}
      back="/reminders"
      size="full"
      showHeaderBorder
      headerOptions={
        <>
          <DeleteReminderButton reminder={reminder} />

          <ShareButton
            resource={reminder}
            href={`/reminders/${reminder.id}/share`}
          />
        </>
      }
    >
      <div className="mx-auto w-full max-w-2xl space-y-6 md:space-y-10 lg:space-y-14">
        <Form onSubmit={submitUpdate}>
          <FormInput
            value={title}
            onChange={setTitle}
            label="reminderTitle"
            placeholder="title"
          />

          <FormTextarea
            value={description}
            onChange={setDescription}
            label="description"
            placeholder="description"
          />

          <Schedule
            frequency={frequency}
            setFrequency={setFrequency}
            days={days}
            setDays={setDays}
            dates={dates}
            setDates={setDates}
            months={months}
            setMonths={setMonths}
          />
        </Form>

        <FormSubmitButton
          isLoading={update.isPending}
          label="save"
          onClick={submitUpdate}
        />
      </div>
    </DashboardLayout>
  );
};

const DeleteReminderButton = ({ reminder }: { reminder: Reminder }) => {
  const copy = useCopy();
  const navigate = useNavigate();
  const remove = useMutation({
    mutationFn: async () => {
      await deleteReminder({
        reminderId: reminder.id,
      });
    },
    onSuccess: async () => {
      analytics.track('reminder.delete');

      navigate('/reminders');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <BasicButton variant="outline" size="sm" disabled={remove.isPending}>
          {remove.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            <svg
              className="size-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          )}
        </BasicButton>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{copy.get('areYouSure')}</AlertDialogTitle>

          <AlertDialogDescription className="pb-6">
            {copy.get('deleteReminder')}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>

          <AlertDialogAction asChild>
            <OriginalButton
              onClick={() => {
                remove.mutate();
              }}
            >
              {copy.get('delete')}
            </OriginalButton>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
