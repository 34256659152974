import { create } from 'zustand';
import { combine } from 'zustand/middleware';

const mirrorVideoStorage = localStorage.getItem('meeting-mirror-video');

const initialState = {
  isVisible: false,
  mirrorVideo: mirrorVideoStorage ? mirrorVideoStorage === 'true' : false,
};

export const meetingSettingsStore = create(
  combine(initialState, (set) => ({
    setUserName: (isVisible: boolean) => {
      set({ isVisible });
    },

    setIsVisible: (isVisible: boolean) => {
      set({ isVisible });
    },

    show: () => set({ isVisible: true }),

    hide: () => set({ isVisible: false }),

    toggle: () => set((state) => ({ isVisible: !state.isVisible })),

    setMirrorVideo: (mirrorVideo: boolean) => {
      localStorage.setItem('meeting-mirror-video', mirrorVideo.toString());
      set({ mirrorVideo });
    },
  })),
);

export function useMeetingSettings() {
  const store = meetingSettingsStore();

  return {
    ...store,
  };
}
