import { QueryClientProvider } from '@tanstack/react-query';
import { getQueryClient } from '~/query';

const queryClient = getQueryClient();

export const QueryProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
