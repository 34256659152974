import { defineCopy } from '~/copy';

export default {
  connectToBank: defineCopy({
    default: 'Connect to Bank',
    'de-DE': 'Mit Bank verbinden',
    'es-ES': 'Conectar al banco',
    'it-IT': 'Connetti alla banca',
    'fr-FR': 'Se connecter à la banque',
  }),
  transactions: defineCopy({
    default: 'Transactions',
    'de-DE': 'Transaktionen',
    'es-ES': 'Transacciones',
    'it-IT': 'Transazioni',
    'fr-FR': 'Transactions',
  }),
  searchForABank: defineCopy({
    default: 'Search for a bank',
    'de-DE': 'Suche nach einer Bank',
    'es-ES': 'Buscar un banco',
    'it-IT': 'Cerca una banca',
    'fr-FR': 'Rechercher une banque',
  }),
  bankAccounts: defineCopy({
    default: 'Bank Accounts',
    'de-DE': 'Bankkonten',
    'es-ES': 'Cuentas bancarias',
    'it-IT': 'Conti bancari',
    'fr-FR': 'Comptes bancaires',
  }),
};
