import { Loader2 } from 'lucide-react';
import { BasicButton, ButtonProps } from '~/components';
import { CopyKey } from '~/copy';
import { useCopy } from '~/copy/hooks';
import { cn } from '~/style';

export const FormSubmitButton = ({
  label,
  className,
  variant,
  disabled,
  type,
  onClick,
  isLoading,
  size,
}: {
  label: CopyKey;
  className?: string;
  variant?: ButtonProps['variant'];
  disabled?: boolean;
  type?: ButtonProps['type'];
  onClick?: ButtonProps['onClick'];
  isLoading?: boolean;
  size?: ButtonProps['size'];
}) => {
  const copy = useCopy();

  return (
    <div className="text-center">
      <BasicButton
        className={cn('w-40 md:w-52', className)}
        variant={variant}
        type={type || 'submit'}
        onClick={onClick}
        disabled={disabled ?? isLoading}
        size={size}
      >
        {isLoading ? (
          <Loader2 className="h-6 w-6 animate-spin" />
        ) : (
          copy.get(label)
        )}
      </BasicButton>
    </div>
  );
};
