import { z } from 'zod';

/////////////////////////////////////////
// PASSWORD SCHEMA
/////////////////////////////////////////

export const PasswordSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  userId: z.string(),
  name: z.string(),
  password: z.string(),
  description: z.string().nullable(),
})

export type Password = z.infer<typeof PasswordSchema>

export default PasswordSchema;
