import { useEffect, useState } from 'react';
import { getBoard } from '~/api';
import { Redirect } from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { useParams } from '~/hooks';
import { Share } from '~/share/components';
import { useShare } from '~/share/hooks';
import { Board } from '~/types';

export const ShareBoardPage = () => {
  const { boardId } = useParams<{ boardId: string }>();
  const [board, setBoard] = useState<Board | null>(null);

  useEffect(() => {
    const fn = async () => {
      const { board } = await getBoard({ boardId });

      setBoard(board);
    };

    fn();
  }, [boardId]);

  if (!board) return null;

  return <ShareBoard board={board} />;
};

export const ShareBoard = ({ board }: { board: Board }) => {
  const copy = useCopy();
  const { canShare } = useShare(board);

  if (!canShare) return <Redirect path="/boards" />;

  return (
    <DashboardLayout
      headerTitle={copy.get('shareBoard')}
      back={`/boards/${board.id}/settings`}
      size="full"
      showHeaderBorder
    >
      <Share
        resourceType="board"
        resourceId={board.id}
        saveSuccessPath={`/boards/${board.id}`}
      />
    </DashboardLayout>
  );
};
