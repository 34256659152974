import { defineCopy } from '~/copy';

export default {
  settings: defineCopy({
    default: 'Settings',
    'de-DE': 'Einstellungen',
    'es-ES': 'Ajustes',
    'it-IT': 'Impostazioni',
    'fr-FR': 'Paramètres',
  }),
  pronunciations: defineCopy({
    default: 'Pronounciations',
    'de-DE': 'Aussprachen',
    'es-ES': 'Pronunciaciones',
    'it-IT': 'Pronunce',
    'fr-FR': 'Prononciations',
  }),
  editPronunciations: defineCopy({
    default: 'Edit Pronounciations',
    'de-DE': 'Aussprachen bearbeiten',
    'es-ES': 'Editar pronunciaciones',
    'it-IT': 'Modifica pronunce',
    'fr-FR': 'Modifier les prononciations',
  }),
};
