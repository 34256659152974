import { defineCopy } from '~/copy';

export default {
  alerts: defineCopy({
    default: 'Alerts',
    'de-DE': 'Warnungen',
    'es-ES': 'Alertas',
    'it-IT': 'Allarmi',
    'fr-FR': 'Alertes',
  }),
  alert: defineCopy({
    default: 'Alert',
    'de-DE': 'Warnung',
    'es-ES': 'Alerta',
    'it-IT': 'Allarme',
    'fr-FR': 'Alerte',
  }),
  noAlertsSent: defineCopy({
    default: 'No alerts have been sent',
    'de-DE': 'Es wurden keine Warnungen gesendet',
    'es-ES': 'No se han enviado alertas',
    'it-IT': 'Nessun allarme è stato inviato',
    'fr-FR': "Aucune alerte n'a été envoyée",
  }),
  alertSent: defineCopy({
    default: 'Alert sent',
    'de-DE': 'Warnung gesendet',
    'es-ES': 'Alerta enviada',
    'it-IT': 'Allarme inviato',
    'fr-FR': 'Alerte envoyée',
  }),
};
