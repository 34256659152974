import { z } from 'zod';

/////////////////////////////////////////
// BOARD SCHEMA
/////////////////////////////////////////

export const BoardSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  userId: z.string(),
  name: z.string(),
})

export type Board = z.infer<typeof BoardSchema>

export default BoardSchema;
