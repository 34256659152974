import { BasicButton, EditButton, Link, Separator } from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { useReminders } from '~/reminder/hooks';
import { Reminder } from '~/types';

export const RemindersPage = () => {
  const copy = useCopy();
  const { reminders } = useReminders();

  if (!reminders) return null;

  return (
    <DashboardLayout
      headerTitle={copy.get('reminders')}
      headerOptions={
        <>
          <Link href="/reminders/new">
            <BasicButton size="sm">
              <svg
                className="-ml-2 mr-4 size-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>

              <span>
                <span className="hidden md:inline">Add </span>

                <span>Reminder</span>
              </span>
            </BasicButton>
          </Link>
        </>
      }
    >
      <div className="mx-auto w-full max-w-5xl space-y-6">
        {reminders.length > 0 ? (
          <div className="mx-auto w-full max-w-5xl space-y-6 md:space-y-10 lg:space-y-14">
            {reminders.map((reminder) => {
              return <ReminderCard key={reminder.id} reminder={reminder} />;
            })}
          </div>
        ) : (
          <div className="text-balance rounded-lg border px-6 py-40 text-center text-lg font-medium text-muted-foreground">
            {copy.get('noReminders')}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

const ReminderCard = ({ reminder }: { reminder: Reminder }) => {
  return (
    <div className="rounded-lg border">
      <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14">
        <div className="space-y-2">
          <div className="text-lg font-semibold md:text-xl lg:text-2xl">
            {reminder.title}
          </div>

          {reminder.frequencyDescription && (
            <div className="text-sm text-muted-foreground">
              {reminder.frequencyDescription}
            </div>
          )}
        </div>

        <div className="ml-auto flex gap-2 md:gap-3">
          <EditButton href={`/reminders/${reminder.id}/edit`} />
        </div>
      </div>

      {reminder.description && (
        <>
          <Separator />

          <div className="px-8 py-6 text-muted-foreground">
            {reminder.description}
          </div>
        </>
      )}
    </div>
  );
};
