import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { createService } from '~/api';
import {
  Form,
  FormCurrency,
  FormFrequency,
  FormInput,
  FormSubmit,
  FormTextarea,
} from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { sentry } from '~/sentry';

export const CreateServicePage = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [cost, setCost] = useState(0);
  const [frequency, setFrequency] = useState('monthly');
  const [currencyId, setCurrencyId] = useState('gbp');
  const [discount] = useState(0);
  const [discountType] = useState('percentage');
  const navigate = useNavigate();
  const copy = useCopy();

  const create = useMutation({
    mutationFn: async () => {
      await createService({
        name,
        description,
        cost,
        currencyId,
        frequency,
        discount,
        discountType,
      });
    },
    onSuccess: async () => {
      analytics.track('service.create');

      navigate('/services');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  const submit = () => {
    create.mutate();
  };

  return (
    <DashboardLayout headerTitle={copy.get('newService')} size="sm">
      <Form onSubmit={submit}>
        <FormInput value={name} onChange={setName} label="name" />

        <FormTextarea
          value={description}
          onChange={setDescription}
          label="description"
          optional
        />

        <FormCurrency currencyId={currencyId} setCurrencyId={setCurrencyId} />

        <FormInput
          value={cost}
          onChange={setCost}
          label="cost"
          type="number"
          min={0}
          step="0.01"
        />

        <FormFrequency
          frequency={frequency}
          setFrequency={setFrequency}
          omitOneTime
        />

        <FormSubmit isLoading={create.isPending} label="save" />
      </Form>
    </DashboardLayout>
  );
};
