import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { getFirstFileConnection } from '~/api';
import { DropboxConnection } from '~/types';

const initialState = {
  isLoading: true,
  connection: null as DropboxConnection | null,
};

export const useFilesConnection = create(
  combine(initialState, (set) => ({
    load: async () => {
      const { connection } = await getFirstFileConnection({});

      set(() => ({
        isLoading: false,
        connection,
      }));
    },
  })),
);
