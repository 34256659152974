import { z } from 'zod';

/////////////////////////////////////////
// ORG MEMBER SCHEMA
/////////////////////////////////////////

export const OrgMemberSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  memberId: z.string(),
  role: z.string(),
})

export type OrgMember = z.infer<typeof OrgMemberSchema>

export default OrgMemberSchema;
