import { useUser } from '~/auth/hooks';
import { ShareResource } from '~/share';

export function useShare<R extends ShareResource>(resource: R) {
  const { user } = useUser();

  const canShare = user.id === resource.userId;

  return {
    canShare,
  };
}
