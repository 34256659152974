import { useOrg } from '~/org/hooks';
import { requiresPlanLevel } from '~/plan';

export function usePlan() {
  const { org } = useOrg();

  const hasPlanLevel = (requiredPlanLevel: number) => {
    const hasIt = requiresPlanLevel(org?.planId, requiredPlanLevel);

    return hasIt;
  };

  const hasEnterprise = () => {
    return hasPlanLevel(2);
  };

  return {
    hasPlanLevel,
    hasEnterprise,
  };
}
