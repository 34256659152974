import { motion } from 'framer-motion';
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { useBanks } from '~/bank/hooks';
import { Input } from '~/components';
import { useCopy } from '~/copy/hooks';

export const Banks = () => {
  const { load, banks, selectedBankLoading, selectBank, error } = useBanks();
  const [searchQuery, setSearchQuery] = useState('');
  const copy = useCopy();

  useEffect(() => {
    load();
  }, []);

  const fuse = new Fuse(banks, {
    keys: ['name'],
    threshold: 0.3,
  });

  const filteredBanks = searchQuery
    ? fuse.search(searchQuery).map((result) => result.item)
    : banks;

  return (
    <div className="mx-auto max-w-2xl space-y-6">
      <Input
        placeholder={copy.get('searchForABank')}
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
      />

      {error && <div className="text-center text-destructive">{error}</div>}

      <div className="space-y-4">
        {filteredBanks.map((bank) => {
          const isLoading = selectedBankLoading === bank.id;

          return (
            <motion.div
              className="flex cursor-pointer items-center gap-4 rounded-xl border p-6 hover:bg-muted/40"
              key={bank.id}
              whileHover="hover"
              onClick={() => selectBank(bank.id)}
            >
              <div>
                <img
                  className="h-10 w-10 rounded-full"
                  src={bank.logo}
                  alt={bank.name}
                />
              </div>

              <div>{bank.name}</div>

              <div className="ml-auto">
                {isLoading ? (
                  <div className="h-8 w-8">
                    <div className="loader" />
                  </div>
                ) : (
                  <motion.div
                    className="ml-auto"
                    initial={{ opacity: 0, x: -10 }}
                    variants={{
                      hover: { opacity: 1, x: 0 },
                    }}
                  >
                    <svg
                      className="size-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                      />
                    </svg>
                  </motion.div>
                )}
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};
