import { useAuth } from '~/auth/hooks';
import { useOrg } from '~/org/hooks';

const nonDashboardNavRoutes = ['/', '/sign-in', '/sign-up', '/sign-out'];

export function useDashboard() {
  const { isSignedIn } = useAuth();
  const { hasOrg, hasOrgPlan } = useOrg();

  const getShouldShowDashboardItems = () => {
    let show = true;

    if (nonDashboardNavRoutes.includes(location.pathname)) {
      return false;
    }

    if (location.pathname.includes('/meetings/')) {
      return false;
    }

    if (location.pathname.includes('/dropbox')) {
      return false;
    }

    if (location.pathname.includes('/bank')) {
      return false;
    }

    if (!isSignedIn) return null;

    if (!hasOrg()) return null;

    if (!hasOrgPlan()) return null;

    return show;
  };

  const shouldShowDashboardItems = getShouldShowDashboardItems();

  return {
    shouldShowDashboardItems,
  };
}
