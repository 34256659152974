import { useQuery } from '@tanstack/react-query';
import { listMembers } from '~/api';

export function useMembers(options?: { omitCurrentUser?: boolean }) {
  const { data: members } = useQuery({
    queryKey: ['members'],
    queryFn: async () => {
      const { members } = await listMembers({
        omitCurrentUser: options?.omitCurrentUser,
      });

      return members;
    },
  });

  return {
    members,
  };
}
