import { z } from 'zod';

/////////////////////////////////////////
// CONTENT MESSAGE SCHEMA
/////////////////////////////////////////

export const ContentMessageSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  userId: z.string(),
  orgId: z.string(),
  contentId: z.string(),
  text: z.string().nullable(),
})

export type ContentMessage = z.infer<typeof ContentMessageSchema>

export default ContentMessageSchema;
