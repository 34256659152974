import { AdvancedImage } from '@cloudinary/react';
import { cloudinary } from '~/cloudinary';
import { cn } from '~/style';

export const CloudImage = ({
  id,
  alt,
  className,
}: {
  id: string;
  alt?: string;
  className?: string;
}) => {
  const image = cloudinary.image(id).quality('auto');

  return (
    <div className={cn('overflow-hidden', className)}>
      <AdvancedImage cldImg={image} alt={alt} />
    </div>
  );
};
