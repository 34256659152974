import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { createPassword } from '~/api';
import {
  BasicButton,
  Input,
  PasswordInput,
  Separator,
  Textarea,
} from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { getOrgId } from '~/org';
import { sentry } from '~/sentry';

export const CreatePasswordPage = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [passwordText, setPasswordText] = useState('');
  const navigate = useNavigate();
  const copy = useCopy();

  const create = useMutation({
    mutationFn: async ({
      name,
      description,
      passwordText,
    }: {
      name: string;
      description: string;
      passwordText: string;
    }) => {
      const orgId = getOrgId();

      await createPassword({
        orgId,
        name,
        description,
        passwordText,
      });
    },
    onSuccess: async () => {
      analytics.track('password.create');

      navigate('/passwords');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <DashboardLayout headerTitle={copy.get('newPassword')} size="sm">
      <div className="mx-auto w-full max-w-2xl space-y-10 md:space-y-14">
        <form
          className="space-y-6 md:space-y-10"
          onSubmit={(event) => {
            event.preventDefault();

            if (!name || !passwordText) return;

            create.mutate({
              name,
              description,
              passwordText,
            });
          }}
        >
          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">
              {copy.get('passwordName')}
            </div>

            <Separator />

            <div className="p-6">
              <Input
                value={name}
                placeholder={copy.get('name')}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
          </div>

          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">
              {copy.get('description')}{' '}
              <span className="lowercase text-muted-foreground">
                ({copy.get('optional')})
              </span>
            </div>

            <Separator />

            <div className="p-6">
              <Textarea
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </div>
          </div>

          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">{copy.get('password')}</div>

            <Separator />

            <div className="p-6">
              <PasswordInput value={passwordText} onChange={setPasswordText} />
            </div>
          </div>

          <BasicButton
            className="w-full"
            type="submit"
            disabled={create.isPending || !name || !passwordText}
          >
            {create.isPending ? (
              <Loader2 className="h-6 w-6 animate-spin" />
            ) : (
              copy.get('addPassword')
            )}
          </BasicButton>
        </form>

        <input
          id="hidden"
          className="pointer-events-none fixed left-full top-full -z-50 h-0 w-0 opacity-0"
          type="text"
        />
      </div>
    </DashboardLayout>
  );
};
