import { useMediaDeviceSelect } from '@livekit/components-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Switch,
} from '~/components';
import { useMeeting, useMeetings, useMeetingSettings } from '~/meeting/hooks';
import { useOrg } from '~/org/hooks';

export const MeetingSettings = ({ meetingId }: { meetingId: string }) => {
  const { isVisible, setIsVisible } = useMeetingSettings();

  return (
    <Sheet open={isVisible} onOpenChange={setIsVisible}>
      <SheetContent className="hide-scrollbar overflow-auto" side="left">
        <button className="hidden"></button>

        <SheetHeader>
          <SheetTitle>Settings</SheetTitle>
        </SheetHeader>

        <div className="mx-auto min-h-64 w-full max-w-2xl space-y-8 px-4 pt-6 sm:pt-10">
          <HostSettings meetingId={meetingId} />

          <VideoSettings />

          <AudioSettings />
        </div>
      </SheetContent>
    </Sheet>
  );
};

const HostSettings = ({ meetingId }: { meetingId: string }) => {
  const { isHost, claimHost } = useMeeting(meetingId);
  const { isOwner } = useOrg();

  if (!isOwner()) return null;

  return (
    <div className="divide-y rounded-xl border">
      <div className="px-6 py-3">Host</div>

      <div className="flex gap-4 p-6">
        <Switch checked={isHost} onCheckedChange={claimHost} />

        <div>Claim host</div>
      </div>
    </div>
  );
};

export const VideoSettings = () => {
  const { videoDevices, videoDeviceId, saveVideoInputDeviceId } = useMeetings();
  const { mirrorVideo, setMirrorVideo } = useMeetingSettings();
  const { setActiveMediaDevice: setActiveVideoDevice } = useMediaDeviceSelect({
    kind: 'videoinput',
  });

  return (
    <div className="divide-y rounded-xl border">
      <div className="px-6 py-3">Video</div>

      <div className="p-6">
        <Select
          onValueChange={(value) => {
            saveVideoInputDeviceId(value);
            setActiveVideoDevice(value);
          }}
          defaultValue={videoDeviceId || ''}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select video device" />
          </SelectTrigger>

          <SelectContent>
            {videoDevices.map((device) => (
              <SelectItem key={device.deviceId} value={device.deviceId}>
                {device.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex gap-4 p-6">
        <Switch checked={mirrorVideo} onCheckedChange={setMirrorVideo} />

        <div>Mirror video</div>
      </div>
    </div>
  );
};

export const AudioSettings = () => {
  const { audioDevices, audioDeviceId, saveAudioInputDeviceId } = useMeetings();
  const { setActiveMediaDevice: setActiveAudioDevice } = useMediaDeviceSelect({
    kind: 'audioinput',
  });

  return (
    <div className="divide-y rounded-xl border">
      <div className="px-6 py-3">Audio</div>

      <div className="p-6">
        <Select
          onValueChange={(value) => {
            setActiveAudioDevice(value);
            saveAudioInputDeviceId(value);
          }}
          defaultValue={audioDeviceId || ''}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select audio device" />
          </SelectTrigger>

          <SelectContent>
            {audioDevices.map((device) => (
              <SelectItem key={device.deviceId} value={device.deviceId}>
                {device.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};
