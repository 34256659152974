import { z } from 'zod';

/////////////////////////////////////////
// MEMBER INVITE CODE SCHEMA
/////////////////////////////////////////

export const MemberInviteCodeSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  email: z.string(),
})

export type MemberInviteCode = z.infer<typeof MemberInviteCodeSchema>

export default MemberInviteCodeSchema;
