import '@fontsource/mulish/400.css';
import '@fontsource/mulish/500.css';
import '@fontsource/mulish/600.css';
import '@fontsource/mulish/700.css';
import '@fontsource/mulish/800.css';
import '@fontsource/mulish/900.css';
import '@liveblocks/react-lexical/styles.css';
import '@liveblocks/react-ui/styles.css';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import '~/style/globals.css';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { analytics } from '~/analytics';
import { App } from '~/App';
import { initSentry } from '~/sentry';

analytics.init();
initSentry();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <Router>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </Router>
  </StrictMode>,
);
