import { z } from 'zod';

/////////////////////////////////////////
// SHARE MEMBER SCHEMA
/////////////////////////////////////////

export const ShareMemberSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  resourceId: z.string(),
  resourceType: z.string(),
  memberId: z.string(),
})

export type ShareMember = z.infer<typeof ShareMemberSchema>

export default ShareMemberSchema;
