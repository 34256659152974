import { cn } from '~/style';
import { getFrequencies } from '~/utils';

export const FrequencySelector = ({
  frequency,
  setFrequency,
  omitOneTime = false,
}: {
  frequency: string;
  setFrequency: (value: string) => void;
  omitOneTime?: boolean;
}) => {
  return (
    <div className="grid grid-cols-2 gap-2 md:grid-cols-3">
      {getFrequencies({ omitOneTime }).map((frequencyItem) => {
        const isSelected = frequency === frequencyItem.id;

        return (
          <div
            className={cn(
              'flex h-10 w-full cursor-pointer select-none items-center justify-center rounded-md bg-muted/70',
              {
                'bg-primary text-primary-foreground': isSelected,
                'hover:bg-muted': !isSelected,
              },
            )}
            onClick={() => {
              setFrequency(frequencyItem.id);
            }}
            key={frequencyItem.id}
          >
            {frequencyItem.label}
          </div>
        );
      })}
    </div>
  );
};
