import { z } from 'zod';

/////////////////////////////////////////
// REMINDER SCHEMA
/////////////////////////////////////////

export const ReminderSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  userId: z.string(),
  frequency: z.string(),
  frequencyDescription: z.string().nullable(),
  days: z.string().nullable(),
  dates: z.string().nullable(),
  months: z.string().nullable(),
  cron: z.string(),
  title: z.string(),
  description: z.string().nullable(),
})

export type Reminder = z.infer<typeof ReminderSchema>

export default ReminderSchema;
