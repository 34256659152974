import { useQuery } from '@tanstack/react-query';
import { listIncidentMessages } from '~/api';
import { useUser } from '~/auth/hooks';
import { BasicButton, Button, Link, Separator } from '~/components';
import { useCopy } from '~/copy/hooks';
import { useParams } from '~/hooks';
import { formatDateTime, numberWithCommas } from '~/utils';

export const IncidentMessages = ({ className }: { className?: string }) => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const { user } = useUser();
  const copy = useCopy();

  const { data: messages } = useQuery({
    queryKey: ['incidents', 'messages', incidentId],
    queryFn: async () => {
      const { incidentMessages } = await listIncidentMessages({ incidentId });

      return incidentMessages;
    },
  });

  if (!messages) return null;

  return (
    <div className={className}>
      <div className="flex flex-col gap-10 md:flex-row">
        <h1 className="text-left text-2xl font-bold md:text-3xl">
          {copy.get('messages')} ({numberWithCommas(messages.length)})
        </h1>

        <Link
          className="md:ml-auto"
          href={`/incidents/${incidentId}/messages/new`}
        >
          <Button className="w-full" size="md" label="addMessage" />
        </Link>
      </div>

      {messages.length > 0 ? (
        <div className="mt-10 space-y-6 md:space-y-10">
          {messages.map((message) => {
            const isCurrentUser = message.user.id === user.id;

            return (
              <div className="rounded-lg border" key={message.id}>
                <div className="flex flex-col gap-6 p-6 md:flex-row">
                  <div>{formatDateTime(message.updated)}</div>

                  <div className="text-muted-foreground md:ml-auto">
                    {message.user.name}
                  </div>
                </div>

                <Separator />

                <p
                  className="p-6 text-lg leading-relaxed text-muted-foreground"
                  dangerouslySetInnerHTML={{
                    __html: message.text.replace(/\n/g, '<br />') ?? '',
                  }}
                />

                {isCurrentUser && (
                  <>
                    <Separator />

                    <div className="flex flex-col gap-6 p-6 md:flex-row">
                      <Link
                        href={`/incidents/${incidentId}/messages/${message.id}/edit`}
                      >
                        <BasicButton
                          className="w-full"
                          variant="outline"
                          size="sm"
                        >
                          {copy.get('edit')}
                        </BasicButton>
                      </Link>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mt-10 text-balance rounded-lg border px-6 py-40 text-center text-lg font-medium text-muted-foreground">
          {copy.get('noMessagesAdded')}
        </div>
      )}
    </div>
  );
};
