import { z } from 'zod';

/////////////////////////////////////////
// GROUP MEMBER SCHEMA
/////////////////////////////////////////

export const GroupMemberSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  groupId: z.string(),
  memberId: z.string(),
})

export type GroupMember = z.infer<typeof GroupMemberSchema>

export default GroupMemberSchema;
