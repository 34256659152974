import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnalyticsProvider } from '~/analytics/components';
import { Ask, AskButton } from '~/ask/components';
import {
  AssetSettingsPage,
  AssetsPage,
  CreateAssetPage,
  ShareAssetPage,
} from '~/asset/pages';
import { AuthGuard, AuthProvider } from '~/auth/components';
import { SignInPage, SignOutPage, SignUpPage } from '~/auth/pages';
import { BanksGuard } from '~/bank/components';
import { BankConnectPage } from '~/bank/pages';
import {
  BoardPage,
  BoardSettingsPage,
  BoardsPage,
  CreateBoardPage,
  ShareBoardPage,
} from '~/board/pages';
import {
  ArchivedContentsPage,
  ContentPage,
  ContentSettings,
  ContentsPage,
  CreateContentPage,
  ShareContentPage,
} from '~/content/pages';
import { DashboardGuard, DashboardNav } from '~/dashboard/components';
import { DropboxConnectPage } from '~/dropbox/pages';
import { FilesGuard, FileViewer } from '~/file/components';
import { CreateGroupPage, EditGroupPage, GroupsPage } from '~/group/pages';
import { HomePage } from '~/home/pages';
import { IncidentAlertPage, IncidentAlertsPage } from '~/incident-alert/pages';
import {
  CreateIncidentMessagePage,
  EditIncidentMessagePage,
} from '~/incident-message/pages';
import {
  CreateIncidentPage,
  EditIncidentPage,
  IncidentPage,
  IncidentsPage,
  ResolvedIncidentsPage,
  SendIncidentAlertPage,
} from '~/incident/pages';
import { MeetingsPage, MeetingVideoPage } from '~/meeting/pages';
import { MemberInvite } from '~/member/components';
import {
  EditMemberPage,
  InviteMemberPage,
  MemberPage,
  MembersPage,
} from '~/member/pages';
import { NotFound } from '~/not-found/components';
import {
  CreatePasswordPage,
  EditPasswordPage,
  PasswordsPage,
  SharePasswordPage,
} from '~/password/pages';
import { Checkout, Plans } from '~/plan/components';
import { QueryProvider } from '~/query/components';
import { Realtime, RealtimeProvider } from '~/realtime/components';
import {
  CreateReminderPage,
  EditReminderPage,
  RemindersPage,
  ShareReminderPage,
} from '~/reminder/pages';
import {
  CreateServicePage,
  ServiceSettingsPage,
  ServicesPage,
  ShareServicePage,
} from '~/service/pages';
import { EditPronounciationsPage, SettingsPage } from '~/settings/pages';
import { Theme } from '~/theme/components';
import { TransactionsPage } from '~/transaction/pages';

export const App = () => {
  const location = useLocation();

  return (
    <AuthProvider>
      <AnalyticsProvider>
        <AnimatePresence mode="wait">
          <QueryProvider>
            <RealtimeProvider>
              <Theme defaultTheme="system">
                <Realtime />

                <DashboardNav />

                <Routes location={location} key={location.pathname}>
                  {/* site */}
                  <Route path="/" element={<HomePage />} />

                  {/* auth */}
                  <Route path="/sign-in" element={<SignInPage />} />
                  <Route path="/sign-up" element={<SignUpPage />} />
                  <Route path="/sign-out" element={<SignOutPage />} />

                  <Route path="/" element={<DashboardGuard />}>
                    {/* content */}
                    <Route path="/content" element={<FilesGuard />}>
                      <Route path="/content" element={<ContentsPage />} />
                      <Route
                        path="/content/new"
                        element={<CreateContentPage />}
                      />
                      <Route
                        path="/content/archive"
                        element={<ArchivedContentsPage />}
                      />
                      <Route
                        path="/content/:contentId"
                        element={<ContentPage />}
                      />
                      <Route
                        path="/content/:contentId/settings"
                        element={<ContentSettings />}
                      />
                      <Route
                        path="/content/:contentId/share"
                        element={<ShareContentPage />}
                      />
                    </Route>

                    {/* passwords */}
                    <Route path="/passwords" element={<PasswordsPage />} />
                    <Route
                      path="/passwords/new"
                      element={<CreatePasswordPage />}
                    />
                    <Route
                      path="/passwords/:passwordId/edit"
                      element={<EditPasswordPage />}
                    />
                    <Route
                      path="/passwords/:passwordId/share"
                      element={<SharePasswordPage />}
                    />

                    {/* boards */}
                    <Route path="/boards" element={<BoardsPage />} />
                    <Route path="/boards/new" element={<CreateBoardPage />} />
                    <Route path="/boards/:boardId" element={<BoardPage />} />
                    <Route
                      path="/boards/:boardId/settings"
                      element={<BoardSettingsPage />}
                    />
                    <Route
                      path="/boards/:boardId/share"
                      element={<ShareBoardPage />}
                    />

                    {/* groups */}
                    <Route path="/groups" element={<GroupsPage />} />
                    <Route path="/groups/new" element={<CreateGroupPage />} />
                    <Route
                      path="/groups/:groupId/edit"
                      element={<EditGroupPage />}
                    />

                    {/* members */}
                    <Route path="/members" element={<MembersPage />} />
                    <Route
                      path="/members/invite"
                      element={<InviteMemberPage />}
                    />
                    <Route path="/members/:memberId" element={<MemberPage />} />
                    <Route
                      path="/members/:memberId/edit"
                      element={<EditMemberPage />}
                    />

                    {/* meeting */}
                    <Route path="/meetings" element={<MeetingsPage />} />
                    <Route
                      path="/meetings/:meetingId"
                      element={<MeetingVideoPage />}
                    />

                    {/* incidents */}
                    <Route path="/incidents" element={<IncidentsPage />} />
                    <Route
                      path="/incidents/new"
                      element={<CreateIncidentPage />}
                    />
                    <Route
                      path="/incidents/resolved"
                      element={<ResolvedIncidentsPage />}
                    />
                    <Route
                      path="/incidents/:incidentId"
                      element={<IncidentPage />}
                    />
                    <Route
                      path="/incidents/:incidentId/edit"
                      element={<EditIncidentPage />}
                    />
                    <Route
                      path="/incidents/:incidentId/send-alert"
                      element={<SendIncidentAlertPage />}
                    />
                    <Route
                      path="/incidents/:incidentId/messages/new"
                      element={<CreateIncidentMessagePage />}
                    />
                    <Route
                      path="/incidents/:incidentId/messages/:incidentMessageId/edit"
                      element={<EditIncidentMessagePage />}
                    />
                    <Route
                      path="/incidents/:incidentId/alerts"
                      element={<IncidentAlertsPage />}
                    />
                    <Route
                      path="/incidents/:incidentId/alerts/:incidentAlertId"
                      element={<IncidentAlertPage />}
                    />

                    {/* reminders */}
                    <Route path="/reminders" element={<RemindersPage />} />
                    <Route
                      path="/reminders/new"
                      element={<CreateReminderPage />}
                    />
                    <Route
                      path="/reminders/:reminderId/edit"
                      element={<EditReminderPage />}
                    />
                    <Route
                      path="/reminders/:reminderId/share"
                      element={<ShareReminderPage />}
                    />

                    {/* services */}
                    <Route path="/services" element={<ServicesPage />} />
                    <Route
                      path="/services/new"
                      element={<CreateServicePage />}
                    />
                    <Route
                      path="/services/:serviceId/settings"
                      element={<ServiceSettingsPage />}
                    />
                    <Route
                      path="/services/:serviceId/share"
                      element={<ShareServicePage />}
                    />

                    {/* assets */}
                    <Route path="/assets" element={<AssetsPage />} />
                    <Route path="/assets/new" element={<CreateAssetPage />} />
                    <Route
                      path="/assets/:assetId/settings"
                      element={<AssetSettingsPage />}
                    />
                    <Route
                      path="/assets/:assetId/share"
                      element={<ShareAssetPage />}
                    />

                    {/* settings */}
                    <Route path="/settings" element={<SettingsPage />} />
                    <Route
                      path="/settings/pronunciations"
                      element={<EditPronounciationsPage />}
                    />

                    {/* dropbox */}
                    <Route
                      path="/dropbox/connect"
                      element={<DropboxConnectPage />}
                    />

                    {/* bank */}
                    <Route path="/bank/connect" element={<BankConnectPage />} />

                    {/* transactions */}
                    <Route path="/" element={<BanksGuard />}>
                      <Route
                        path="/transactions"
                        element={<TransactionsPage />}
                      />
                    </Route>
                  </Route>

                  <Route path="/" element={<AuthGuard />}>
                    {/* join */}
                    <Route path="/join/:code" element={<MemberInvite />} />

                    {/* plans */}
                    <Route
                      path="/plans"
                      element={
                        <main className="px-6 py-28 md:px-20 md:py-40">
                          <Plans />
                        </main>
                      }
                    />
                    <Route path="/plans/pay/checkout" element={<Checkout />} />
                  </Route>

                  {/* not found */}
                  <Route path="*" element={<NotFound />} />
                </Routes>

                {/* ask */}
                <Ask />
                <AskButton />

                {/* file */}
                <FileViewer />
              </Theme>
            </RealtimeProvider>
          </QueryProvider>
        </AnimatePresence>
      </AnalyticsProvider>
    </AuthProvider>
  );
};
