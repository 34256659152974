import { defineCopy } from '~/copy';

export default {
  groups: defineCopy({
    default: 'Groups',
    'de-DE': 'Gruppen',
    'es-ES': 'Contraseñas',
    'it-IT': 'Group',
    'fr-FR': 'Mots de passe',
  }),
  group: defineCopy({
    default: 'Group',
    'de-DE': 'Gruppe',
    'es-ES': 'Contraseña',
    'it-IT': 'Group',
    'fr-FR': 'Mot de passe',
  }),
  newGroup: defineCopy({
    default: 'New group',
    'de-DE': 'Neue Gruppe',
    'es-ES': 'Nueva contraseña',
    'it-IT': 'New group',
    'fr-FR': 'Nouveau mot de passe',
  }),
  createGroup: defineCopy({
    default: 'Create group',
    'de-DE': 'Gruppe erstellen',
    'es-ES': 'Crear contraseña',
    'it-IT': 'Create group',
    'fr-FR': 'Créer un mot de passe',
  }),
  groupName: defineCopy({
    default: 'Group name',
    'de-DE': 'Gruppenname',
    'es-ES': 'Nombre de la contraseña',
    'it-IT': 'Group name',
    'fr-FR': 'Nom du mot de passe',
  }),
  addGroup: defineCopy({
    default: 'Add group',
    'de-DE': 'Gruppe hinzufügen',
    'es-ES': 'Agregar contraseña',
    'it-IT': 'Add group',
    'fr-FR': 'Ajouter un mot de passe',
  }),
  noGroups: defineCopy({
    default: 'No groups have been added',
    'de-DE': 'Es wurden keine Gruppen hinzugefügt',
    'es-ES': 'No se han agregado contraseñas',
    'it-IT': 'No groups have been added',
    'fr-FR': "Aucun mot de passe n'a été ajouté",
  }),
  editGroup: defineCopy({
    default: 'Edit group',
    'de-DE': 'Gruppe bearbeiten',
    'es-ES': 'Editar contraseña',
    'it-IT': 'Edit group',
    'fr-FR': 'Modifier le mot de passe',
  }),
  deleteGroup: defineCopy({
    default: 'Deleting a group is permanent and cannot be undone.',
    'de-DE':
      'Das Löschen einer Gruppe ist endgültig und kann nicht rückgängig gemacht werden.',
    'es-ES': 'Eliminar una contraseña es permanente y no se puede deshacer.',
    'it-IT': 'Deleting a group is permanent and cannot be undone.',
    'fr-FR':
      'La suppression d’un mot de passe est définitive et ne peut pas être annulée.',
  }),
  shareGroup: defineCopy({
    default: 'Share group',
    'de-DE': 'Gruppe teilen',
    'es-ES': 'Compartir contraseña',
    'it-IT': 'Share group',
    'fr-FR': 'Partager le mot de passe',
  }),
};
