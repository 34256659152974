import { z } from 'zod';

/////////////////////////////////////////
// MEMBER NOTIFICATION SCHEMA
/////////////////////////////////////////

export const MemberNotificationSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  contentId: z.string(),
  notificationId: z.string(),
  memberId: z.string(),
  status: z.string(),
  error: z.string().nullable(),
  errorRetry: z.number().int().nullable(),
})

export type MemberNotification = z.infer<typeof MemberNotificationSchema>

export default MemberNotificationSchema;
