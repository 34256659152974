import { useQuery } from '@tanstack/react-query';
import { listContents } from '~/api';
import { Link, Separator } from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { formatDateTime } from '~/utils';

export const ArchivedContentsPage = () => {
  const copy = useCopy();

  const { data: contents } = useQuery({
    queryKey: ['content', 'archived'],
    queryFn: async () => {
      const { contents } = await listContents({
        filter: {
          isArchived: true,
        },
      });

      return contents;
    },
  });

  if (!contents) return null;

  return (
    <DashboardLayout headerTitle={copy.get('archive')} back="/content">
      <div className="mx-auto w-full max-w-5xl overflow-hidden rounded-2xl border dark:border-none dark:bg-muted/50">
        {contents.length > 0 ? (
          <>
            {contents.map((content, contentIndex) => {
              return (
                <div className="hover:bg-muted/40" key={content.id}>
                  {contentIndex !== 0 && <Separator className="opacity-90" />}

                  <Link href={`/content/${content.id}`}>
                    <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-8 xl:py-10">
                      <div className="font-semibold">{content.title}</div>

                      <div className="whitespace-nowrap text-muted-foreground lg:ml-auto">
                        {formatDateTime(content.updated, 'HH:mm - D MMM YYYY')}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </>
        ) : (
          <div className="text-balance px-6 py-40 text-center text-lg font-medium text-muted-foreground">
            {copy.get('noContentArchived')}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};
