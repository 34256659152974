import { Loader2 } from 'lucide-react';
import { BasicButton, ButtonProps } from '~/components';
import { CopyKey } from '~/copy';
import { useCopy } from '~/copy/hooks';

export const Button = ({
  label,
  className,
  variant,
  disabled,
  type,
  onClick,
  onMouseUp,
  isLoading,
  size,
}: {
  label: CopyKey;
  className?: string;
  variant?: ButtonProps['variant'];
  disabled?: boolean;
  type?: ButtonProps['type'];
  onClick?: ButtonProps['onClick'];
  onMouseUp?: ButtonProps['onClick'];
  isLoading?: boolean;
  size?: ButtonProps['size'];
}) => {
  const copy = useCopy();

  return (
    <BasicButton
      className={className}
      variant={variant}
      type={type}
      onClick={onClick}
      onMouseUp={onMouseUp}
      disabled={disabled ?? isLoading}
      size={size}
    >
      {isLoading ? (
        <Loader2 className="h-6 w-6 animate-spin" />
      ) : (
        copy.get(label)
      )}
    </BasicButton>
  );
};
