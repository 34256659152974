import { Link } from '~/components';
import { cn } from '~/style';
import { Content } from '~/types';
import { formatDateTime } from '~/utils';

export const Contents = ({
  contents,
  className,
}: {
  contents: Content[];
  className?: string;
}) => {
  return (
    <div className={cn('mx-auto max-w-5xl space-y-4', className)}>
      {contents.map((content) => {
        return (
          <div
            className="rounded-xl transition-colors dark:bg-muted/50 hover:dark:bg-muted/70"
            key={content.id}
          >
            <Link href={`/content/${content.id}`}>
              <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-8 xl:py-10">
                <div className="font-semibold">{content.title}</div>

                <div className="whitespace-nowrap text-muted-foreground lg:ml-auto">
                  {formatDateTime(content.updated, 'HH:mm - D MMM YYYY')}
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
