import { useClerk } from '@clerk/clerk-react';
import { useEffect } from 'react';

export const SignOutPage = () => {
  const { signOut } = useClerk();

  useEffect(() => {
    const fn = async () => {
      try {
        await signOut({ redirectUrl: '/' });
      } catch (error) {
        window.location.href = '/';
      }
    };

    fn();
  }, []);

  return (
    <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
      <div className="h-10 w-10">
        <div className="loader" />
      </div>
    </div>
  );
};
