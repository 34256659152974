import { z } from 'zod';

/////////////////////////////////////////
// INCIDENT TRANSLATION SCHEMA
/////////////////////////////////////////

export const IncidentTranslationSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  incidentId: z.string(),
  languageId: z.string(),
  description: z.string(),
  title: z.string().nullable(),
  location: z.string().nullable(),
  summary: z.string().nullable(),
})

export type IncidentTranslation = z.infer<typeof IncidentTranslationSchema>

export default IncidentTranslationSchema;
