import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { getPayUrl } from '~/api';
import { BasicButton } from '~/components';
import { sentry } from '~/sentry';

export const Pay = () => {
  const payUrl = useMutation({
    mutationFn: async () => {
      const { url } = await getPayUrl({});

      return { url };
    },
    onSuccess: ({ url }) => {
      window.location.href = url;
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <div className="flex h-screen w-screen items-center justify-center p-8">
      <BasicButton
        className="mx-auto flex gap-6"
        size="lg"
        onClick={() => {
          payUrl.mutate();
        }}
      >
        {payUrl.isPending ? (
          <Loader2 className="h-6 w-6 animate-spin" />
        ) : (
          <>
            <span>Continue to payment</span>

            <svg
              className="size-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
              />
            </svg>
          </>
        )}
      </BasicButton>
    </div>
  );
};
