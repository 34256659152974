import dayjs from 'dayjs';
import { Currency, getCurrencyByShortName } from '~/currency';
import { cn } from '~/style';
import { useTransactions } from '~/transaction/hooks';

export const Transactions = () => {
  const { transactions } = useTransactions();

  if (!transactions) return null;

  return (
    <div className="space-y-4">
      {transactions.map((transaction) => {
        const currency = getCurrencyByShortName(transaction.amountCurrency);

        return (
          <div
            className="flex items-center gap-4 rounded-xl border p-6"
            key={transaction.id}
          >
            <div className="space-y-1">
              <div>{transaction.remittanceInformationUnstructured}</div>

              <div className="text-sm text-muted-foreground">
                {dayjs(transaction.bookingDate).format('DD MMM YY')}
              </div>
            </div>

            <div className="ml-auto">
              <Amount amountText={transaction.amountText} currency={currency} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Amount = ({
  amountText,
  currency,
}: {
  amountText: string;
  currency: Currency;
}) => {
  const isNegative = amountText.startsWith('-');
  const isPositive = !isNegative;
  const text = isNegative ? amountText.slice(1) : amountText;

  return (
    <div className={cn('font-semibold', { 'text-success': isPositive })}>
      {isNegative ? '-' : '+'}
      {currency.symbol}
      {text}
    </div>
  );
};
