import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { getBankConnectionUrl, listBanks } from '~/api';
import { Bank } from '~/types';

const initialState = {
  banks: [] as Bank[],
  selectedBankLoading: null as string | null,
  error: null as string | null,
};

export const banksStore = create(
  combine(initialState, (set) => ({
    load: async () => {
      try {
        const { banks } = await listBanks({});

        set(() => ({
          banks,
        }));
      } catch (error) {}
    },

    selectBank: async (bankId: string) => {
      set(() => ({
        selectedBankLoading: bankId,
      }));

      try {
        const { url } = await getBankConnectionUrl({ bankId });

        if (!url) throw new Error();

        window.location.href = url;
      } catch (error) {
        set(() => ({
          selectedBankLoading: null,
          error: 'Unable to connect to bank',
        }));
      }
    },
  })),
);

export function useBanks() {
  const store = banksStore();

  return {
    ...store,
  };
}
