import { z } from 'zod';

/////////////////////////////////////////
// BOARD STAGE SCHEMA
/////////////////////////////////////////

export const BoardStageSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  boardId: z.string(),
  title: z.string(),
  position: z.number().int(),
})

export type BoardStage = z.infer<typeof BoardStageSchema>

export default BoardStageSchema;
