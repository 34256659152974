import { motion } from 'framer-motion';
import { Link } from '~/components';

export const ActionButton = ({ href }: { href: string }) => {
  return (
    <Link href={href}>
      <motion.div
        className="fixed bottom-6 right-6 flex h-14 w-14 select-none items-center justify-center rounded-full bg-primary lg:bottom-8 lg:right-8 lg:h-16 lg:w-16 xl:bottom-12 xl:right-12"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: 'spring' }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <svg
          className="size-6 text-background lg:size-7"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
      </motion.div>
    </Link>
  );
};
