import { useQuery } from '@tanstack/react-query';
import { listIncidents } from '~/api';
import { ActionButton, Link, Separator } from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { formatDateTime } from '~/utils';

export const ResolvedIncidentsPage = () => {
  const copy = useCopy();

  const { data: incidents } = useQuery({
    queryKey: ['incidents', 'resolved'],
    queryFn: async () => {
      const { incidents } = await listIncidents({
        filter: {
          isResolved: true,
        },
      });

      return incidents;
    },
  });

  if (!incidents) return null;

  return (
    <DashboardLayout headerTitle={copy.get('resolved')}>
      <div className="mx-auto w-full max-w-5xl space-y-6">
        {incidents.length > 0 ? (
          <div className="mx-auto w-full max-w-5xl overflow-hidden rounded-lg border">
            {incidents.map((incident, incidentIndex) => {
              return (
                <div className="hover:bg-muted/40" key={incident.id}>
                  {incidentIndex !== 0 && <Separator />}

                  <Link href={`/incidents/${incident.id}`}>
                    <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-6 xl:py-8">
                      <div className="font-semibold">{incident.title}</div>

                      <div className="whitespace-nowrap text-muted-foreground lg:ml-auto">
                        {formatDateTime(incident.date, 'D MMM YY')}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-balance rounded-lg border px-6 py-40 text-center text-lg font-medium text-muted-foreground">
            {copy.get('noIncidentsResolved')}
          </div>
        )}
      </div>

      <ActionButton href="/incidents/new" />
    </DashboardLayout>
  );
};
