import { useMutation, useQuery } from '@tanstack/react-query';
import Fuse from 'fuse.js';
import { useState } from 'react';
import { listRemoteFiles, setFirstFileConnectionFolder } from '~/api';
import { Button, Input } from '~/components';
import { useCopy } from '~/copy/hooks';
import { useFilesConnection } from '~/file/hooks';
import { RemoteFile } from '~/types';

export const PathSelector = () => {
  const [selectedFolder, setSelectedFolder] = useState<RemoteFile | null>(null);
  const { load } = useFilesConnection();
  const copy = useCopy();
  const [searchQuery, setSearchQuery] = useState('');

  const { data: allFolders } = useQuery({
    queryKey: ['files'],
    queryFn: async () => {
      const { files } = await listRemoteFiles({ path: '' });
      const folders = files
        .filter((file) => file.isFolder)
        .sort((a, b) => a.name.localeCompare(b.name));

      return folders;
    },
  });

  const update = useMutation({
    mutationFn: async () => {
      if (!selectedFolder) return;

      await setFirstFileConnectionFolder({
        folderId: selectedFolder.id,
        folderPath: selectedFolder.path || '',
      });
      await load();
    },
    onSuccess: () => {},
  });

  if (!allFolders) return null;

  const fuse = new Fuse(allFolders, {
    keys: ['name'],
    threshold: 0.3,
  });

  const folders = searchQuery
    ? fuse.search(searchQuery).map((result) => result.item)
    : allFolders;

  return (
    <div className="space-y-6 md:space-y-10">
      <div className="space-y-2">
        <h1 className="text-xl font-semibold md:text-2xl">
          {copy.get(selectedFolder ? 'selectedFolder' : 'selectAFolder')}
        </h1>

        <p className="text-sm text-muted-foreground">
          {copy.get('chooseFolderDescription')}
        </p>
      </div>

      {!selectedFolder && (
        <Input
          placeholder={copy.get('search') + '...'}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
      )}

      {selectedFolder ? (
        <div className="space-y-6">
          <div className="flex items-center gap-4 rounded-xl border p-6">
            <div>{selectedFolder.name}</div>
          </div>

          <Button
            label="changeFolder"
            variant="outline"
            size="sm"
            onClick={() => {
              setSelectedFolder(null);
              setSearchQuery('');
            }}
          />
        </div>
      ) : (
        <div className="space-y-4">
          {folders.map((folder) => {
            return (
              <div
                className="flex cursor-pointer items-center gap-4 rounded-xl border p-6 hover:bg-muted/40"
                key={folder.id}
                onClick={() => setSelectedFolder(folder)}
              >
                <div className="text-muted-foreground">{folder.name}</div>
              </div>
            );
          })}
        </div>
      )}

      <div className="fixed bottom-0 left-0 right-0 border-t bg-background p-4 md:py-6">
        <div className="mx-auto w-40 md:w-52">
          <Button
            className="w-full"
            onClick={() => update.mutate()}
            isLoading={update.isPending}
            disabled={update.isPending || !selectedFolder}
            label="chooseFolder"
          />
        </div>
      </div>
    </div>
  );
};
