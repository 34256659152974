import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { enableBankConnection } from '~/api';

export const BankConnectPage = () => {
  const [searchParams] = useSearchParams();
  const ref = searchParams.get('ref');
  const navigate = useNavigate();
  const hasLoadedRef = useRef(false);

  useEffect(() => {
    const fn = async () => {
      if (hasLoadedRef.current) return;

      hasLoadedRef.current = true;

      if (!ref) {
        navigate('/transactions');
        return;
      }

      try {
        await enableBankConnection({ requisitionId: ref });
        navigate('/transactions');
      } catch (error) {
        navigate('/transactions');
      }
    };

    fn();
  }, [ref, navigate]);

  return (
    <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
      <div className="h-10 w-10">
        <div className="loader" />
      </div>
    </div>
  );
};
