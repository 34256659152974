import { z } from 'zod';

/////////////////////////////////////////
// MEETING SCHEMA
/////////////////////////////////////////

export const MeetingSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  userId: z.string(),
  hostId: z.string().nullable(),
})

export type Meeting = z.infer<typeof MeetingSchema>

export default MeetingSchema;
