import { useEffect, useState } from 'react';
import { getService } from '~/api';
import { Redirect } from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { useParams } from '~/hooks';
import { getOrgId } from '~/org';
import { Share } from '~/share/components';
import { useShare } from '~/share/hooks';
import { Service } from '~/types';

export const ShareServicePage = () => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const [service, setService] = useState<Service | null>(null);

  useEffect(() => {
    const fn = async () => {
      const orgId = getOrgId();
      const { service } = await getService({ orgId, serviceId });

      setService(service);
    };

    fn();
  }, [serviceId]);

  if (!service) return null;

  return <ShareService service={service} />;
};

export const ShareService = ({ service }: { service: Service }) => {
  const copy = useCopy();
  const { canShare } = useShare(service);

  if (!canShare) return <Redirect path="/services" />;

  return (
    <DashboardLayout
      headerTitle={copy.get('shareService')}
      back={`/services/${service.id}/settings`}
      size="full"
      showHeaderBorder
    >
      <Share
        resourceType="service"
        resourceId={service.id}
        saveSuccessPath="/services"
      />
    </DashboardLayout>
  );
};
