import { z } from 'zod';

/////////////////////////////////////////
// CONTENT NOTIFICATION SCHEMA
/////////////////////////////////////////

export const ContentNotificationSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  contentId: z.string(),
  status: z.string(),
})

export type ContentNotification = z.infer<typeof ContentNotificationSchema>

export default ContentNotificationSchema;
