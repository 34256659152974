export const getRootDomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');

  // Handle cases like 'localhost' or IP addresses
  if (parts.length <= 2 || hostname === 'localhost') {
    return hostname;
  }

  // Get the last two parts (root domain)
  return parts.slice(-2).join('.');
};
