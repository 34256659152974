import { defaultDashboardPath } from '~/auth';
import { BasicButton, Link } from '~/components';

export const NotFound = () => {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="space-y-8 p-6 text-center md:space-y-12">
        <h1 className="text-xl font-bold md:text-2xl">Page not found</h1>

        <div>
          <Link href={defaultDashboardPath}>
            <BasicButton>Go Home</BasicButton>
          </Link>
        </div>
      </div>
    </div>
  );
};
