import { useEffect } from 'react';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { listBankConnections } from '~/api';

const initialState = {
  isLoading: true,
  hasConnection: false,
};

export const hasBankConnectionsStore = create(
  combine(initialState, (set) => ({
    load: async () => {
      const { connections } = await listBankConnections({});

      set(() => ({
        isLoading: false,
        hasConnection: connections.length > 0,
      }));
    },
  })),
);

export function useHasBankConnections() {
  const store = hasBankConnectionsStore();

  useEffect(() => {
    store.load();
  }, []);

  return {
    ...store,
  };
}
