import { CurrencySelector } from '~/components';
import { useCopy } from '~/copy/hooks';

export const FormCurrency = ({
  currencyId,
  setCurrencyId,
}: {
  currencyId: string;
  setCurrencyId: (value: string) => void;
}) => {
  const copy = useCopy();

  return (
    <div className="space-y-4">
      <div>{copy.get('currency')}</div>

      <div className="rounded-xl border p-6">
        <CurrencySelector
          currencyId={currencyId}
          setCurrencyId={setCurrencyId}
        />
      </div>
    </div>
  );
};
