import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { Form, FormInput, FormSubmitButton, FormSwitch } from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { sentry } from '~/sentry';

export const CreateContentPage = () => {
  const [title, setTitle] = useState('');
  const copy = useCopy();

  const [autoGenerateAudio, setAutoGenerateAudio] = useState(false);

  const create = useMutation({
    mutationFn: async () => {},
    onSuccess: async () => {},
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <DashboardLayout headerTitle={copy.get('addContent')} size="sm">
      <Form
        onSubmit={() => {
          create.mutate();
        }}
      >
        <FormInput
          value={title}
          onChange={setTitle}
          label="contentTitle"
          placeholder="title"
        />

        <FormSwitch
          id="auto-generate-audio"
          label="audio"
          description="autoGenerateAudioDescription"
          value={autoGenerateAudio}
          onChange={setAutoGenerateAudio}
        />

        <FormSubmitButton isLoading={create.isPending} label="addContent" />
      </Form>
    </DashboardLayout>
  );
};
