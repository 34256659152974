import { useQuery } from '@tanstack/react-query';
import { listMembers } from '~/api';
import { useUser } from '~/auth/hooks';
import { Badge, BasicButton, Link, Separator } from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { MemberInvites } from '~/member/components';
import { useOrg } from '~/org/hooks';

export const MembersPage = () => {
  const { user } = useUser();
  const copy = useCopy();
  const { isOwner } = useOrg();

  const { data: members } = useQuery({
    queryKey: ['members'],
    queryFn: async () => {
      const { members } = await listMembers({});

      return members;
    },
  });

  if (!members) return null;

  return (
    <DashboardLayout
      headerTitle={copy.get('members')}
      headerOptions={
        <>
          {isOwner() && (
            <Link href="/members/invite">
              <BasicButton size="sm">
                <svg
                  className="-ml-2 mr-4 size-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>

                <span>
                  <span className="hidden md:inline">Add </span>

                  <span>Member</span>
                </span>
              </BasicButton>
            </Link>
          )}
        </>
      }
    >
      <div className="mx-auto w-full max-w-5xl space-y-6">
        {members.length > 0 && (
          <div className="mx-auto w-full max-w-5xl overflow-hidden rounded-lg border">
            {members.map((member, memberIndex) => {
              const isCurrentUser = member.memberId === user.id;

              return (
                <div className="hover:bg-muted/40" key={member.member.id}>
                  {memberIndex !== 0 && <Separator />}

                  <Link href={`/members/${member.member.id}`}>
                    <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:gap-14 lg:py-6 xl:py-8">
                      <>
                        <div className="flex items-center gap-4">
                          <div className="font-semibold">
                            {member.member.name}
                          </div>

                          {isCurrentUser && (
                            <Badge variant="secondary">{copy.get('you')}</Badge>
                          )}
                        </div>

                        <div className="text-muted-foreground">
                          {member.member.email}
                        </div>

                        <div className="text-muted-foreground lg:ml-auto">
                          {member.member.phone}
                        </div>
                      </>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <MemberInvites showSeparator />
    </DashboardLayout>
  );
};
