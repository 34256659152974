import { defineCopy } from '~/copy';

export default {
  asset: defineCopy({
    default: 'Asset',
    'de-DE': 'Vermögenswert',
    'es-ES': 'Activo',
    'it-IT': 'Bene',
    'fr-FR': 'Actif',
  }),
  assets: defineCopy({
    default: 'Assets',
    'de-DE': 'Vermögenswerte',
    'es-ES': 'Activos',
    'it-IT': 'Beni',
    'fr-FR': 'Actifs',
  }),
  newAsset: defineCopy({
    default: 'New asset',
    'de-DE': 'Neuer Vermögenswert',
    'es-ES': 'Nuevo activo',
    'it-IT': 'Nuovo bene',
    'fr-FR': 'Nouvel actif',
  }),
  assetName: defineCopy({
    default: 'Asset name',
    'de-DE': 'Name des Vermögenswerts',
    'es-ES': 'Nombre del activo',
    'it-IT': 'Nome del bene',
    'fr-FR': "Nom de l'actif",
  }),
  addAsset: defineCopy({
    default: 'Add asset',
    'de-DE': 'Vermögenswert hinzufügen',
    'es-ES': 'Agregar activo',
    'it-IT': 'Aggiungi bene',
    'fr-FR': 'Ajouter un actif',
  }),
  noAsset: defineCopy({
    default: 'No asset have been added',
    'de-DE': 'Kein Vermögenswert wurde hinzugefügt',
    'es-ES': 'No se ha agregado ningún activo',
    'it-IT': 'Nessun bene è stato aggiunto',
    'fr-FR': "Aucun actif n'a été ajouté",
  }),
  editAsset: defineCopy({
    default: 'Edit asset',
    'de-DE': 'Vermögenswert bearbeiten',
    'es-ES': 'Editar activo',
    'it-IT': 'Modifica bene',
    'fr-FR': "Modifier l'actif",
  }),
  deleteAsset: defineCopy({
    default: 'Deleting an asset is permanent and cannot be undone.',
    'de-DE':
      'Das Löschen eines Vermögenswerts ist dauerhaft und kann nicht rückgängig gemacht werden.',
    'es-ES': 'Eliminar un activo es permanente y no se puede deshacer.',
    'it-IT': 'Eliminare un bene è definitivo e non può essere annullato.',
    'fr-FR':
      "La suppression d'un actif est permanente et ne peut pas être annulée.",
  }),
  shareAsset: defineCopy({
    default: 'Share asset',
    'de-DE': 'Vermögenswert teilen',
    'es-ES': 'Compartir activo',
    'it-IT': 'Condividi bene',
    'fr-FR': "Partager l'actif",
  }),
  noAssets: defineCopy({
    default: 'No assets have been added',
    'de-DE': 'Keine Vermögenswerte wurden hinzugefügt',
    'es-ES': 'No se han agregado activos',
    'it-IT': 'Nessun bene è stato aggiunto',
    'fr-FR': "Aucun actif n'a été ajouté",
  }),
};
