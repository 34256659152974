import { useEffect, useState } from 'react';
import { getAsset } from '~/api';
import { Redirect } from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { useParams } from '~/hooks';
import { getOrgId } from '~/org';
import { Share } from '~/share/components';
import { useShare } from '~/share/hooks';
import { Asset } from '~/types';

export const ShareAssetPage = () => {
  const { assetId } = useParams<{ assetId: string }>();
  const [asset, setAsset] = useState<Asset | null>(null);

  useEffect(() => {
    const fn = async () => {
      const orgId = getOrgId();
      const { asset } = await getAsset({ orgId, assetId });

      setAsset(asset);
    };

    fn();
  }, [assetId]);

  if (!asset) return null;

  return <ShareAsset asset={asset} />;
};

export const ShareAsset = ({ asset }: { asset: Asset }) => {
  const copy = useCopy();
  const { canShare } = useShare(asset);

  if (!canShare) return <Redirect path="/assets" />;

  return (
    <DashboardLayout
      headerTitle={copy.get('shareAsset')}
      back={`/assets/${asset.id}/settings`}
      size="full"
      showHeaderBorder
    >
      <Share
        resourceType="asset"
        resourceId={asset.id}
        saveSuccessPath="/assets"
      />
    </DashboardLayout>
  );
};
