import { useEffect } from 'react';

export function useKeyboard({
  onKeyDown,
}: {
  onKeyDown?: (options: { key: string }) => void;
}) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      onKeyDown?.({ key: event.key });
    };

    if (onKeyDown) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (onKeyDown) {
        window.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);
}
