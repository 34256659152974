import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getShare, updateShare } from '~/api';
import { Button, Link, Separator } from '~/components';
import { useCopy } from '~/copy/hooks';
import { GroupsSelect } from '~/group/components';
import { useGroups, useSelectGroups } from '~/group/hooks';
import { MembersSelect } from '~/member/components';
import { useMembers, useSelectMembers } from '~/member/hooks';
import { invalidateQueries } from '~/query';
import { sentry } from '~/sentry';
import {
  GroupExpanded,
  OrgMemberExpanded,
  ResourceType,
  ShareGroupExpanded,
  ShareMemberExpanded,
} from '~/types';

export const Share = ({
  resourceType,
  resourceId,
  saveSuccessPath,
}: {
  resourceType: ResourceType;
  resourceId: string;
  saveSuccessPath: string;
}) => {
  const { data } = useQuery({
    queryKey: ['share', resourceId],
    queryFn: async () => {
      const { shareGroups, shareMembers } = await getShare({ resourceId });

      return { shareGroups, shareMembers };
    },
  });
  const { groups } = useGroups();
  const { members } = useMembers({ omitCurrentUser: true });

  if (!data || !groups || !members) return null;

  const shareGroups = data.shareGroups;
  const shareMembers = data.shareMembers;

  return (
    <ShareForm
      groups={groups}
      members={members}
      resourceType={resourceType}
      resourceId={resourceId}
      shareGroups={shareGroups}
      shareMembers={shareMembers}
      saveSuccessPath={saveSuccessPath}
    />
  );
};

const ShareForm = ({
  groups,
  members,
  resourceType,
  resourceId,
  shareGroups,
  shareMembers,
  saveSuccessPath,
}: {
  groups: GroupExpanded[];
  members: OrgMemberExpanded[];
  resourceType: ResourceType;
  resourceId: string;
  shareGroups: ShareGroupExpanded[];
  shareMembers: ShareMemberExpanded[];
  saveSuccessPath: string;
}) => {
  const copy = useCopy();
  const { groupIds, setGroupId } = useSelectGroups(shareGroups);
  const { memberIds, setMemberId } = useSelectMembers(shareMembers);
  const navigate = useNavigate();

  const update = useMutation({
    mutationFn: async () => {},
    onSuccess: async () => {
      await updateShare({
        resourceType,
        resourceId,
        groupIds,
        memberIds,
      });

      invalidateQueries(['share']);
      navigate(saveSuccessPath);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <>
      <div className="space-y-8 md:space-y-10">
        <div className="mx-auto w-full max-w-5xl space-y-8">
          <h2 className="text-lg md:text-xl lg:text-2xl">
            {copy.get('groups')}
            {groupIds.length > 0 && ` (${groupIds.length})`}
          </h2>

          <GroupsSelect
            groups={groups}
            groupIds={groupIds}
            onGroupSelect={setGroupId}
          />
        </div>

        <Separator />

        <div className="mx-auto w-full max-w-5xl space-y-8">
          <h2 className="text-lg md:text-xl lg:text-2xl">
            {copy.get('members')}
            {memberIds.length > 0 && ` (${memberIds.length})`}
          </h2>

          <MembersSelect
            members={members}
            memberIds={memberIds}
            onMemberSelect={setMemberId}
            noMembers={
              <div className="mx-auto w-full max-w-5xl space-y-8 rounded-xl border p-8 text-center md:space-y-10 md:p-14 lg:p-16">
                <h1 className="text-lg md:text-xl lg:text-2xl">
                  {copy.get('inviteMemberToShare')}
                </h1>

                <div>
                  <Link href="/members/invite">
                    <Button
                      className="w-40 md:w-52"
                      label="inviteMember"
                      variant="outline"
                    />
                  </Link>
                </div>
              </div>
            }
          />
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0 border-t bg-background p-4 md:py-6">
        <div className="mx-auto w-40 md:w-52">
          <Button
            className="w-full"
            onClick={() => update.mutate()}
            isLoading={update.isPending}
            label="save"
          />
        </div>
      </div>
    </>
  );
};
