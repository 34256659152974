import { LiveblocksProvider } from '@liveblocks/react/suspense';
import { getRealtimeToken } from '~/api';

export const RealtimeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <LiveblocksProvider
      authEndpoint={async (room) => {
        if (!room) return { token: '' };

        try {
          const { token } = await getRealtimeToken({ room });

          return {
            token,
          };
        } catch (error) {
          return {
            token: '',
          };
        }
      }}
    >
      {children}
    </LiveblocksProvider>
  );
};
