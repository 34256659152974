import { z } from 'zod';

/////////////////////////////////////////
// BANK INSTITUTION SCHEMA
/////////////////////////////////////////

export const BankInstitutionSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  name: z.string(),
  logo: z.string(),
})

export type BankInstitution = z.infer<typeof BankInstitutionSchema>

export default BankInstitutionSchema;
