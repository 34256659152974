import { z } from 'zod';

/////////////////////////////////////////
// SERVICE SCHEMA
/////////////////////////////////////////

export const ServiceSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  userId: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  cost: z.number().int(),
  costText: z.string(),
  currencyId: z.string(),
  frequency: z.string(),
  discount: z.number().int(),
  discountType: z.string().nullable(),
})

export type Service = z.infer<typeof ServiceSchema>

export default ServiceSchema;
