import { z } from 'zod';

/////////////////////////////////////////
// DROPBOX CONNECTION SCHEMA
/////////////////////////////////////////

export const DropboxConnectionSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  userId: z.string(),
  dropboxAccountId: z.string(),
  refreshToken: z.string(),
  accessToken: z.string(),
  expires: z.number().int(),
  data: z.string(),
  folderId: z.string().nullable(),
  folderPath: z.string().nullable(),
  cursor: z.string().nullable(),
})

export type DropboxConnection = z.infer<typeof DropboxConnectionSchema>

export default DropboxConnectionSchema;
