import { z } from 'zod';

/////////////////////////////////////////
// TRANSACTION SCHEMA
/////////////////////////////////////////

export const TransactionSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  userId: z.string(),
  bankConnectionId: z.string(),
  bankAccountId: z.string(),
  bookingDate: z.string(),
  amount: z.number().int(),
  amountText: z.string(),
  amountCurrency: z.string(),
  currencyExchangeInstructedAmount: z.number().int().nullable(),
  currencyExchangeInstructedAmountText: z.string().nullable(),
  currencyExchangeInstructedAmountCurrency: z.string().nullable(),
  currencyExchangeSourceCurrency: z.string().nullable(),
  currencyExchangeExchangeRate: z.string().nullable(),
  currencyExchangeUnitCurrency: z.string().nullable(),
  currencyExchangeTargetCurrency: z.string().nullable(),
  creditorName: z.string().nullable(),
  remittanceInformationUnstructured: z.string(),
  proprietaryBankTransactionCode: z.string().nullable(),
  merchantCategoryCode: z.string().nullable(),
  internalTransactionId: z.string().nullable(),
})

export type Transaction = z.infer<typeof TransactionSchema>

export default TransactionSchema;
