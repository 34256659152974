import { useTracks } from '@livekit/components-react';
import { Track } from 'livekit-client';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';

const initialState = {
  isVisible: false,
};

export const participantsStore = create(
  combine(initialState, (set) => ({
    setUserName: (isVisible: boolean) => {
      set({ isVisible });
    },

    setIsVisible: (isVisible: boolean) => {
      set({ isVisible });
    },

    toggle: () => set((state) => ({ isVisible: !state.isVisible })),
  })),
);

export function useParticipants() {
  const store = participantsStore();

  const videoTracks = useTracks([
    { source: Track.Source.Camera, withPlaceholder: true },
    { source: Track.Source.ScreenShare, withPlaceholder: false },
  ]);

  return {
    ...store,
    videoTracks,
  };
}
