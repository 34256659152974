import { motion } from 'framer-motion';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { useCopy } from '~/copy/hooks';
import { cn } from '~/style';

export const CopyPassword = ({
  text,
  label,
}: {
  text: string | null | undefined;
  label?: string;
}) => {
  const [copied, setCopied] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const copy = useCopy();

  const copyText = () => {
    if (!text) return null;

    const formattedText =
      text.substring(0, 2) === '\n\n' ? text.replace('\n\n', '') : text;

    navigator.clipboard.writeText(formattedText);

    setCopied(true);

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <div className="relative">
      {label && (
        <div className="text-xxs absolute left-3 top-0 z-30 -translate-y-1/2 bg-background px-2 font-bold uppercase text-muted-foreground">
          {label}
        </div>
      )}

      <motion.div
        className="border-neutral relative flex h-16 cursor-pointer items-center overflow-hidden rounded-lg border px-4"
        whileHover="hover"
        whileTap="tap"
      >
        <div
          className="absolute left-0 top-0 z-10 h-full w-full"
          onClick={() => {
            copyText();
          }}
        />

        <motion.div
          className="absolute left-4 flex h-full items-center text-success"
          initial={{ top: '-100%' }}
          animate={{
            top: copied ? 0 : '-100%',
          }}
        >
          {copy.get('copied')}
        </motion.div>

        <motion.div
          className={cn(
            'absolute left-4 flex h-full select-none items-center',
            {
              'tracking-widest': !isVisible,
            },
          )}
          initial={{ top: 0, opacity: 0.5 }}
          animate={{
            top: copied ? '100%' : 0,
          }}
          variants={{
            hover: {
              opacity: 1,
            },
          }}
        >
          {isVisible ? (
            text
          ) : (
            <>
              <span>{_.times(10, () => '•').join('')}</span>
              <span className="hidden md:inline">
                {_.times(10, () => '•').join('')}
              </span>
              <span className="hidden lg:inline">
                {_.times(10, () => '•').join('')}
              </span>
            </>
          )}
        </motion.div>

        <motion.div
          className="pointer-events-none ml-auto"
          initial={{ opacity: 0.5 }}
          variants={{
            hover: {
              opacity: 1,
            },
          }}
        >
          <svg
            className="ml-auto size-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.5 8.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v8.25A2.25 2.25 0 0 0 6 16.5h2.25m8.25-8.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-7.5A2.25 2.25 0 0 1 8.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 0 0-2.25 2.25v6"
            />
          </svg>
        </motion.div>

        <div
          className="absolute right-12 top-1/2 z-20 -translate-y-1/2 p-3 md:right-16"
          onClick={() => setIsVisible(!isVisible)}
        >
          <motion.div
            initial={{ opacity: 0.5 }}
            variants={{
              hover: {
                opacity: 1,
              },
            }}
          >
            {isVisible ? (
              <svg
                className="size-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            ) : (
              <svg
                className="size-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
            )}
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};
