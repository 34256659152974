import { useIncident } from '~/incident/hooks';

export const IncidentProvider = ({
  incidentId,
  children,
}: {
  incidentId: string;
  children: React.ReactNode;
}) => {
  const incident = useIncident(incidentId);

  if (!incident) return null;

  return children;
};
