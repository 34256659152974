import { useQuery } from '@tanstack/react-query';
import { listBoardStages } from '~/api';

export function useBoardStages(boardId: string) {
  const { data: stages } = useQuery({
    queryKey: ['boards', boardId, 'stages'],
    queryFn: async () => {
      const { stages } = await listBoardStages({ boardId });

      return stages;
    },
  });

  return { stages };
}
