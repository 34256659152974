import { z } from 'zod';

/////////////////////////////////////////
// ACTION SCHEMA
/////////////////////////////////////////

export const ActionSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  userId: z.string(),
  orgId: z.string(),
  name: z.string(),
})

export type Action = z.infer<typeof ActionSchema>

export default ActionSchema;
