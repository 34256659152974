import { ButtonProps } from '~/components';
import { FormSubmitButton } from '~/components/form';
import { CopyKey } from '~/copy';

export const FormSubmit = ({
  label,
  onSubmit,
  isLoading,
}: {
  label: CopyKey;
  onSubmit?: ButtonProps['onClick'];
  isLoading: boolean;
}) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 border-t bg-background p-4 md:py-6">
      <div className="mx-auto w-40 md:w-52">
        <FormSubmitButton
          isLoading={isLoading}
          label={label}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};
