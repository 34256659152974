import { z } from 'zod';

/////////////////////////////////////////
// FILE SCHEMA
/////////////////////////////////////////

export const FileSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  userId: z.string(),
  orgId: z.string(),
  name: z.string(),
  path: z.string(),
  isFolder: z.boolean(),
  isFile: z.boolean(),
  type: z.string().nullable(),
  size: z.number().int().nullable(),
})

export type File = z.infer<typeof FileSchema>

export default FileSchema;
