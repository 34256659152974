import { motion } from 'framer-motion';
import { useAsk } from '~/ask/hooks';
import { useDashboard } from '~/dashboard/hooks';

export const AskButton = () => {
  const { toggle } = useAsk();
  const { shouldShowDashboardItems } = useDashboard();

  if (!shouldShowDashboardItems) return null;

  return (
    <motion.div
      className="fixed bottom-3 right-3 flex h-14 w-14 cursor-pointer select-none items-center justify-center rounded-full bg-muted lg:bottom-5 lg:right-5 lg:h-16 lg:w-16"
      initial={{ scale: 0, opacity: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      transition={{
        type: 'spring',
      }}
      whileHover={{
        scale: 1.1,
      }}
      whileTap={{
        scale: 0.9,
      }}
      onClick={toggle}
    >
      <div className="h-6 w-6 rounded-md border-[3px] border-foreground lg:h-7 lg:w-7 lg:border-4" />
    </motion.div>
  );
};
