import { z } from 'zod';

/////////////////////////////////////////
// MEMBER INCIDENT ALERT SCHEMA
/////////////////////////////////////////

export const MemberIncidentAlertSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  incidentId: z.string(),
  incidentAlertId: z.string(),
  memberId: z.string(),
  status: z.string(),
  error: z.string().nullable(),
  errorRetry: z.number().int().nullable(),
})

export type MemberIncidentAlert = z.infer<typeof MemberIncidentAlertSchema>

export default MemberIncidentAlertSchema;
