import { useQuery } from '@tanstack/react-query';
import { listTransactions } from '~/api';

export function useTransactions() {
  const { data } = useQuery({
    queryKey: ['transactions'],
    queryFn: async () => {
      const { bankInstitutions, transactions } = await listTransactions({});

      return { bankInstitutions, transactions };
    },
  });

  const bankInstitutions = data?.bankInstitutions || null;
  const transactions = data?.transactions || null;

  return { bankInstitutions, transactions };
}
