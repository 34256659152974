export const Form = ({
  onSubmit,
  children,
}: {
  onSubmit: () => void;
  children: React.ReactNode;
}) => {
  return (
    <form
      className="mx-auto w-full max-w-2xl space-y-6 md:space-y-10 lg:space-y-14"
      onSubmit={(event) => {
        event.preventDefault();

        onSubmit();
      }}
    >
      {children}
    </form>
  );
};
