import { Input, InputProps } from '~/components';
import { CopyKey } from '~/copy';
import { useCopy } from '~/copy/hooks';

export const FormInput = <V extends string | number>({
  value,
  label,
  placeholder,
  onChange,
  type,
  min,
  max,
  step,
}: {
  value: V;
  label?: CopyKey;
  placeholder?: CopyKey;
  onChange: (value: V) => void;
  type?: InputProps['type'];
  min?: InputProps['min'];
  max?: InputProps['max'];
  step?: InputProps['step'];
}) => {
  const copy = useCopy();

  return (
    <div className="space-y-4">
      {label && <div>{copy.get(label)}</div>}

      <Input
        value={value}
        placeholder={placeholder && copy.get(placeholder)}
        onChange={(event) => onChange(event.target.value as V)}
        type={type}
        min={min}
        max={max}
        step={step}
      />
    </div>
  );
};
