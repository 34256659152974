import { defineCopy } from '~/copy';

export default {
  meetings: defineCopy({
    default: 'Meetings',
    'de-DE': 'Besprechungen',
    'es-ES': 'Reuniones',
    'it-IT': 'Riunioni',
    'fr-FR': 'Réunions',
  }),
};
