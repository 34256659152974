import {
  AudioTrack,
  LiveKitRoom,
  TrackReference,
  useTrackMutedIndicator,
  useTracks,
  useTrackToggle,
  VideoTrack,
} from '@livekit/components-react';
import { motion } from 'framer-motion';
import {
  Room,
  RoomConnectOptions,
  RoomOptions,
  Track,
  VideoPresets,
} from 'livekit-client';
import { useMemo } from 'react';
import { MicToggle } from '~/audio/components';
import { BasicButton, CameraToggle } from '~/components';
import { useSize } from '~/hooks';
import {
  MeetingSettings,
  MeetingSettingsLink,
  Participants,
  ParticipantsToggle,
} from '~/meeting/components';
import {
  useMeeting,
  useMeetings,
  useMeetingSettings,
  useParticipants,
} from '~/meeting/hooks';
import { useOrg } from '~/org/hooks';
import { cn } from '~/style';

export const MeetingVideo = ({ meetingId }: { meetingId: string }) => {
  const {
    connection,
    codec,
    hq,
    leave,
    videoDeviceId,
    videoEnabled,
    audioDeviceId,
    audioEnabled,
  } = useMeetings();

  const roomOptions = useMemo((): RoomOptions => {
    return {
      videoCaptureDefaults: {
        deviceId: videoDeviceId ?? undefined,
        resolution: hq ? VideoPresets.h2160 : VideoPresets.h720,
      },
      publishDefaults: {
        dtx: false,
        videoSimulcastLayers: hq
          ? [VideoPresets.h1080, VideoPresets.h720]
          : [VideoPresets.h540, VideoPresets.h216],
        red: true,
        videoCodec: codec,
      },
      audioCaptureDefaults: {
        deviceId: audioDeviceId ?? undefined,
      },
      adaptiveStream: { pixelDensity: 'screen' },
      dynacast: true,
    };
  }, [hq, codec, videoDeviceId, audioDeviceId]);

  const room = useMemo(() => new Room(roomOptions), []); // eslint-disable-line

  const connectOptions = useMemo((): RoomConnectOptions => {
    return {
      autoSubscribe: true,
    };
  }, []);

  const handleError = (error: Error) => {
    throw error;
  };

  if (!connection) return;

  return (
    <LiveKitRoom
      connect
      className="h-screen w-screen bg-background"
      data-lk-theme="default"
      room={room}
      token={connection.participantToken}
      serverUrl={connection.serverUrl}
      connectOptions={connectOptions}
      video={videoEnabled}
      audio={audioEnabled}
      onDisconnected={() => {
        leave();
        window.location.href = `/meetings/${meetingId}`;
      }}
      onError={handleError}
    >
      <MeetingVideoConference meetingId={meetingId} room={room} />
    </LiveKitRoom>
  );
};

function MeetingVideoConference({
  meetingId,
  room,
}: {
  meetingId: string;
  room: Room;
}) {
  const { hostVideoTrack } = useMeeting(meetingId);
  const { isVisible: isParticipantsVisible } = useParticipants();
  const { isLg } = useSize();

  const videoTracks = useTracks([
    { source: Track.Source.Camera, withPlaceholder: true },
    { source: Track.Source.ScreenShare, withPlaceholder: false },
  ]);
  const audioTracks = useTracks([
    { source: Track.Source.Microphone, withPlaceholder: true },
  ]);
  // const { enabled: userVideoEnabled } = useTrackToggle({
  //   source: Track.Source.Camera,
  // });

  // const userVideoTrack =
  //   videoTracks.find((track) => {
  //     const metadata = getParticipantMetadata(track);

  //     return metadata?.userId === user.id;
  //   }) || null;

  // const otherVideoTrack =
  //   videoTracks.find((track) => {
  //     const metadata = getParticipantMetadata(track);

  //     return metadata?.orgMember.role !== 'owner';
  //   }) || null;

  if (!videoTracks.length) {
    return (
      <div className="fixed left-0 top-0 flex h-2/3 w-screen items-center justify-center md:h-screen">
        <div className="h-10 w-10">
          <div className="loader" />
        </div>
      </div>
    );
  }

  return (
    <>
      <motion.div
        className="fixed bottom-0 right-0 top-0 z-30 overflow-hidden bg-background"
        initial={{ left: 0 }}
        animate={{ left: !isLg ? 0 : isParticipantsVisible ? 320 : 0 }}
        transition={{
          type: 'spring',
          duration: 0.5,
        }}
      >
        <div className="absolute bottom-0 left-0 right-0 top-0">
          {hostVideoTrack ? (
            <MainVideo
              room={room}
              videoTrack={hostVideoTrack as TrackReference}
              meetingId={meetingId}
            />
          ) : (
            <NoMainVideo room={room} />
          )}

          <MeetingOptions />

          <motion.div
            className="absolute left-0 top-0 h-full w-[1px] bg-muted"
            initial={{ opacity: 0 }}
            animate={{ opacity: !isLg ? 0 : isParticipantsVisible ? 1 : 0 }}
          />
        </div>

        {/* {userVideoTrack && !isOwner() && (
          <motion.div
            className="fixed right-4 top-4 w-40"
            initial={{
              x: '100%',
            }}
            animate={{
              x: userVideoEnabled ? 0 : '120%',
            }}
          >
            {userVideoTrack?.publication?.kind === 'video' && (
              <VideoTrack
                className="scale-x-[-1] rounded-xl drop-shadow-2xl"
                trackRef={userVideoTrack as TrackReference}
              />
            )}
          </motion.div>
        )} */}

        {/* {otherVideoTrack && isOwner() && (
          <OtherVideo videoTrack={otherVideoTrack as TrackReference} />
        )} */}

        <LeaveButton room={room} />

        {audioTracks.map((track, trackIndex) => (
          <AudioTrack key={trackIndex} trackRef={track as TrackReference} />
        ))}
      </motion.div>

      <MeetingSettings meetingId={meetingId} />

      <Participants meetingId={meetingId} />
    </>
  );
}

const MainVideo = ({
  room,
  videoTrack,
  meetingId,
}: {
  room: Room;
  videoTrack: TrackReference;
  meetingId: string;
}) => {
  const { isMuted } = useTrackMutedIndicator(videoTrack);
  const { mirrorVideo } = useMeetingSettings();
  const { isHost } = useMeeting(meetingId);
  const shouldMirror = isHost && mirrorVideo;

  if (isMuted) {
    return <NoMainVideo room={room} videoTrack={videoTrack} />;
  }

  return (
    <VideoTrack
      className={cn('h-full w-full', {
        'scale-x-[-1]': shouldMirror,
      })}
      trackRef={videoTrack}
    />
  );
};

const NoMainVideo = ({
  room,
  videoTrack,
}: {
  room: Room;
  videoTrack?: TrackReference | null | undefined;
}) => {
  const { isOwner } = useOrg();

  if (!videoTrack && !isOwner()) {
    return (
      <div className="fixed left-0 top-0 h-full w-full">
        <div className="mx-auto flex h-2/3 max-w-md items-center justify-center space-y-8 text-center md:h-full md:space-y-14">
          <div className="space-y-8 md:space-y-16">
            <h2 className="text-lg font-semibold md:text-xl lg:text-2xl">
              Waiting for the host to join
            </h2>

            <div className="mx-auto h-10 w-10 md:h-16 md:w-16">
              <div className="loader" />
            </div>
          </div>
        </div>

        <LeaveButton room={room} />
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      <div className="flex h-2/3 items-center justify-center md:h-full">
        <div className="space-y-8 text-center">
          <svg
            className="mx-auto size-32 text-muted-foreground/70 md:size-40 lg:size-48 xl:size-60"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
              clipRule="evenodd"
            />
          </svg>

          {!isOwner() && <div>Host's camera is off</div>}
        </div>
      </div>
    </div>
  );
};

// const OtherVideo = ({ videoTrack }: { videoTrack: TrackReference }) => {
//   const { isMuted } = useTrackMutedIndicator(videoTrack);

//   return (
//     <motion.div
//       className="fixed right-4 top-4 z-20 w-1/2 md:w-72 lg:w-96"
//       initial={{
//         x: '100%',
//       }}
//       animate={{
//         x: 0,
//       }}
//     >
//       {videoTrack?.publication?.kind === 'video' && (
//         <div className="relative overflow-hidden rounded-xl bg-muted drop-shadow-2xl">
//           <VideoTrack
//             className={cn({ 'opacity-0': isMuted })}
//             trackRef={videoTrack}
//           />

//           {isMuted && (
//             <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
//               <svg
//                 className="mx-auto size-14 text-muted-foreground/70 md:size-20 lg:size-28"
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 24 24"
//                 fill="currentColor"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
//                   clipRule="evenodd"
//                 />
//               </svg>
//             </div>
//           )}
//         </div>
//       )}
//     </motion.div>
//   );
// };

const MeetingCameraToggle = () => {
  const { buttonProps, enabled } = useTrackToggle({
    source: Track.Source.Camera,
  });

  return <CameraToggle enabled={enabled} onClick={buttonProps.onClick} />;
};

const MeetingAudioToggle = () => {
  const { buttonProps, enabled } = useTrackToggle({
    source: Track.Source.Microphone,
  });

  return <MicToggle enabled={enabled} onClick={buttonProps.onClick} />;
};

const LeaveButton = ({ room }: { room: Room }) => {
  return (
    <BasicButton
      className="fixed bottom-4 right-4 h-12 w-12 md:h-16 md:w-16"
      variant="destructive"
      size="md"
      onClick={() => room.disconnect()}
    >
      <div>
        <svg
          className="size-5 md:size-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>
    </BasicButton>
  );
};

export const MeetingOptions = () => {
  return (
    <div className="absolute bottom-4 left-4 flex gap-4 md:gap-6">
      <MeetingSettingsLink />

      <MeetingCameraToggle />

      <MeetingAudioToggle />

      <ParticipantsToggle />
    </div>
  );
};
