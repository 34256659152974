import { defineCopy } from '~/copy';

export default {
  boards: defineCopy({
    default: 'Boards',
    'de-DE': 'Bretter',
    'es-ES': 'Tableros',
    'it-IT': 'Bacheche',
    'fr-FR': 'Tableaux',
  }),
  board: defineCopy({
    default: 'Board',
    'de-DE': 'Brett',
    'es-ES': 'Tablero',
    'it-IT': 'Bacheca',
    'fr-FR': 'Tableau',
  }),
  createBoard: defineCopy({
    default: 'Create board',
    'de-DE': 'Brett erstellen',
    'es-ES': 'Crear tablero',
    'it-IT': 'Crea bacheca',
    'fr-FR': 'Créer un tableau',
  }),
  newBoard: defineCopy({
    default: 'New board',
    'de-DE': 'Neues Brett',
    'es-ES': 'Nuevo tablero',
    'it-IT': 'Nuova bacheca',
    'fr-FR': 'Nouveau tableau',
  }),
  boardName: defineCopy({
    default: 'Board name',
    'de-DE': 'Brettname',
    'es-ES': 'Nombre del tablero',
    'it-IT': 'Nome della bacheca',
    'fr-FR': 'Nom du tableau',
  }),
  addBoard: defineCopy({
    default: 'Add board',
    'de-DE': 'Brett hinzufügen',
    'es-ES': 'Agregar tablero',
    'it-IT': 'Aggiungi bacheca',
    'fr-FR': 'Ajouter un tableau',
  }),
  noBoards: defineCopy({
    default: 'No boards have been added',
    'de-DE': 'Keine Bretter wurden hinzugefügt',
    'es-ES': 'No se han agregado tableros',
    'it-IT': 'Non sono state aggiunte bacheche',
    'fr-FR': "Aucun tableau n'a été ajouté",
  }),
  editBoard: defineCopy({
    default: 'Edit board',
    'de-DE': 'Brett bearbeiten',
    'es-ES': 'Editar tablero',
    'it-IT': 'Modifica bacheca',
    'fr-FR': 'Modifier le tableau',
  }),
  deleteBoard: defineCopy({
    default: 'Deleting a board is permanent and cannot be undone.',
    'de-DE':
      'Das Löschen eines Bretts ist endgültig und kann nicht rückgängig gemacht werden.',
    'es-ES': 'Eliminar un tablero es permanente y no se puede deshacer.',
    'it-IT': 'Eliminare una bacheca è permanente e non può essere annullato.',
    'fr-FR':
      "La suppression d'un tableau est permanente et ne peut pas être annulée.",
  }),
  shareBoard: defineCopy({
    default: 'Share board',
    'de-DE': 'Brett teilen',
    'es-ES': 'Compartir tablero',
    'it-IT': 'Condividi bacheca',
    'fr-FR': 'Partager le tableau',
  }),
  stages: defineCopy({
    default: 'Stages',
    'de-DE': 'Stufen',
    'es-ES': 'Etapas',
    'it-IT': 'Fasi',
    'fr-FR': 'Étapes',
  }),
  addStage: defineCopy({
    default: 'Add stage',
    'de-DE': 'Stufe hinzufügen',
    'es-ES': 'Agregar etapa',
    'it-IT': 'Aggiungi fase',
    'fr-FR': 'Ajouter une étape',
  }),
};
