import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { analytics } from '~/analytics';
import { acceptMemberInvite, getMemberInvite } from '~/api';
import { Button, Link } from '~/components';
import { useCopy } from '~/copy/hooks';
import { useParams } from '~/hooks';
import { setOrgCookie } from '~/org';
import { sentry } from '~/sentry';
import { MemberInviteCode, Org } from '~/types';

let loaded = false;

export const MemberInvite = () => {
  const { code } = useParams<{ code: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [invite, setInvite] = useState<MemberInviteCode | null>(null);
  const [org, setOrg] = useState<Org | null>(null);
  const copy = useCopy();

  const accept = useMutation({
    mutationFn: async () => {
      const { org } = await acceptMemberInvite({ code });

      if (!org) {
        setInvite(null);
        setOrg(null);

        return;
      }

      setOrgCookie(org.id);

      analytics.track('member.invite.accept');

      window.location.href = '/';
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  useEffect(() => {
    if (loaded) return;

    loaded = true;

    const fn = async () => {
      const { invite, org } = await getMemberInvite({ code });

      if (invite) {
        setInvite(invite);
      }

      if (org) {
        setOrg(org);
      }

      setIsLoading(false);
    };

    fn();
  }, [code]);

  if (isLoading) {
    return (
      <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
        <div className="h-10 w-10">
          <div className="loader" />
        </div>
      </div>
    );
  }

  if (!invite || !org) {
    return (
      <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
        <div className="space-y-4 text-center">
          <h1 className="text-lg font-bold md:text-2xl">
            {copy.get('invalidCode')}
          </h1>

          <p className="text-muted-foreground">{copy.get('thisCodeInvalid')}</p>

          <div className="pt-8">
            <Link href="/">
              <Button size="sm" label="done" />
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="py-10 md:py-20 lg:py-32">
      <div
        className="mx-auto max-w-xl rounded-xl border p-6 text-center md:p-10 lg:p-14"
        key={invite.id}
      >
        <div className="space-y-4">
          <div className="text-balance text-xl font-semibold md:text-2xl lg:text-3xl">
            {org.name}
          </div>

          <p className="text-balance text-lg text-muted-foreground">
            {copy.get('youveBeenInvited')}
          </p>
        </div>

        <Button
          className="mt-16 w-full md:mt-20"
          type="submit"
          size="md"
          disabled={accept.isPending}
          onClick={() => accept.mutate()}
          isLoading={accept.isPending}
          label="acceptInvite"
        />
      </div>
    </div>
  );
};
