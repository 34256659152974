import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { planCheckout } from '~/api';
import { defaultDashboardPath } from '~/auth';
import { getOrgId } from '~/org';

let hasLoaded = false;

export const Checkout = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    const fn = async () => {
      if (hasLoaded) return;

      hasLoaded = true;

      if (!sessionId) {
        window.location.href = '/plans';
        return;
      }

      try {
        const orgId = getOrgId();

        await planCheckout({
          orgId,
          sessionId,
        });
      } catch (error) {
        // ignore
      }

      window.location.href = defaultDashboardPath;
    };

    fn();
  }, [sessionId]);

  return (
    <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
      <div className="h-10 w-10">
        <div className="loader" />
      </div>
    </div>
  );
};
