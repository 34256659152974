import { z } from 'zod';

/////////////////////////////////////////
// BOARD ITEM SCHEMA
/////////////////////////////////////////

export const BoardItemSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  boardId: z.string(),
  boardStageId: z.string(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  position: z.number().int(),
})

export type BoardItem = z.infer<typeof BoardItemSchema>

export default BoardItemSchema;
