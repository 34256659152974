import { defineCopy } from '~/copy';

export default {
  service: defineCopy({
    default: 'Service',
    'de-DE': 'Dienst',
    'es-ES': 'Servicio',
    'it-IT': 'Servizio',
    'fr-FR': 'Service',
  }),
  services: defineCopy({
    default: 'Services',
    'de-DE': 'Dienste',
    'es-ES': 'Servicios',
    'it-IT': 'Servizi',
    'fr-FR': 'Services',
  }),
  newService: defineCopy({
    default: 'New service',
    'de-DE': 'Neuer Dienst',
    'es-ES': 'Nuevo servicio',
    'it-IT': 'Nuovo servizio',
    'fr-FR': 'Nouveau service',
  }),
  serviceName: defineCopy({
    default: 'Service name',
    'de-DE': 'Dienstname',
    'es-ES': 'Nombre del servicio',
    'it-IT': 'Nome del servizio',
    'fr-FR': 'Nom du service',
  }),
  addService: defineCopy({
    default: 'Add service',
    'de-DE': 'Dienst hinzufügen',
    'es-ES': 'Agregar servicio',
    'it-IT': 'Aggiungi servizio',
    'fr-FR': 'Ajouter un service',
  }),
  noService: defineCopy({
    default: 'No service have been added',
    'de-DE': 'Es wurde kein Dienst hinzugefügt',
    'es-ES': 'No se ha agregado ningún servicio',
    'it-IT': 'Non è stato aggiunto alcun servizio',
    'fr-FR': "Aucun service n'a été ajouté",
  }),
  editService: defineCopy({
    default: 'Edit service',
    'de-DE': 'Dienst bearbeiten',
    'es-ES': 'Editar servicio',
    'it-IT': 'Modifica servizio',
    'fr-FR': 'Modifier le service',
  }),
  deleteService: defineCopy({
    default: 'Deleting a service is permanent and cannot be undone.',
    'de-DE':
      'Das Löschen eines Dienstes ist endgültig und kann nicht rückgängig gemacht werden.',
    'es-ES': 'Eliminar un servicio es permanente y no se puede deshacer.',
    'it-IT':
      "L'eliminazione di un servizio è definitiva e non può essere annullata.",
    'fr-FR':
      "La suppression d'un service est définitive et ne peut pas être annulée.",
  }),
  shareService: defineCopy({
    default: 'Share service',
    'de-DE': 'Dienst teilen',
    'es-ES': 'Compartir servicio',
    'it-IT': 'Condividi servizio',
    'fr-FR': 'Partager le service',
  }),
  noServices: defineCopy({
    default: 'No services have been added',
    'de-DE': 'Es wurden keine Dienste hinzugefügt',
    'es-ES': 'No se han agregado servicios',
    'it-IT': 'Non sono stati aggiunti servizi',
    'fr-FR': "Aucun service n'a été ajouté",
  }),
};
