import { defineCopy } from '~/copy';

export default {
  contents: defineCopy({
    default: 'Content',
    'de-DE': 'Inhalt',
    'es-ES': 'Contenido',
    'it-IT': 'Contenuti',
    'fr-FR': 'Contenus',
  }),
  content: defineCopy({
    default: 'Content',
    'de-DE': 'Inhalt',
    'es-ES': 'Contenido',
    'it-IT': 'Contenuto',
    'fr-FR': 'Contenu',
  }),
  addContent: defineCopy({
    default: 'Add content',
    'de-DE': 'Inhalt hinzufügen',
    'es-ES': 'Agregar contenido',
    'it-IT': 'Aggiungi contenuto',
    'fr-FR': 'Ajouter du contenu',
  }),
  noContent: defineCopy({
    default: 'No content has been added yet',
    'de-DE': 'Es wurde noch kein Inhalt hinzugefügt',
    'es-ES': 'Aún no se ha agregado contenido',
    'it-IT': 'Non è stato ancora aggiunto alcun contenuto',
    'fr-FR': "Aucun contenu n'a encore été ajouté",
  }),
  viewArchived: defineCopy({
    default: 'View archive',
    'de-DE': 'Archiv anzeigen',
    'es-ES': 'Ver archivo',
    'it-IT': 'Visualizza archivio',
    'fr-FR': 'Voir les archives',
  }),
  noContentArchived: defineCopy({
    default: 'No content has been archived yet',
    'de-DE': 'Es wurde noch kein Inhalt archiviert',
    'es-ES': 'Aún no se ha archivado contenido',
    'it-IT': 'Non è stato ancora archiviato alcun contenuto',
    'fr-FR': "Aucun contenu n'a encore été archivé",
  }),
  sendNotification: defineCopy({
    default: 'Send notification',
    'de-DE': 'Benachrichtigung senden',
    'es-ES': 'Enviar notificación',
    'it-IT': 'Invia notifica',
    'fr-FR': 'Envoyer une notification',
  }),
  description: defineCopy({
    default: 'Description',
    'de-DE': 'Beschreibung',
    'es-ES': 'Descripción',
    'it-IT': 'Descrizione',
    'fr-FR': 'Description',
  }),
  summary: defineCopy({
    default: 'Summary',
    'de-DE': 'Zusammenfassung',
    'es-ES': 'Resumen',
    'it-IT': 'Sommario',
    'fr-FR': 'Résumé',
  }),
  viewNotifications: defineCopy({
    default: 'View notifications',
    'de-DE': 'Benachrichtigungen anzeigen',
    'es-ES': 'Ver notificaciones',
    'it-IT': 'Visualizza notifiche',
    'fr-FR': 'Voir les notifications',
  }),
  actionUnarchiveContent: defineCopy({
    default: 'This action will unarchive the content.',
    'de-DE': 'Diese Aktion wird den Inhalt wiederherstellen.',
    'es-ES': 'Esta acción desarchivará el contenido.',
    'it-IT': 'Questa azione ripristinerà il contenuto.',
    'fr-FR': 'Cette action désarchivera le contenu.',
  }),
  actionArchiveContent: defineCopy({
    default: 'This action will archive the content.',
    'de-DE': 'Diese Aktion wird den Inhalt archivieren.',
    'es-ES': 'Esta acción archivará el contenido.',
    'it-IT': 'Questa azione archivierà il contenuto.',
    'fr-FR': 'Cette action archivera le contenu.',
  }),
  actionDeleteContent: defineCopy({
    default:
      'This action will permanently delete the content. This cannot be undone.',
    'de-DE':
      'Diese Aktion wird den Inhalt dauerhaft löschen. Dies kann nicht rückgängig gemacht werden.',
    'es-ES':
      'Esta acción eliminará permanentemente el contenido. Esto no se puede deshacer.',
    'it-IT':
      'Questa azione eliminerà definitivamente il contenuto. Questo non può essere annullato.',
    'fr-FR':
      'Cette action supprimera définitivement le contenu. Cela ne peut pas être annulé.',
  }),
  deleteContent: defineCopy({
    default: 'Delete content',
    'de-DE': 'Inhalt löschen',
    'es-ES': 'Eliminar contenido',
    'it-IT': 'Elimina contenuto',
    'fr-FR': 'Supprimer le contenu',
  }),
  editContent: defineCopy({
    default: 'Edit content',
    'de-DE': 'Inhalt bearbeiten',
    'es-ES': 'Editar contenido',
    'it-IT': 'Modifica contenuto',
    'fr-FR': 'Modifier le contenu',
  }),
  contentTitle: defineCopy({
    default: 'Content title',
    'de-DE': 'Inhaltstitel',
    'es-ES': 'Título del contenido',
    'it-IT': 'Titolo del contenuto',
    'fr-FR': 'Titre du contenu',
  }),
  text: defineCopy({
    default: 'Text',
    'de-DE': 'Text',
    'es-ES': 'Texto',
    'it-IT': 'Testo',
    'fr-FR': 'Texte',
  }),
  sendNotificationToMembers: defineCopy({
    default: 'Send content notification to members',
    'de-DE': 'Benachrichtigung über den Inhalt an Mitglieder senden',
    'es-ES': 'Enviar notificación de contenido a los miembros',
    'it-IT': 'Invia notifica contenuto ai membri',
    'fr-FR': 'Envoyer une notification de contenu aux membres',
  }),
  thisWillSendNotification: defineCopy({
    default:
      'This will send a notification to all members of this org.<br/>Are you sure you want to proceed?',
    'de-DE':
      'Dies sendet eine Benachrichtigung an alle Mitglieder dieser Gruppe.<br/>Möchten Sie wirklich fortfahren?',
    'es-ES':
      'Esto enviará una notificación a todos los miembros de este grupo.<br/>¿Estás seguro de que quieres continuar?',
    'it-IT':
      'Questo invierà una notifica a tutti i membri di questo gruppo.<br/>Sei sicuro di voler procedere?',
    'fr-FR':
      'Cela enverra une notification à tous les membres de ce orge.<br/>Êtes-vous sûr de vouloir continuer?',
  }),
  sendNotificationToXMembers: defineCopy({
    default: 'Send notification to {membersTotal} member{s}',
    'de-DE': 'Benachrichtigung an {membersTotal} Mitglied{e} senden',
    'es-ES': 'Enviar notificación a {membersTotal} miembro{s}',
    'it-IT': 'Invia notifica a {membersTotal} membro{i}',
    'fr-FR': 'Envoyer une notification à {membersTotal} membre{s}',
  }),
  autoGenerateText: defineCopy({
    default: 'Auto-generate text',
    'de-DE': 'Text automatisch generieren',
    'es-ES': 'Generar texto automáticamente',
    'it-IT': 'Genera testo automaticamente',
    'fr-FR': 'Générer automatiquement du texte',
  }),
  autoGenerateInstructions: defineCopy({
    default: 'Auto-generate instructions',
    'de-DE': 'Anweisungen zur automatischen Generierung',
    'es-ES': 'Instrucciones de autogeneración',
    'it-IT': 'Istruzioni di generazione automatica',
    'fr-FR': 'Instructions de génération automatique',
  }),
  autoGenerateGuide: defineCopy({
    default: 'Provide instructions below for generating the text',
    'de-DE': 'Geben Sie unten Anweisungen zur Generierung des Textes an',
    'es-ES': 'Proporcione instrucciones a continuación para generar el texto',
    'it-IT': 'Fornire istruzioni di seguito per generare il testo',
    'fr-FR': 'Fournir des instructions ci-dessous pour générer le texte',
  }),
  autoGenerateAudio: defineCopy({
    default: 'Auto-generate audio',
    'de-DE': 'Audio automatisch generieren',
    'es-ES': 'Generar audio automáticamente',
    'it-IT': 'Genera audio automaticamente',
    'fr-FR': "Générer automatiquement de l'audio",
  }),
  autoGenerateAudioDescription: defineCopy({
    default: 'Automatically generate audio for this content',
    'de-DE': 'Audio für diesen Inhalt automatisch generieren',
    'es-ES': 'Generar audio automáticamente para este contenido',
    'it-IT': "Genera automaticamente l'audio per questo contenuto",
    'fr-FR': "Générer automatiquement de l'audio pour ce contenu",
  }),
  instructions: defineCopy({
    default: 'Instructions',
    'de-DE': 'Anweisungen',
    'es-ES': 'Instrucciones',
    'it-IT': 'Istruzioni',
    'fr-FR': 'Instructions',
  }),
  contentText: defineCopy({
    default: 'Content text',
    'de-DE': 'Inhaltstext',
    'es-ES': 'Texto del contenido',
    'it-IT': 'Testo del contenuto',
    'fr-FR': 'Texte du contenu',
  }),
  shareContent: defineCopy({
    default: 'Share content',
    'de-DE': 'Inhalt teilen',
    'es-ES': 'Compartir contenido',
    'it-IT': 'Condividi contenuto',
    'fr-FR': 'Partager le contenu',
  }),
  yourContent: defineCopy({
    default: 'Your content',
    'de-DE': 'Dein Inhalt',
    'es-ES': 'Tu contenido',
    'it-IT': 'Il tuo contenuto',
    'fr-FR': 'Votre contenu',
  }),
};
