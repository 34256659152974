import { AnimatePresence, motion } from 'framer-motion';
import { Badge } from '~/components';
import { useParticipants } from '~/meeting/hooks';
import { cn } from '~/style';
import { numberWithCommas } from '~/utils';

export const ParticipantsToggle = () => {
  const { toggle, videoTracks } = useParticipants();
  const videoTracksLength = videoTracks.length;

  return (
    <div className="relative">
      <motion.div
        className="flex h-12 w-12 cursor-pointer select-none items-center justify-center gap-2 rounded-full bg-muted md:h-16 md:w-16"
        whileHover={{
          scale: 1.05,
        }}
        whileTap={{
          scale: 0.95,
        }}
        onClick={toggle}
      >
        <svg
          className="size-5 md:size-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
          />
        </svg>
      </motion.div>

      <AnimatePresence>
        {videoTracksLength > 1 && videoTracksLength < 100 && (
          <motion.div
            className={cn('absolute -right-2 -top-1 md:top-0', {
              '-right-3 md:-right-3': videoTracksLength > 9,
            })}
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ type: 'spring' }}
          >
            <Badge className="scale-75 md:scale-100">
              {numberWithCommas(videoTracksLength)}
            </Badge>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
