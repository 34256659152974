import { AnimatePresence, motion } from 'framer-motion';
import { BasicButton } from '~/components';
import { useCopy } from '~/copy/hooks';
import { useRemoteFile } from '~/file/hooks';
import { useKeyboard } from '~/hooks';
import { RemoteFile } from '~/types';

export const FileViewer = () => {
  const { isVisible, hide, file } = useRemoteFile();

  useKeyboard({
    onKeyDown: ({ key }) => {
      if (key === 'Escape') {
        hide();
      }
    },
  });

  if (!file) return;

  return (
    <AnimatePresence>
      {isVisible && (
        <div className="fixed left-0 top-0 z-30">
          <motion.div
            className="fixed bottom-0 left-0 right-0 top-0 bg-background/80"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={hide}
          />

          <div className="fixed bottom-0 top-0 w-full max-w-full md:left-1/2 md:top-1/2 md:h-full md:w-full md:-translate-x-1/2 md:-translate-y-1/2">
            <div className="h-full p-5 md:p-8">
              <motion.div
                className="hide-scrollbar h-full w-full overflow-auto rounded-xl bg-background drop-shadow-2xl"
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
              >
                <div className="h-full w-full bg-muted/80">
                  <motion.div
                    className="flex h-full flex-col"
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: {
                        delay: 0.2,
                      },
                    }}
                    exit={{ opacity: 0 }}
                  >
                    <Header file={file} />

                    <Content />

                    <Footer />
                  </motion.div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

const Header = ({ file }: { file: RemoteFile }) => {
  return (
    <div className="flex items-center border-b border-white/5 px-6 py-1 md:py-3 lg:py-6">
      <div className="md:text-lg">{file.name}</div>

      <CloseButton />
    </div>
  );
};

const Content = () => {
  const { fileUrlIsLoading, fileUrl, fileUrlError, hasPreview } =
    useRemoteFile();
  const copy = useCopy();

  if (fileUrlIsLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <div className="h-10 w-10">
          <div className="loader" />
        </div>
      </div>
    );
  }

  if (fileUrlError) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <div className="text-muted-foreground">{fileUrlError}</div>
      </div>
    );
  }

  if (!fileUrl) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <div className="text-muted-foreground">No file URL</div>
      </div>
    );
  }

  if (!hasPreview) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <a href={fileUrl} target="_blank" rel="noreferrer noopener">
          <BasicButton>
            {copy.get('download')}

            <svg
              className="-mr-4 ml-7 size-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </BasicButton>
        </a>
      </div>
    );
  }

  return (
    <div className="hide-scrollbar relative flex-1 overflow-auto p-6">
      <iframe
        className="h-full w-full"
        src={fileUrl}
        allowFullScreen
        style={{ border: 'none' }}
      />
    </div>
  );
};

const Footer = () => {
  return (
    <div className="flex items-center border-t border-white/5 px-6 py-3 md:py-4 lg:py-6">
      <div className="ml-auto"></div>
    </div>
  );
};

const CloseButton = () => {
  const { hide } = useRemoteFile();

  return (
    <motion.button
      className="-mr-4 ml-auto cursor-pointer p-3 text-muted-foreground md:-mr-3 lg:-mr-2"
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.7 }}
      exit={{ opacity: 0 }}
      whileHover={{ scale: 1.1, opacity: 1 }}
      whileTap={{ scale: 0.95 }}
      onClick={hide}
      tabIndex={3}
    >
      <svg
        className="size-7"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    </motion.button>
  );
};
