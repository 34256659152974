import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { getFileLink } from '~/api';
import { hasFilePreview } from '~/file';
import { RemoteFile } from '~/types';

const initialState = {
  isVisible: false,
  file: null as RemoteFile | null,
  fileUrl: null as string | null,
  fileUrlIsLoading: false,
  fileUrlError: null as string | null,
  hasPreview: false,
};

export const useRemoteFileStore = create(
  combine(initialState, (set) => ({
    selectFile: async (file: RemoteFile) => {
      if (!file.path) return;

      const hasPreview = hasFilePreview(file);

      set({
        file,
        isVisible: true,
        fileUrlError: null,
        fileUrl: null,
        fileUrlIsLoading: true,
        hasPreview,
      });

      try {
        const { url } = await getFileLink({ path: file.path });

        set({
          fileUrl: url,
          fileUrlIsLoading: false,
        });
      } catch (error) {
        set({
          fileUrlIsLoading: false,
          fileUrlError: 'Unable to load preview',
        });
      }
    },

    hide: () => {
      set({ isVisible: false });
    },
  })),
);

export function useRemoteFile() {
  const store = useRemoteFileStore();

  return { ...store };
}
