import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { getDropboxAuthUrl } from '~/api';
import { Button } from '~/components';
import { sentry } from '~/sentry';

export const ConnectDropboxButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  const link = useMutation({
    mutationFn: async () => {
      setIsLoading(true);

      const { url } = await getDropboxAuthUrl({});

      return url;
    },
    onSuccess: async (url) => {
      window.location.href = url;
    },
    onError: (error) => {
      sentry.captureError(error);
      setIsLoading(false);
    },
  });

  return (
    <Button
      className="w-52"
      label="connectDropbox"
      isLoading={isLoading}
      onClick={() => link.mutate()}
    />
  );
};
