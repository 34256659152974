import { defineCopy } from '~/copy';

export default {
  messages: defineCopy({
    default: 'Messages',
    'de-DE': 'Nachrichten',
    'es-ES': 'Mensajes',
    'it-IT': 'Messaggi',
    'fr-FR': 'Messages',
  }),
  message: defineCopy({
    default: 'Message',
    'de-DE': 'Nachricht',
    'es-ES': 'Mensaje',
    'it-IT': 'Messaggio',
    'fr-FR': 'Message',
  }),
  addMessage: defineCopy({
    default: 'Add message',
    'de-DE': 'Nachricht hinzufügen',
    'es-ES': 'Agregar mensaje',
    'it-IT': 'Aggiungi messaggio',
    'fr-FR': 'Ajouter un message',
  }),
  noMessagesAdded: defineCopy({
    default: 'No messages added',
    'de-DE': 'Keine Nachrichten hinzugefügt',
    'es-ES': 'No se han agregado mensajes',
    'it-IT': 'Nessun messaggio aggiunto',
    'fr-FR': 'Aucun message ajouté',
  }),
  editMessage: defineCopy({
    default: 'Edit message',
    'de-DE': 'Nachricht bearbeiten',
    'es-ES': 'Editar mensaje',
    'it-IT': 'Modifica messaggio',
    'fr-FR': 'Modifier le message',
  }),
  whatIsMessage: defineCopy({
    default: 'What is the message...',
    'de-DE': 'Was ist die Nachricht...',
    'es-ES': '¿Cuál es el mensaje...',
    'it-IT': 'Qual è il messaggio...',
    'fr-FR': 'Quel est le message...',
  }),
  deleteMessagePermanently: defineCopy({
    default: 'Delete message permanently',
    'de-DE': 'Nachricht dauerhaft löschen',
    'es-ES': 'Eliminar mensaje permanentemente',
    'it-IT': 'Elimina messaggio definitivamente',
    'fr-FR': 'Supprimer le message définitivement',
  }),
  deleteMessage: defineCopy({
    default: 'Delete message',
    'de-DE': 'Nachricht löschen',
    'es-ES': 'Eliminar mensaje',
    'it-IT': 'Elimina messaggio',
    'fr-FR': 'Supprimer le message',
  }),
};
