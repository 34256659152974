import { defineCopy } from '~/copy';

export default {
  signIn: defineCopy({
    default: 'Sign in',
    'de-DE': 'Anmelden',
    'es-ES': 'Iniciar sesión',
    'it-IT': 'Accedi',
    'fr-FR': 'Se connecter',
  }),
  signUp: defineCopy({
    default: 'Sign up',
    'de-DE': 'Registrieren',
    'es-ES': 'Regístrate',
    'it-IT': 'Iscriviti',
    'fr-FR': "S'inscrire",
  }),
  signOut: defineCopy({
    default: 'Sign out',
    'de-DE': 'Abmelden',
    'es-ES': 'Cerrar sesión',
    'it-IT': 'Esci',
    'fr-FR': 'Se déconnecter',
  }),
  continueWithGoogle: defineCopy({
    default: 'Continue with Google',
    'de-DE': 'Mit Google fortfahren',
    'es-ES': 'Continuar con Google',
    'it-IT': 'Continua con Google',
    'fr-FR': 'Continuer avec Google',
  }),
  password: defineCopy({
    default: 'Password',
    'de-DE': 'Passwort',
    'es-ES': 'Contraseña',
    'it-IT': 'Password',
    'fr-FR': 'Mot de passe',
  }),
  dontHaveAnAccount: defineCopy({
    default: "Don't have an account?",
    'de-DE': 'Sie haben noch kein Konto?',
    'es-ES': '¿No tienes una cuenta?',
    'it-IT': 'Non hai un account?',
    'fr-FR': "Vous n'avez pas de compte ?",
  }),
  alreadyHaveAnAccount: defineCopy({
    default: 'Already have an account?',
    'de-DE': 'Sie haben bereits ein Konto?',
    'es-ES': '¿Ya tienes una cuenta?',
    'it-IT': 'Hai già un account?',
    'fr-FR': 'Vous avez déjà un compte ?',
  }),
};
