import { RemoteFile } from '~/types';

const previewType = ['txt', 'wav', 'jpg', 'png', 'jpeg', 'gif', 'mp4'];

export const hasFilePreview = (file: RemoteFile) => {
  let hasPreview = false;

  previewType.forEach((type) => {
    if (file.name.includes(type)) {
      hasPreview = true;
    }
  });

  return hasPreview;
};
