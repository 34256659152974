import { useMutation, useQuery } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  deleteIncidentMessage,
  generateIncidentSummary,
  getIncidentMessage,
  updateIncidentMessage,
} from '~/api';
import { useUser } from '~/auth/hooks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  BasicButton,
  OriginalButton,
  Separator,
  Textarea,
} from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { useParams } from '~/hooks';
import { invalidateQueries } from '~/query';
import { sentry } from '~/sentry';
import { IncidentMessageExpanded } from '~/types';

export const EditIncidentMessagePage = () => {
  const { incidentMessageId } = useParams<{
    incidentId: string;
    incidentMessageId: string;
  }>();
  const { data: message } = useQuery({
    queryKey: ['incidents', 'messages', incidentMessageId],
    queryFn: async () => {
      const { incidentMessage } = await getIncidentMessage({
        incidentMessageId,
      });
      return incidentMessage;
    },
  });

  if (!message) return null;

  return <EditIncidentMessage message={message} />;
};

export const EditIncidentMessage = ({
  message,
}: {
  message: IncidentMessageExpanded;
}) => {
  const [text, setText] = useState(message.text);
  const { user } = useUser();
  const navigate = useNavigate();
  const copy = useCopy();

  const updateMutation = useMutation({
    mutationFn: async ({ text }: { text: string }) => {
      await updateIncidentMessage({
        incidentMessageId: message.id,
        updateData: {
          text,
        },
      });

      await generateIncidentSummary({
        orgId: message.orgId,
        incidentId: message.incidentId,
      });
    },
    onSuccess: async () => {
      invalidateQueries(['incidents']);
      navigate(`/incidents/${message.incidentId}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  const remove = useMutation({
    mutationFn: async () => {
      await deleteIncidentMessage({
        incidentMessageId: message.id,
      });

      await generateIncidentSummary({
        orgId: message.orgId,
        incidentId: message.incidentId,
      });
    },
    onSuccess: async () => {
      navigate(`/incidents/${message.incidentId}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  const isCurrentUser = user.id === message.userId;

  if (isCurrentUser) {
    return (
      <DashboardLayout headerTitle={copy.get('editMessage')}>
        <div className="mx-auto w-full max-w-2xl space-y-10 md:space-y-14">
          <form
            className="space-y-6 md:space-y-10"
            onSubmit={(event) => {
              event.preventDefault();

              if (!text) return;

              updateMutation.mutate({
                text,
              });
            }}
          >
            <div className="rounded-lg border">
              <div className="bg-muted/50 px-6 py-4">
                {copy.get('whatIsMessage')}
              </div>

              <Separator />

              <div className="p-6">
                <Textarea
                  value={text}
                  onChange={(event) => setText(event.target.value)}
                  rows={10}
                />
              </div>
            </div>

            <BasicButton
              className="w-full"
              type="submit"
              disabled={updateMutation.isPending || !text}
            >
              {updateMutation.isPending ? (
                <Loader2 className="h-6 w-6 animate-spin" />
              ) : (
                copy.get('save')
              )}
            </BasicButton>
          </form>

          <div className="text-center">
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <BasicButton
                  variant="outline"
                  size="sm"
                  disabled={remove.isPending}
                >
                  {remove.isPending ? (
                    <Loader2 className="h-6 w-6 animate-spin" />
                  ) : (
                    copy.get('delete')
                  )}
                </BasicButton>
              </AlertDialogTrigger>

              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>{copy.get('areYouSure')}</AlertDialogTitle>

                  <AlertDialogDescription className="pb-6">
                    {copy.get('deleteMessagePermanently')}
                  </AlertDialogDescription>
                </AlertDialogHeader>

                <AlertDialogFooter>
                  <AlertDialogCancel>{copy.get('cancel')}</AlertDialogCancel>

                  <AlertDialogAction asChild>
                    <OriginalButton onClick={() => remove.mutate()}>
                      {copy.get('deleteMessage')}
                    </OriginalButton>
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>

          <input
            id="hidden"
            className="pointer-events-none fixed left-full top-full -z-50 h-0 w-0 opacity-0"
            type="text"
          />
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout headerTitle={copy.get('update')}>
      <div className="mx-auto w-full max-w-2xl flex-col gap-6 rounded-lg border p-6 md:p-8 lg:p-12">
        {message.text}
      </div>
    </DashboardLayout>
  );
};
