import { Currency } from './types';

export const currencies: Currency[] = [
  {
    id: 'gbp',
    name: 'British Pound',
    shortName: 'GBP',
    symbol: '£',
  },
  {
    id: 'usd',
    name: 'US Dollar',
    shortName: 'USD',
    symbol: '$',
  },
];

export const defaultCurrency = currencies[0];

export const getCurrency = (currencyId: string) => {
  return (
    currencies.find((currency) => currency.id === currencyId) || defaultCurrency
  );
};

export const getCurrencyByShortName = (shortName: string) => {
  return (
    currencies.find((currency) => currency.shortName === shortName) ||
    defaultCurrency
  );
};
