import { useEffect } from 'react';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { getIncident } from '~/api';
import { Incident } from '~/types';

const initialState = {
  incident: null as any as Incident,
};

export const incidentStore = create(
  combine(initialState, (set) => ({
    setIncident: (incident: Incident) => {
      set(() => ({ incident }));
    },
  })),
);

export function useIncident(incidentId?: string) {
  const { incident, setIncident } = incidentStore();

  useEffect(() => {
    const fn = async () => {
      if (!incidentId) return;

      const { incident } = await getIncident({ incidentId });

      if (!incident) return;

      setIncident(incident);
    };

    fn();
  }, [incidentId]); // eslint-disable-line react-hooks/exhaustive-deps

  return incident;
}
