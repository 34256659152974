import { AnimatePresence, motion } from 'framer-motion';
import { useAsk } from '~/ask/hooks';
import { Input } from '~/components';
import { useCopy } from '~/copy/hooks';

export const Query = () => {
  const { ask, query, setQuery, isLoading } = useAsk();
  const copy = useCopy();

  return (
    <form
      className="mx-auto max-w-2xl space-y-6"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="relative">
        <Input
          id="ask-query"
          className="rounded-full pr-16 focus-visible:ring-primary/20 md:py-9 md:pl-6 md:pr-20"
          value={query}
          placeholder={copy.get('howCanIHelp')}
          onChange={(e) => setQuery(e.target.value)}
        />

        <motion.button
          className="absolute right-1.5 top-1.5 h-11 w-11 rounded-full bg-muted md:right-2.5 md:top-2 md:h-14 md:w-14"
          onClick={() => {
            if (!query) {
              document.getElementById('ask-query')?.focus();
              return;
            }

            ask();
          }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <AnimatePresence>
            {!isLoading && (
              <div className="absolute left-0 top-0 h-full w-full overflow-hidden rounded-full">
                <motion.div
                  className="flex h-full w-full items-center justify-center overflow-hidden"
                  initial={{ x: -50, scale: 1, opacity: 1 }}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    x: 0,
                    transition: { type: 'spring' },
                  }}
                  exit={{ scale: 0, x: 50, opacity: 0 }}
                >
                  <svg
                    className="size-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                    />
                  </svg>
                </motion.div>
              </div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {isLoading && (
              <motion.div
                className="absolute left-1.5 top-1.5 h-8 w-8 md:left-2 md:top-2 md:h-10 md:w-10"
                initial={{
                  scale: 0,
                  opacity: 0,
                }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  transition: {
                    type: 'spring',
                  },
                }}
                exit={{
                  scale: 0,
                  opacity: 0,
                }}
              >
                <div className="loader" />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.button>
      </div>
    </form>
  );
};
