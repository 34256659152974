import { useQuery } from '@tanstack/react-query';
import { listGroups } from '~/api';

export function useGroups() {
  const { data: groups } = useQuery({
    queryKey: ['groups'],
    queryFn: async () => {
      const { groups } = await listGroups({});

      return groups;
    },
  });

  return {
    groups,
  };
}
