import { useState } from 'react';

export function useSelectGroups<
  M extends { id: string } | { group: { id: string } },
>(groups: M[]) {
  const [groupIds, _setGroupIds] = useState(
    groups.map((group) => {
      if ('group' in group) {
        return group.group.id;
      }

      return group.id;
    }),
  );

  const setGroupId = (groupId: string) => {
    _setGroupIds((prevGroupIds) => {
      if (prevGroupIds.includes(groupId)) {
        return prevGroupIds.filter((prevGroupId) => prevGroupId !== groupId);
      }

      return [...prevGroupIds, groupId];
    });
  };

  return {
    groupIds,
    setGroupId,
  };
}
