import { currencies } from '~/currency';
import { cn } from '~/style';

export const CurrencySelector = ({
  currencyId,
  setCurrencyId,
}: {
  currencyId: string;
  setCurrencyId: (value: string) => void;
}) => {
  return (
    <div className="flex flex-wrap gap-2">
      {currencies.map((currency) => {
        const isSelected = currency.id === currencyId;

        return (
          <div
            className={cn(
              'flex h-10 w-16 cursor-pointer select-none items-center justify-center rounded-md bg-muted/70 font-semibold',
              {
                'bg-primary text-primary-foreground': isSelected,
                'hover:bg-muted': !isSelected,
              },
            )}
            key={currency.id}
            onClick={() => setCurrencyId(currency.id)}
          >
            {currency.shortName}
          </div>
        );
      })}
    </div>
  );
};
