import { getLanguages } from '~/language';
import { useLanguage } from '~/language/hooks';
import { usePlan } from '~/plan/hooks';
import { cn } from '~/style';

export const LanguageSelectorHorizontal = () => {
  const { language, selectLanguage } = useLanguage();
  const languages = getLanguages();
  const { hasEnterprise } = usePlan();

  if (!hasEnterprise()) return null;

  return (
    <div className="flex flex-wrap gap-4 py-4 md:gap-6">
      {languages.map((lang) => {
        const isCurrent = lang.id === language.id;

        return (
          <div
            className={cn(
              'w-16 cursor-pointer rounded-lg border border-transparent p-2 transition-opacity',
              {
                'opacity-30 hover:opacity-100': !isCurrent,
                'border-primary opacity-100': isCurrent,
              },
            )}
            key={lang.id}
            onClick={() => {
              selectLanguage(lang.id);
            }}
          >
            <img
              className="w-full rounded-sm"
              src={`/assets/flags/${lang.flag}.svg`}
              alt={lang.name}
            />
          </div>
        );
      })}
    </div>
  );
};
