import { z } from 'zod';

/////////////////////////////////////////
// INCIDENT MESSAGE SCHEMA
/////////////////////////////////////////

export const IncidentMessageSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  incidentId: z.string(),
  userId: z.string(),
  text: z.string(),
})

export type IncidentMessage = z.infer<typeof IncidentMessageSchema>

export default IncidentMessageSchema;
