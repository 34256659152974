import { useLocalParticipant, useTracks } from '@livekit/components-react';
import { useQuery } from '@tanstack/react-query';
import { Track } from 'livekit-client';
import { getMeeting, setMeetingHost } from '~/api';
import { invalidateQueries } from '~/query';

export function useMeeting(meetingId: string) {
  const { data: meeting } = useQuery({
    queryKey: ['meetings', meetingId],
    queryFn: async () => {
      const { meeting } = await getMeeting({ meetingId });

      return meeting;
    },
  });

  const { localParticipant } = useLocalParticipant();

  const claimHost = async (value: boolean) => {
    const hostId = value ? localParticipant.identity : null;

    await setMeetingHost({ meetingId, hostId });

    invalidateQueries(['meetings', meetingId]);
  };

  const videoTracks = useTracks([
    { source: Track.Source.Camera, withPlaceholder: true },
    { source: Track.Source.ScreenShare, withPlaceholder: false },
  ]);

  const hostVideoTrack =
    videoTracks.find((track) => {
      if (!meeting?.hostId) return false;

      const videoTrackIsHost = meeting.hostId === track.participant.identity;

      return videoTrackIsHost;
    }) || null;

  const isHost = localParticipant.identity === meeting?.hostId;

  return {
    meeting: meeting || null,
    hostVideoTrack,
    isHost,
    claimHost,
  };
}
