import { AnimatePresence, motion } from 'framer-motion';
import { Query, Response } from '~/ask/components';
import { useAsk } from '~/ask/hooks';
import { useDashboard } from '~/dashboard/hooks';
import { useKeyboard } from '~/hooks';

export const Ask = () => {
  const { isVisible, hide } = useAsk();
  const { shouldShowDashboardItems } = useDashboard();

  useKeyboard({
    onKeyDown: ({ key }) => {
      if (key === 'Escape') {
        hide();
      }
    },
  });

  if (!shouldShowDashboardItems) return null;

  return (
    <AnimatePresence>
      {isVisible && (
        <div className="fixed left-0 top-0 z-30">
          <motion.div
            className="fixed bottom-0 left-0 right-0 top-0 bg-background/90"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={hide}
          />

          <div className="fixed bottom-0 top-16 w-full max-w-full md:left-1/2 md:top-1/2 md:h-[80vh] md:max-h-[50rem] md:w-full md:max-w-5xl md:-translate-x-1/2 md:-translate-y-1/2">
            <div className="h-full p-5 md:p-8">
              <motion.div
                className="h-full w-full rounded-xl bg-background drop-shadow-2xl"
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
              >
                <div className="hide-scrollbar h-full w-full overflow-auto bg-muted/50 px-6 py-10 md:px-8 md:py-20">
                  <Query />

                  <Response />
                </div>
              </motion.div>
            </div>

            <CloseButton />
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

const CloseButton = () => {
  const { hide } = useAsk();

  return (
    <motion.button
      className="absolute -top-12 right-2 ml-auto cursor-pointer p-3 text-muted-foreground md:-mr-2 md:hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.7 }}
      exit={{ opacity: 0 }}
      whileHover={{ scale: 1.1, opacity: 1 }}
      whileTap={{ scale: 0.95 }}
      onClick={hide}
      tabIndex={3}
    >
      <svg
        className="size-7"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    </motion.button>
  );
};
