import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ConnectDropboxButton } from '~/dropbox/components';
import { useFilesConnection } from '~/file/hooks';
import { PathSelector } from './PathSelector';

export const FilesGuard = () => {
  const { isLoading, connection, load } = useFilesConnection();

  useEffect(() => {
    load();
  }, []);

  if (isLoading) {
    return (
      <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
        <div className="h-10 w-10">
          <div className="loader" />
        </div>
      </div>
    );
  }

  if (!connection) {
    return (
      <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
        <div className="mx-auto max-w-xs space-y-10 text-center md:space-y-14">
          <div className="space-y-4">
            <h1 className="text-lg font-semibold md:text-xl lg:text-2xl">
              Connect to Dropbox
            </h1>

            <p className="text-balance text-muted-foreground">
              Get started by connecting your Dropbox account
            </p>
          </div>

          <ConnectDropboxButton />
        </div>
      </div>
    );
  }

  if (!connection.folderId) {
    return (
      <div className="mx-auto max-w-xl px-6 pb-32 pt-28 md:pb-40 md:pt-36 lg:pb-48 lg:pt-60">
        <PathSelector />
      </div>
    );
  }

  return <Outlet />;
};
