import { useAsk } from '~/ask/hooks';

export const Response = () => {
  const { response, previousQuery } = useAsk();

  return (
    <div className="mx-auto mt-10 max-w-2xl space-y-4 px-4 md:mt-16">
      <div>{previousQuery}</div>

      <div className="text-lg leading-relaxed text-muted-foreground">
        {response}
      </div>
    </div>
  );
};
