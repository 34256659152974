import { useEffect } from 'react';
import { defaultDashboardPath } from '~/auth';

export const Redirect = ({ path }: { path?: string }) => {
  useEffect(() => {
    window.location.href = path || defaultDashboardPath;
  }, [path]);

  return null;
};
