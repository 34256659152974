import { z } from 'zod';

/////////////////////////////////////////
// BANK ACCOUNT SCHEMA
/////////////////////////////////////////

export const BankAccountSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  userId: z.string(),
  bankConnectionId: z.string(),
  bankInstitutionId: z.string(),
  iban: z.string(),
  status: z.string(),
  ownerName: z.string(),
})

export type BankAccount = z.infer<typeof BankAccountSchema>

export default BankAccountSchema;
