import { defineCopy } from '~/copy';

export default {
  reminders: defineCopy({
    default: 'Reminders',
    'de-DE': 'Erinnerungen',
    'es-ES': 'Recordatorios',
    'it-IT': 'Promemoria',
    'fr-FR': 'Rappels',
  }),
  reminder: defineCopy({
    default: 'Reminder',
    'de-DE': 'Erinnerung',
    'es-ES': 'Recordatorio',
    'it-IT': 'Promemoria',
    'fr-FR': 'Rappel',
  }),
  newReminder: defineCopy({
    default: 'New reminder',
    'de-DE': 'Neue Erinnerung',
    'es-ES': 'Nuevo recordatorio',
    'it-IT': 'Nuovo promemoria',
    'fr-FR': 'Nouveau rappel',
  }),
  createReminder: defineCopy({
    default: 'Create reminder',
    'de-DE': 'Erinnerung erstellen',
    'es-ES': 'Crear recordatorio',
    'it-IT': 'Crea promemoria',
    'fr-FR': 'Créer un rappel',
  }),
  reminderTitle: defineCopy({
    default: 'Reminder Title',
    'de-DE': 'Erinnerungstitel',
    'es-ES': 'Título del recordatorio',
    'it-IT': 'Titolo del promemoria',
    'fr-FR': 'Titre du rappel',
  }),
  addReminder: defineCopy({
    default: 'Add reminder',
    'de-DE': 'Erinnerung hinzufügen',
    'es-ES': 'Agregar recordatorio',
    'it-IT': 'Aggiungi promemoria',
    'fr-FR': 'Ajouter un rappel',
  }),
  noReminders: defineCopy({
    default: 'No reminders have been added',
    'de-DE': 'Es wurden keine Erinnerungen hinzugefügt',
    'es-ES': 'No se han agregado recordatorios',
    'it-IT': 'Non sono stati aggiunti promemoria',
    'fr-FR': "Aucun rappel n'a été ajouté",
  }),
  editReminder: defineCopy({
    default: 'Edit reminder',
    'de-DE': 'Erinnerung bearbeiten',
    'es-ES': 'Editar recordatorio',
    'it-IT': 'Modifica promemoria',
    'fr-FR': 'Modifier le rappel',
  }),
  deleteReminder: defineCopy({
    default: 'Deleting a reminder is permanent and cannot be undone.',
    'de-DE':
      'Das Löschen einer Erinnerung ist endgültig und kann nicht rückgängig gemacht werden.',
    'es-ES': 'Eliminar un recordatorio es permanente y no se puede deshacer.',
    'it-IT':
      "L'eliminazione di un promemoria è permanente e non può essere annullata.",
    'fr-FR':
      "La suppression d'un rappel est définitive et ne peut pas être annulée.",
  }),
  shareReminder: defineCopy({
    default: 'Share reminder',
    'de-DE': 'Erinnerung teilen',
    'es-ES': 'Compartir recordatorio',
    'it-IT': 'Condividi promemoria',
    'fr-FR': 'Partager le rappel',
  }),
};
