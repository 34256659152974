import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { liveblocksConfig, LiveblocksPlugin } from '@liveblocks/react-lexical';
import { useEffect } from 'react';
import { captureError } from '~/sentry';
import { Content } from '~/types';

export function ContentEditor({ content }: { content: Content }) {
  const initialConfig = liveblocksConfig({
    namespace: 'content',
    onError: async (error: unknown) => {
      await captureError(error);
    },
  });

  useEffect(() => {
    if (!content.text) {
      document.getElementById('content-text')?.focus();
    }
  }, [content.text]);

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div className="relative h-full">
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              id="content-text"
              className="min-h-screen outline-none md:text-lg lg:text-xl"
              style={{ padding: 0, margin: 0 }}
            />
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
      </div>

      <LiveblocksPlugin />
    </LexicalComposer>
  );
}
