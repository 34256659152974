import { useQuery } from '@tanstack/react-query';
import { listReminders } from '~/api';

export function useReminders() {
  const { data: reminders } = useQuery({
    queryKey: ['reminders'],
    queryFn: async () => {
      const { reminders } = await listReminders({});

      return reminders;
    },
  });

  return {
    reminders,
  };
}
