import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyDropboxAuth } from '~/api';

export const DropboxConnectPage = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const navigate = useNavigate();
  const hasLoadedRef = useRef(false);

  useEffect(() => {
    const fn = async () => {
      if (hasLoadedRef.current) return;

      hasLoadedRef.current = true;

      if (!code || !state) {
        navigate('/content');
        return;
      }

      try {
        await verifyDropboxAuth({ code, state });

        navigate('/content');
      } catch (error) {
        navigate('/content');
      }
    };

    fn();
  }, [code, state, navigate]);

  return (
    <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
      <div className="h-10 w-10">
        <div className="loader" />
      </div>
    </div>
  );
};
