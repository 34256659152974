import { z } from 'zod';

/////////////////////////////////////////
// ORG SCHEMA
/////////////////////////////////////////

export const OrgSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  name: z.string(),
  userId: z.string(),
  planId: z.string().nullable(),
  pronunciations: z.string().nullable(),
})

export type Org = z.infer<typeof OrgSchema>

export default OrgSchema;
