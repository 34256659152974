import { PasswordInput } from '~/components';
import { CopyKey } from '~/copy';
import { useCopy } from '~/copy/hooks';

export const FormPasswordInput = ({
  value,
  label,
  placeholder,
  onChange,
}: {
  value: string;
  label?: CopyKey;
  placeholder?: CopyKey;
  onChange: (value: string) => void;
}) => {
  const copy = useCopy();

  return (
    <div className="space-y-4">
      {label && <div>{copy.get(label)}</div>}

      <PasswordInput
        value={value}
        onChange={onChange}
        placeholder={placeholder && copy.get(placeholder)}
      />
    </div>
  );
};
