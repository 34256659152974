import { defineCopy } from '~/copy';

export default {
  file: defineCopy({
    default: 'File',
    'de-DE': 'Datei',
    'es-ES': 'Archivo',
    'it-IT': 'File',
    'fr-FR': 'Fichier',
  }),
  files: defineCopy({
    default: 'Files',
    'de-DE': 'Dateien',
    'es-ES': 'Archivos',
    'it-IT': 'File',
    'fr-FR': 'Fichiers',
  }),
  connectDropbox: defineCopy({
    default: 'Connect Dropbox',
    'de-DE': 'Dropbox verbinden',
    'es-ES': 'Conectar Dropbox',
    'it-IT': 'Collega Dropbox',
    'fr-FR': 'Connecter Dropbox',
  }),
  uploadImage: defineCopy({
    default: 'Upload Image',
    'de-DE': 'Bild hochladen',
    'es-ES': 'Subir imagen',
    'it-IT': 'Carica immagine',
    'fr-FR': 'Télécharger une image',
  }),
  changeImage: defineCopy({
    default: 'Change Image',
    'de-DE': 'Bild ändern',
    'es-ES': 'Cambiar imagen',
    'it-IT': 'Cambia immagine',
    'fr-FR': "Changer l'image",
  }),
  selectImage: defineCopy({
    default: 'Select Image',
    'de-DE': 'Bild auswählen',
    'es-ES': 'Seleccionar imagen',
    'it-IT': 'Seleziona immagine',
    'fr-FR': 'Sélectionner une image',
  }),
  selectAFolder: defineCopy({
    default: 'Select a folder',
    'de-DE': 'Wählen Sie einen Ordner aus',
    'es-ES': 'Seleccionar una carpeta',
    'it-IT': 'Seleziona una cartella',
    'fr-FR': 'Sélectionner un dossier',
  }),
  changeFolder: defineCopy({
    default: 'Change folder',
    'de-DE': 'Ordner ändern',
    'es-ES': 'Cambiar carpeta',
    'it-IT': 'Cambia cartella',
    'fr-FR': 'Changer de dossier',
  }),
  selectedFolder: defineCopy({
    default: 'Selected Folder',
    'de-DE': 'Ausgewählter Ordner',
    'es-ES': 'Carpeta seleccionada',
    'it-IT': 'Cartella selezionata',
    'fr-FR': 'Dossier sélectionné',
  }),
  chooseFolder: defineCopy({
    default: 'Choose folder',
    'de-DE': 'Ordner auswählen',
    'es-ES': 'Seleccionar carpeta',
    'it-IT': 'Scegli cartella',
    'fr-FR': 'Choisir un dossier',
  }),
  chooseFolderDescription: defineCopy({
    default:
      'Select the main folder for your organisation. If you do not have a folder yet, create it in your Dropbox account first.',
    'de-DE':
      'Wählen Sie den Hauptordner für Ihre Organisation aus. Wenn Sie noch keinen Ordner haben, erstellen Sie ihn zuerst in Ihrem Dropbox-Konto.',
    'es-ES':
      'Selecciona la carpeta principal de tu organización. Si aún no tienes una carpeta, créala primero en tu cuenta de Dropbox.',
    'it-IT':
      'Seleziona la cartella principale per la tua organizzazione. Se non hai ancora una cartella, creala prima nel tuo account Dropbox.',
    'fr-FR':
      "Sélectionnez le dossier principal de votre organisation. Si vous n'avez pas encore de dossier, créez-le d'abord dans votre compte Dropbox.",
  }),
};
