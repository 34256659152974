import { useQuery } from '@tanstack/react-query';
import { listPasswords } from '~/api';
import {
  BasicButton,
  CopyPassword,
  EditButton,
  Link,
  Separator,
} from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { decodePassword } from '~/password';
import { ShareButton } from '~/share/components';
import { Password } from '~/types';
import { formatText } from '~/utils';

export const PasswordsPage = () => {
  const copy = useCopy();

  const { data: passwords } = useQuery({
    queryKey: ['passwords'],
    queryFn: async () => {
      const { passwords } = await listPasswords({});

      return passwords;
    },
  });

  if (!passwords) return null;

  return (
    <DashboardLayout
      headerTitle={copy.get('passwords')}
      headerOptions={
        <>
          <Link href="/passwords/new">
            <BasicButton size="sm">
              <svg
                className="-ml-2 mr-4 size-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>

              <span>
                <span className="hidden md:inline">Add </span>

                <span>Password</span>
              </span>
            </BasicButton>
          </Link>
        </>
      }
    >
      <div className="mx-auto w-full max-w-5xl space-y-6">
        {passwords.length > 0 ? (
          <div className="mx-auto w-full max-w-5xl space-y-6">
            {passwords.map((password) => {
              return <PasswordCard key={password.id} password={password} />;
            })}
          </div>
        ) : (
          <div className="text-balance rounded-lg border px-6 py-20 text-center text-lg font-medium text-muted-foreground">
            {copy.get('noPasswords')}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

const PasswordCard = ({ password }: { password: Password }) => {
  const copy = useCopy();

  return (
    <div className="rounded-lg border" key={password.id}>
      <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14">
        <div className="text-lg font-semibold md:text-xl">{password.name}</div>

        <div className="ml-auto flex gap-2 md:gap-3">
          <ShareButton
            resource={password}
            href={`/passwords/${password.id}/share`}
          />
          <EditButton href={`/passwords/${password.id}/edit`} />
        </div>
      </div>

      {password.description && (
        <>
          <Separator />

          <div
            className="px-8 py-4 leading-relaxed text-muted-foreground"
            dangerouslySetInnerHTML={{
              __html: formatText(password.description),
            }}
          />
        </>
      )}

      <Separator />

      <div className="space-y-3 p-8">
        <div className="text-sm text-muted-foreground">
          {copy.get('password')}
        </div>

        <CopyPassword text={decodePassword(password.password)} />
      </div>
    </div>
  );
};
