import { defineCopy } from '~/copy';

export default {
  sharedMembers: defineCopy({
    default: 'Shared with the following members',
    'de-DE': 'Mit den folgenden Mitgliedern geteilt',
    'es-ES': 'Compartido con los siguientes miembros',
    'it-IT': 'Condiviso con i seguenti membri',
    'fr-FR': 'Partagé avec les membres suivants',
  }),
  inviteMemberToShare: defineCopy({
    default: 'Invite member to share',
    'de-DE': 'Mitglied einladen, um zu teilen',
    'es-ES': 'Invitar a un miembro a compartir',
    'it-IT': 'Invita un membro a condividere',
    'fr-FR': 'Inviter un membre à partager',
  }),
  sharedWithYou: defineCopy({
    default: 'Shared with you',
    'de-DE': 'Mit dir geteilt',
    'es-ES': 'Compartido contigo',
    'it-IT': 'Condiviso con te',
    'fr-FR': 'Partagé avec vous',
  }),
};
