import { useQuery } from '@tanstack/react-query';
import { listServices } from '~/api';
import { BasicButton, Link, Separator } from '~/components';
import { useCopy } from '~/copy/hooks';
import { DashboardLayout } from '~/dashboard/components';
import { Service, ServicesTotals } from '~/types';
import { formatText, getFrequency } from '~/utils';

export const ServicesPage = () => {
  const copy = useCopy();

  const { data } = useQuery({
    queryKey: ['services'],
    queryFn: async () => {
      const { services, totals } = await listServices({
        currencyId: 'gbp',
      });

      return { services, totals };
    },
  });

  if (!data) return null;

  const { services, totals } = data;

  return (
    <DashboardLayout
      headerTitle={copy.get('services')}
      headerOptions={
        <>
          <Link href="/services/new">
            <BasicButton size="sm">
              <svg
                className="-ml-2 mr-4 size-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>

              <span>
                <span className="hidden md:inline">Add </span>

                <span>Service</span>
              </span>
            </BasicButton>
          </Link>
        </>
      }
    >
      <div className="mx-auto w-full max-w-5xl space-y-6">
        <Totals totals={totals} />

        {services.length > 0 ? (
          <div className="mx-auto w-full max-w-5xl space-y-6">
            {services.map((service) => {
              return <ServiceCard key={service.id} service={service} />;
            })}
          </div>
        ) : (
          <div className="text-balance rounded-lg border px-6 py-20 text-center text-lg font-medium text-muted-foreground">
            {copy.get('noServices')}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

const ServiceCard = ({ service }: { service: Service }) => {
  const frequency = getFrequency(service.frequency);

  return (
    <Link className="block" href={`/services/${service.id}/settings`}>
      <div className="rounded-lg border hover:bg-muted/40" key={service.id}>
        <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14">
          <div className="text-lg font-semibold md:text-xl">{service.name}</div>

          <div className="space-y-1 text-right md:ml-auto">
            <div className="text-lg font-semibold md:text-xl">
              {service.costText}
            </div>

            {frequency?.term && (
              <div className="text-sm text-muted-foreground">
                per {frequency.term}
              </div>
            )}
          </div>
        </div>

        {service.description && (
          <>
            <Separator />

            <div className="ml-auto items-center gap-6 space-y-4 px-8 py-4 md:flex md:gap-3 md:space-y-0">
              <div
                className="leading-relaxed text-muted-foreground"
                dangerouslySetInnerHTML={{
                  __html: formatText(service.description),
                }}
              />
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

const Totals = ({ totals }: { totals: ServicesTotals }) => {
  const copy = useCopy();

  return (
    <div className="grid gap-6 md:grid-cols-3">
      <div className="w-full space-y-1 rounded-xl border px-5 py-3 md:space-y-4 md:px-8 md:py-6">
        <div className="text-muted-foreground">{copy.get('month')}</div>

        <div className="text-lg font-bold md:text-xl lg:text-2xl">
          {totals.monthText}
        </div>
      </div>

      <div className="w-full space-y-1 rounded-xl border px-5 py-3 md:space-y-4 md:px-8 md:py-6">
        <div className="text-muted-foreground">{copy.get('quarter')}</div>

        <div className="text-lg font-bold md:text-xl lg:text-2xl">
          {totals.quarterText}
        </div>
      </div>

      <div className="w-full space-y-1 rounded-xl border px-5 py-3 md:space-y-4 md:px-8 md:py-6">
        <div className="text-muted-foreground">{copy.get('year')}</div>

        <div className="text-lg font-bold md:text-xl lg:text-2xl">
          {totals.yearText}
        </div>
      </div>
    </div>
  );
};
