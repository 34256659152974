import { z } from 'zod';

/////////////////////////////////////////
// CONTENT AUDIO SCHEMA
/////////////////////////////////////////

export const ContentAudioSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  contentId: z.string(),
  languageId: z.string(),
  status: z.string(),
  text: z.string(),
})

export type ContentAudio = z.infer<typeof ContentAudioSchema>

export default ContentAudioSchema;
