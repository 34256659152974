import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { MicToggle } from '~/audio/components';
import {
  BasicButton,
  CameraToggle,
  Link,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/components';
import { useMeetings } from '~/meeting/hooks';
import { useOrg } from '~/org/hooks';
import { cn } from '~/style';
import { isProduction } from '~/utils';

export const MeetingPreview = ({ meetingId }: { meetingId: string }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { isOwner } = useOrg();
  const userIsOwner = isOwner();

  const {
    join,
    justLeftMeeting,
    videoDevices,
    audioDevices,
    videoDeviceId,
    audioDeviceId,
    videoEnabled,
    audioEnabled,
    saveVideoInputEnabled,
    saveVideoInputDeviceId,
    saveAudioInputEnabled,
    saveAudioInputDeviceId,
  } = useMeetings();

  useEffect(() => {
    saveVideoInputEnabled(isProduction() ? false : true);
    saveAudioInputEnabled(false);
  }, [saveVideoInputEnabled, saveAudioInputEnabled, userIsOwner]);

  useEffect(() => {
    const startVideo = async () => {
      const currentVideoElement = videoRef.current;

      try {
        // Stop any active streams before starting a new one
        if (currentVideoElement && currentVideoElement.srcObject) {
          const stream = currentVideoElement.srcObject as MediaStream;
          stream.getTracks().forEach((track) => track.stop());
        }

        // Access the video stream from the selected video device
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: videoDeviceId ? { exact: videoDeviceId } : undefined,
          },
        });

        if (currentVideoElement) {
          currentVideoElement.srcObject = stream;
        }
      } catch (err) {
        console.error('Error accessing video stream:', err);
        setError('Unable to access camera');
      }
    };

    if (videoDeviceId) {
      startVideo();
    }

    return () => {
      const currentVideoElement = videoRef.current; // eslint-disable-line

      // Stop the stream when the component unmounts or video device changes
      if (currentVideoElement && currentVideoElement.srcObject) {
        const stream = currentVideoElement.srcObject as MediaStream;
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [videoDeviceId]);

  if (justLeftMeeting) return null;

  if (error) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <div className="mx-auto max-w-md space-y-8 text-center md:space-y-10">
          <div className="space-y-4">
            <div className="text-lg md:text-xl">Unable to load camera</div>

            <p className="text-balance text-muted-foreground">
              Please reload the page. If this does not work, please contact
              support.
            </p>
          </div>

          <BasicButton
            className="w-40"
            variant="destructive"
            onClick={() => {
              localStorage.removeItem('lk-user-choices');
              window.location.reload();
            }}
          >
            Reload
          </BasicButton>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Link href="/meetings">
        <motion.div
          className="fixed left-4 top-4 z-30 flex h-12 w-12 items-center justify-center"
          initial={{ x: 0 }}
          whileHover={{
            x: -2,
          }}
        >
          <svg
            className="size-6 lg:size-8"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
            />
          </svg>
        </motion.div>
      </Link>

      <div className="mx-auto max-w-2xl space-y-6 px-8 py-20">
        {/* video */}
        <div className="relative aspect-video overflow-hidden rounded-xl bg-muted">
          <video
            className={cn('w-full scale-x-[-1]', {
              hidden: !videoEnabled,
            })}
            ref={videoRef}
            autoPlay
            playsInline
            muted
          />

          {!videoEnabled && (
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-lg font-medium">
              <svg
                className="size-14 text-muted-foreground/70 md:size-16 lg:size-20 xl:size-28"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
        </div>

        {/* video settings */}
        {videoDeviceId && (
          <div className="flex items-center gap-6 rounded-xl border p-4">
            <CameraToggle
              enabled={videoEnabled}
              onClick={() => saveVideoInputEnabled(!videoEnabled)}
            />

            {videoDeviceId && (
              <Select
                onValueChange={saveVideoInputDeviceId}
                defaultValue={videoDeviceId}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select video device" />
                </SelectTrigger>

                <SelectContent>
                  {videoDevices.map((device) => (
                    <SelectItem key={device.deviceId} value={device.deviceId}>
                      {device.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          </div>
        )}

        {/* audio */}
        <div className="rounded-xl border">
          {audioDeviceId && (
            <div className="flex items-center gap-6 p-4">
              <MicToggle
                enabled={audioEnabled}
                onClick={() => saveAudioInputEnabled(!audioEnabled)}
              />

              <Select
                onValueChange={saveAudioInputDeviceId}
                defaultValue={audioDeviceId}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select audio device" />
                </SelectTrigger>

                <SelectContent>
                  {audioDevices.map((device) => (
                    <SelectItem key={device.deviceId} value={device.deviceId}>
                      {device.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
        </div>

        <BasicButton
          className="w-full"
          size="lg"
          onClick={() => join({ meetingId })}
        >
          Join Meeting
        </BasicButton>
      </div>
    </div>
  );
};
