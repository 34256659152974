import { z } from 'zod';
import { createBackgroundFunction, createFunction } from '~/api';
import * as types from '~/types';

export const getShareSchema = {
  name: 'getShare',
  input: z.object({
    resourceId: z.string(),
  }),
  output: z.object({
    shareGroups: z.array(types.ShareGroupExpandedSchema),
    shareMembers: z.array(types.ShareMemberExpandedSchema),
  }),
};

export const getShare = createFunction(getShareSchema);

export const updateShareSchema = {
  name: 'updateShare',
  input: z.object({
    resourceType: types.ResourceType,
    resourceId: z.string(),
    groupIds: z.array(z.string()),
    memberIds: z.array(z.string()),
  }),
  output: z.object({}),
};

export const updateShare = createFunction(updateShareSchema);

export const getRealtimeTokenSchema = {
  name: 'getRealtimeToken',
  input: z.object({
    room: z.string(),
  }),
  output: z.object({
    token: z.string(),
  }),
};

export const getRealtimeToken = createFunction(getRealtimeTokenSchema);

export const createBoardStageSchema = {
  name: 'createBoardStage',
  input: z.object({
    boardId: z.string(),
    title: z.string(),
  }),
  output: z.object({
    stage: types.BoardStageSchema,
  }),
};

export const createBoardStage = createFunction(createBoardStageSchema);

export const listBoardStagesSchema = {
  name: 'listBoardStages',
  input: z.object({
    boardId: z.string(),
  }),
  output: z.object({
    stages: z.array(types.BoardStageExpandedSchema),
  }),
};

export const listBoardStages = createFunction(listBoardStagesSchema);

export const getBoardStageSchema = {
  name: 'getBoardStage',
  input: z.object({
    boardStageId: z.string(),
  }),
  output: z.object({
    stage: z.union([types.BoardStageSchema, z.null()]),
  }),
};

export const getBoardStage = createFunction(getBoardStageSchema);

export const updateBoardStageSchema = {
  name: 'updateBoardStage',
  input: z.object({
    boardStageId: z.string(),
    updateData: z.object({
      title: z.string().optional(),
    }),
  }),
  output: z.object({
    stage: types.BoardStageSchema,
  }),
};

export const updateBoardStage = createFunction(updateBoardStageSchema);

export const deleteBoardStageSchema = {
  name: 'deleteBoardStage',
  input: z.object({
    boardStageId: z.string(),
  }),
  output: z.object({}),
};

export const deleteBoardStage = createFunction(deleteBoardStageSchema);

export const getDropboxAuthUrlSchema = {
  name: 'getDropboxAuthUrl',
  input: z.object({}),
  output: z.object({
    url: z.string(),
    state: z.string(),
  }),
};

export const getDropboxAuthUrl = createFunction(getDropboxAuthUrlSchema);

export const verifyDropboxAuthSchema = {
  name: 'verifyDropboxAuth',
  input: z.object({
    code: z.string(),
    state: z.string(),
  }),
  output: z.object({}),
};

export const verifyDropboxAuth = createFunction(verifyDropboxAuthSchema);

export const getMeetingConnectionSchema = {
  name: 'getMeetingConnection',
  input: z.object({
    meetingId: z.string(),
  }),
  output: z.object({
    serverUrl: z.string(),
    roomName: z.string(),
    participantToken: z.string(),
    participantName: z.string(),
  }),
};

export const getMeetingConnection = createFunction(getMeetingConnectionSchema);

export const getMeetingSchema = {
  name: 'getMeeting',
  input: z.object({
    meetingId: z.string(),
  }),
  output: z.object({
    meeting: z.union([types.MeetingSchema, z.null()]),
  }),
};

export const getMeeting = createFunction(getMeetingSchema);

export const setMeetingHostSchema = {
  name: 'setMeetingHost',
  input: z.object({
    meetingId: z.string(),
    hostId: z.union([z.string(), z.null()]),
  }),
  output: z.object({
    meeting: z.union([types.MeetingSchema, z.null()]),
  }),
};

export const setMeetingHost = createFunction(setMeetingHostSchema);

export const listBankConnectionsSchema = {
  name: 'listBankConnections',
  input: z.object({}),
  output: z.object({
    connections: z.array(types.BankConnectionSchema),
  }),
};

export const listBankConnections = createFunction(listBankConnectionsSchema);

export const listBanksSchema = {
  name: 'listBanks',
  input: z.object({}),
  output: z.object({
    banks: z.array(types.BankSchema),
  }),
};

export const listBanks = createFunction(listBanksSchema);

export const getBankConnectionUrlSchema = {
  name: 'getBankConnectionUrl',
  input: z.object({
    bankId: z.string(),
  }),
  output: z.object({
    url: z.union([z.string(), z.null()]),
  }),
};

export const getBankConnectionUrl = createFunction(getBankConnectionUrlSchema);

export const enableBankConnectionSchema = {
  name: 'enableBankConnection',
  input: z.object({
    requisitionId: z.string(),
  }),
  output: z.object({}),
};

export const enableBankConnection = createFunction(enableBankConnectionSchema);

export const listBankAccountsSchema = {
  name: 'listBankAccounts',
  input: z.object({}),
  output: z.object({
    bankAccounts: z.array(types.BankAccountExpandedSchema),
  }),
};

export const listBankAccounts = createFunction(listBankAccountsSchema);

export const listTransactionsSchema = {
  name: 'listTransactions',
  input: z.object({}),
  output: z.object({
    bankInstitutions: z.array(types.BankInstitutionSchema),
    transactions: z.array(types.TransactionExpandedSchema),
  }),
};

export const listTransactions = createFunction(listTransactionsSchema);

export const getBankAccountSchema = {
  name: 'getBankAccount',
  input: z.object({
    bankAccountId: z.string(),
  }),
  output: z.object({
    bankAccount: z.union([types.BankAccountExpandedSchema, z.null()]),
  }),
};

export const getBankAccount = createFunction(getBankAccountSchema);

export const storeLatestTransactionsSchema = {
  name: 'storeLatestTransactions',
  input: z.object({
    connectionId: z.string(),
  }),
};

export const storeLatestTransactions = createBackgroundFunction(
  storeLatestTransactionsSchema,
);

export const createServiceSchema = {
  name: 'createService',
  input: z.object({
    name: z.string(),
    description: z.string().optional(),
    cost: z.union([z.number(), z.string()]),
    currencyId: z.string(),
    frequency: z.string(),
    discount: z.number(),
    discountType: z.union([z.string(), z.null(), z.undefined()]),
  }),
  output: z.object({}),
};

export const createService = createFunction(createServiceSchema);

export const updateServiceSchema = {
  name: 'updateService',
  input: z.object({
    serviceId: z.string(),
    updateData: z.object({
      name: z.string(),
      description: z.string().optional(),
      cost: z.union([z.number(), z.string()]),
      currencyId: z.string(),
      frequency: z.string(),
      discount: z.number(),
      discountType: z.union([z.string(), z.null(), z.undefined()]),
    }),
  }),
  output: z.object({}),
};

export const updateService = createFunction(updateServiceSchema);

export const getServiceSchema = {
  name: 'getService',
  input: z.object({
    orgId: z.string(),
    serviceId: z.string(),
  }),
  output: z.object({
    service: z.union([types.ServiceSchema, z.null()]),
  }),
};

export const getService = createFunction(getServiceSchema);

export const listServicesSchema = {
  name: 'listServices',
  input: z.object({
    currencyId: z.string(),
  }),
  output: z.object({
    services: z.array(types.ServiceSchema),
    totals: types.ServicesTotalsSchema,
  }),
};

export const listServices = createFunction(listServicesSchema);

export const deleteServiceSchema = {
  name: 'deleteService',
  input: z.object({
    serviceId: z.string(),
  }),
  output: z.object({}),
};

export const deleteService = createFunction(deleteServiceSchema);

export const createOrgSchema = {
  name: 'createOrg',
  input: z.object({
    name: z.string(),
  }),
  output: z.object({
    org: types.OrgSchema,
  }),
};

export const createOrg = createFunction(createOrgSchema);

export const loadOrgSchema = {
  name: 'loadOrg',
  input: z.object({
    orgId: z.string().optional(),
  }),
  output: z.object({
    org: z.union([types.OrgSchema, z.null()]),
    orgMember: z.union([types.OrgMemberSchema, z.null()]),
  }),
};

export const loadOrg = createFunction(loadOrgSchema);

export const updateOrgPronunciationsSchema = {
  name: 'updateOrgPronunciations',
  input: z.object({
    pronunciations: z.string(),
  }),
  output: z.object({
    org: types.OrgSchema,
  }),
};

export const updateOrgPronunciations = createFunction(
  updateOrgPronunciationsSchema,
);

export const listOrgMembersSchema = {
  name: 'listOrgMembers',
  input: z.object({}),
  output: z.object({
    members: z.array(types.UserSchema),
  }),
};

export const listOrgMembers = createFunction(listOrgMembersSchema);

export const listContentsSchema = {
  name: 'listContents',
  input: z.object({
    filter: z
      .object({
        isArchived: z.boolean().optional(),
      })
      .optional(),
  }),
  output: z.object({
    contents: z.array(types.ContentSchema),
    sharedContents: z.array(types.ContentSchema),
  }),
};

export const listContents = createFunction(listContentsSchema);

export const getContentSchema = {
  name: 'getContent',
  input: z.object({
    contentId: z.string(),
  }),
  output: z.object({
    content: z.union([types.ContentSchema, z.null()]),
    contentAudio: z.union([types.ContentAudioSchema, z.null()]),
  }),
};

export const getContent = createFunction(getContentSchema);

export const updateContentSchema = {
  name: 'updateContent',
  input: z.object({
    contentId: z.string(),
    data: z.object({
      title: z.string().optional(),
      textInstructions: z.string().optional(),
      autoGenerateText: z.boolean().optional(),
      autoGenerateAudio: z.boolean().optional(),
      isArchived: z.boolean().optional(),
    }),
  }),
  output: z.object({
    content: types.ContentSchema,
  }),
};

export const updateContent = createFunction(updateContentSchema);

export const deleteContentSchema = {
  name: 'deleteContent',
  input: z.object({
    contentId: z.string(),
  }),
  output: z.object({}),
};

export const deleteContent = createFunction(deleteContentSchema);

export const onContentUpdateSchema = {
  name: 'onContentUpdate',
  input: z.object({
    userId: z.string(),
    orgId: z.string(),
    contentId: z.string(),
    languageId: z.string(),
  }),
};

export const onContentUpdate = createBackgroundFunction(onContentUpdateSchema);

export const generateContentAudioSchema = {
  name: 'generateContentAudio',
  input: z.object({
    contentAudioId: z.string(),
  }),
};

export const generateContentAudio = createBackgroundFunction(
  generateContentAudioSchema,
);

export const processContentNotificationSchema = {
  name: 'processContentNotification',
  input: z.object({
    userId: z.string(),
    orgId: z.string(),
    contentId: z.string(),
    notificationId: z.string(),
  }),
};

export const processContentNotification = createBackgroundFunction(
  processContentNotificationSchema,
);

export const getContentAudioUrlSchema = {
  name: 'getContentAudioUrl',
  input: z.object({
    contentAudioId: z.string(),
  }),
  output: z.object({
    url: z.string(),
  }),
};

export const getContentAudioUrl = createFunction(getContentAudioUrlSchema);

export const planCheckoutSchema = {
  name: 'planCheckout',
  input: z.object({
    orgId: z.string(),
    sessionId: z.string(),
  }),
  output: z.object({
    success: z.boolean(),
  }),
};

export const planCheckout = createFunction(planCheckoutSchema);

export const listIncidentAlertsSchema = {
  name: 'listIncidentAlerts',
  input: z.object({
    incidentId: z.string(),
  }),
  output: z.object({
    incidentAlerts: z.array(types.IncidentAlertSchema),
  }),
};

export const listIncidentAlerts = createFunction(listIncidentAlertsSchema);

export const getIncidentAlertSchema = {
  name: 'getIncidentAlert',
  input: z.object({
    incidentAlertId: z.string(),
  }),
  output: z.object({
    incidentAlert: z.union([types.IncidentAlertSchema, z.null()]),
  }),
};

export const getIncidentAlert = createFunction(getIncidentAlertSchema);

export const listMemberIncidentAlertsSchema = {
  name: 'listMemberIncidentAlerts',
  input: z.object({
    incidentAlertId: z.string(),
  }),
  output: z.object({
    memberIncidentAlerts: z.array(types.MemberIncidentAlertExpandedSchema),
  }),
};

export const listMemberIncidentAlerts = createFunction(
  listMemberIncidentAlertsSchema,
);

export const sendIncidentAlertSchema = {
  name: 'sendIncidentAlert',
  input: z.object({
    incidentId: z.string(),
  }),
  output: z.object({
    incidentAlertId: z.string(),
  }),
};

export const sendIncidentAlert = createFunction(sendIncidentAlertSchema);

export const processIncidentAlertSchema = {
  name: 'processIncidentAlert',
  input: z.object({
    incidentAlertId: z.string(),
  }),
};

export const processIncidentAlert = createBackgroundFunction(
  processIncidentAlertSchema,
);

export const createBoardItemSchema = {
  name: 'createBoardItem',
  input: z.object({
    boardId: z.string(),
    boardStageId: z.string(),
    title: z.string().optional(),
    description: z.string().optional(),
  }),
  output: z.object({
    item: types.BoardItemSchema,
  }),
};

export const createBoardItem = createFunction(createBoardItemSchema);

export const updateBoardItemSchema = {
  name: 'updateBoardItem',
  input: z.object({
    boardItemId: z.string(),
    updateData: z.object({
      title: z.string().optional(),
      description: z.string().optional(),
    }),
  }),
  output: z.object({
    item: types.BoardItemSchema,
  }),
};

export const updateBoardItem = createFunction(updateBoardItemSchema);

export const deleteBoardItemSchema = {
  name: 'deleteBoardItem',
  input: z.object({
    boardItemId: z.string(),
  }),
  output: z.object({}),
};

export const deleteBoardItem = createFunction(deleteBoardItemSchema);

export const createBoardSchema = {
  name: 'createBoard',
  input: z.object({
    name: z.string(),
    createDefaults: z.boolean().optional(),
  }),
  output: z.object({
    board: types.BoardSchema,
  }),
};

export const createBoard = createFunction(createBoardSchema);

export const listBoardsSchema = {
  name: 'listBoards',
  input: z.object({
    filter: z.object({}).optional(),
  }),
  output: z.object({
    boards: z.array(types.BoardSchema),
  }),
};

export const listBoards = createFunction(listBoardsSchema);

export const getBoardSchema = {
  name: 'getBoard',
  input: z.object({
    boardId: z.string(),
  }),
  output: z.object({
    board: z.union([types.BoardSchema, z.null()]),
  }),
};

export const getBoard = createFunction(getBoardSchema);

export const updateBoardSchema = {
  name: 'updateBoard',
  input: z.object({
    boardId: z.string(),
    updateData: z.object({
      name: z.string().optional(),
    }),
  }),
  output: z.object({
    board: types.BoardSchema,
  }),
};

export const updateBoard = createFunction(updateBoardSchema);

export const updateBoardStagesSchema = {
  name: 'updateBoardStages',
  input: z.object({
    boardId: z.string(),
    items: types.BoardStageItemsSchema,
  }),
  output: z.object({}),
};

export const updateBoardStages = createFunction(updateBoardStagesSchema);

export const updateBoardStagePositionsSchema = {
  name: 'updateBoardStagePositions',
  input: z.object({
    boardId: z.string(),
    stageIds: types.BoardStagesInputSchema,
  }),
  output: z.object({}),
};

export const updateBoardStagePositions = createFunction(
  updateBoardStagePositionsSchema,
);

export const updateBoardItemPositionsSchema = {
  name: 'updateBoardItemPositions',
  input: z.object({
    boardId: z.string(),
    items: types.BoardItemsInputSchema,
  }),
  output: z.object({}),
};

export const updateBoardItemPositions = createFunction(
  updateBoardItemPositionsSchema,
);

export const deleteBoardSchema = {
  name: 'deleteBoard',
  input: z.object({
    boardId: z.string(),
  }),
  output: z.object({}),
};

export const deleteBoard = createFunction(deleteBoardSchema);

export const getPayUrlSchema = {
  name: 'getPayUrl',
  input: z.object({}),
  output: z.object({
    url: z.string(),
  }),
};

export const getPayUrl = createFunction(getPayUrlSchema);

export const acceptMemberInviteSchema = {
  name: 'acceptMemberInvite',
  input: z.object({
    code: z.string(),
  }),
  output: z.object({
    org: z.union([types.OrgSchema, z.null()]),
  }),
};

export const acceptMemberInvite = createFunction(acceptMemberInviteSchema);

export const getMemberInviteSchema = {
  name: 'getMemberInvite',
  input: z.object({
    code: z.string(),
  }),
  output: z.object({
    invite: z.union([types.MemberInviteCodeSchema, z.null()]),
    org: z.union([types.OrgSchema, z.null()]),
  }),
};

export const getMemberInvite = createFunction(getMemberInviteSchema);

export const inviteMemberSchema = {
  name: 'inviteMember',
  input: z.object({
    email: z.string(),
  }),
  output: z.object({}),
};

export const inviteMember = createFunction(inviteMemberSchema);

export const updateMemberSchema = {
  name: 'updateMember',
  input: z.object({
    memberId: z.string(),
    phone: z.union([z.string(), z.null()]),
    name: z.string(),
  }),
  output: z.object({
    member: z.union([types.UserSchema, z.null()]),
  }),
};

export const updateMember = createFunction(updateMemberSchema);

export const listMembersSchema = {
  name: 'listMembers',
  input: z.object({
    filter: z.object({}).optional(),
    omitCurrentUser: z.boolean().optional(),
  }),
  output: z.object({
    members: z.array(types.OrgMemberExpandedSchema),
  }),
};

export const listMembers = createFunction(listMembersSchema);

export const listMemberInvitesSchema = {
  name: 'listMemberInvites',
  input: z.object({}),
  output: z.object({
    invites: z.array(types.MemberInviteCodeSchema),
  }),
};

export const listMemberInvites = createFunction(listMemberInvitesSchema);

export const getMemberSchema = {
  name: 'getMember',
  input: z.object({
    memberId: z.string(),
  }),
  output: z.object({
    member: z.union([types.UserSchema, z.null()]),
  }),
};

export const getMember = createFunction(getMemberSchema);

export const removeMemberSchema = {
  name: 'removeMember',
  input: z.object({
    memberId: z.string(),
  }),
  output: z.object({
    ok: z.boolean(),
  }),
};

export const removeMember = createFunction(removeMemberSchema);

export const createGroupSchema = {
  name: 'createGroup',
  input: z.object({
    name: z.string(),
    memberIds: z.array(z.string()),
  }),
  output: z.object({
    group: types.GroupSchema,
  }),
};

export const createGroup = createFunction(createGroupSchema);

export const listGroupsSchema = {
  name: 'listGroups',
  input: z.object({
    filter: z.object({}).optional(),
  }),
  output: z.object({
    groups: z.array(types.GroupExpandedSchema),
  }),
};

export const listGroups = createFunction(listGroupsSchema);

export const getGroupSchema = {
  name: 'getGroup',
  input: z.object({
    groupId: z.string(),
  }),
  output: z.object({
    group: z.union([types.GroupExpandedSchema, z.null()]),
  }),
};

export const getGroup = createFunction(getGroupSchema);

export const updateGroupSchema = {
  name: 'updateGroup',
  input: z.object({
    groupId: z.string(),
    updateData: z.object({
      name: z.string().optional(),
    }),
    memberIds: z.array(z.string()),
  }),
  output: z.object({
    group: types.GroupExpandedSchema,
  }),
};

export const updateGroup = createFunction(updateGroupSchema);

export const deleteGroupSchema = {
  name: 'deleteGroup',
  input: z.object({
    groupId: z.string(),
  }),
  output: z.object({}),
};

export const deleteGroup = createFunction(deleteGroupSchema);

export const createReminderSchema = {
  name: 'createReminder',
  input: z.object({
    title: z.string(),
    description: z.string().optional(),
    frequency: z.string(),
    days: z.string().optional(),
    dates: z.string().optional(),
    months: z.string().optional(),
  }),
  output: z.object({
    reminder: types.ReminderSchema,
  }),
};

export const createReminder = createFunction(createReminderSchema);

export const listRemindersSchema = {
  name: 'listReminders',
  input: z.object({
    filter: z.object({}).optional(),
  }),
  output: z.object({
    reminders: z.array(types.ReminderSchema),
  }),
};

export const listReminders = createFunction(listRemindersSchema);

export const getReminderSchema = {
  name: 'getReminder',
  input: z.object({
    reminderId: z.string(),
  }),
  output: z.object({
    reminder: z.union([types.ReminderSchema, z.null()]),
  }),
};

export const getReminder = createFunction(getReminderSchema);

export const updateReminderSchema = {
  name: 'updateReminder',
  input: z.object({
    reminderId: z.string(),
    updateData: z.object({
      title: z.string().optional(),
      description: z.string().optional(),
      frequency: z.string(),
      days: z.string().optional(),
      dates: z.string().optional(),
      months: z.string().optional(),
    }),
  }),
  output: z.object({
    reminder: types.ReminderSchema,
  }),
};

export const updateReminder = createFunction(updateReminderSchema);

export const deleteReminderSchema = {
  name: 'deleteReminder',
  input: z.object({
    reminderId: z.string(),
  }),
  output: z.object({}),
};

export const deleteReminder = createFunction(deleteReminderSchema);

export const sendReminderSchema = {
  name: 'sendReminder',
  input: z.object({
    reminderId: z.string(),
  }),
};

export const sendReminder = createBackgroundFunction(sendReminderSchema);

export const createIncidentSchema = {
  name: 'createIncident',
  input: z.object({
    description: z.string(),
    location: z.string(),
    date: z.number(),
  }),
  output: z.object({
    incident: types.IncidentSchema,
  }),
};

export const createIncident = createFunction(createIncidentSchema);

export const listIncidentsSchema = {
  name: 'listIncidents',
  input: z.object({
    filter: z
      .object({
        isResolved: z.boolean().optional(),
      })
      .optional(),
  }),
  output: z.object({
    incidents: z.array(types.IncidentSchema),
  }),
};

export const listIncidents = createFunction(listIncidentsSchema);

export const getIncidentSchema = {
  name: 'getIncident',
  input: z.object({
    incidentId: z.string(),
  }),
  output: z.object({
    incident: z.union([types.IncidentSchema, z.null()]),
    incidentAudio: z.union([types.IncidentAudioSchema, z.null()]),
  }),
};

export const getIncident = createFunction(getIncidentSchema);

export const updateIncidentSchema = {
  name: 'updateIncident',
  input: z.object({
    incidentId: z.string(),
    updateData: z.object({
      title: z.string().optional(),
      description: z.string().optional(),
      location: z.string().optional(),
      date: z.number().optional(),
      isResolved: z.boolean().optional(),
    }),
  }),
  output: z.object({
    incident: types.IncidentSchema,
  }),
};

export const updateIncident = createFunction(updateIncidentSchema);

export const deleteIncidentSchema = {
  name: 'deleteIncident',
  input: z.object({
    incidentId: z.string(),
  }),
  output: z.object({}),
};

export const deleteIncident = createFunction(deleteIncidentSchema);

export const getIncidentAudioUrlSchema = {
  name: 'getIncidentAudioUrl',
  input: z.object({
    incidentAudioId: z.string(),
  }),
  output: z.object({
    url: z.string(),
  }),
};

export const getIncidentAudioUrl = createFunction(getIncidentAudioUrlSchema);

export const generateIncidentAudioSchema = {
  name: 'generateIncidentAudio',
  input: z.object({
    incidentAudioId: z.string(),
  }),
};

export const generateIncidentAudio = createBackgroundFunction(
  generateIncidentAudioSchema,
);

export const generateIncidentSummarySchema = {
  name: 'generateIncidentSummary',
  input: z.object({
    orgId: z.string(),
    incidentId: z.string(),
  }),
};

export const generateIncidentSummary = createBackgroundFunction(
  generateIncidentSummarySchema,
);

export const initAskSchema = {
  name: 'initAsk',
  input: z.object({
    orgId: z.string(),
    query: z.string(),
  }),
  output: z.object({
    token: z.string(),
  }),
};

export const initAsk = createFunction(initAskSchema);

export const createAssetSchema = {
  name: 'createAsset',
  input: z.object({
    name: z.string(),
    description: z.string().optional(),
    cost: z.union([z.number(), z.string()]),
    currencyId: z.string(),
    frequency: z.string(),
    discount: z.number(),
    discountType: z.union([z.string(), z.null(), z.undefined()]),
  }),
  output: z.object({}),
};

export const createAsset = createFunction(createAssetSchema);

export const updateAssetSchema = {
  name: 'updateAsset',
  input: z.object({
    assetId: z.string(),
    updateData: z.object({
      name: z.string(),
      description: z.string().optional(),
      cost: z.union([z.number(), z.string()]),
      currencyId: z.string(),
      frequency: z.string(),
      discount: z.number(),
      discountType: z.union([z.string(), z.null(), z.undefined()]),
    }),
  }),
  output: z.object({}),
};

export const updateAsset = createFunction(updateAssetSchema);

export const getAssetSchema = {
  name: 'getAsset',
  input: z.object({
    orgId: z.string(),
    assetId: z.string(),
  }),
  output: z.object({
    asset: z.union([types.AssetSchema, z.null()]),
  }),
};

export const getAsset = createFunction(getAssetSchema);

export const listAssetsSchema = {
  name: 'listAssets',
  input: z.object({
    currencyId: z.string(),
  }),
  output: z.object({
    assets: z.array(types.AssetSchema),
    totals: types.AssetsTotalsSchema,
  }),
};

export const listAssets = createFunction(listAssetsSchema);

export const deleteAssetSchema = {
  name: 'deleteAsset',
  input: z.object({
    assetId: z.string(),
  }),
  output: z.object({}),
};

export const deleteAsset = createFunction(deleteAssetSchema);

export const getFileUrlSchema = {
  name: 'getFileUrl',
  input: z.object({
    fileId: z.string(),
  }),
  output: z.object({
    url: z.string(),
  }),
};

export const getFileUrl = createFunction(getFileUrlSchema);

export const getFirstFileConnectionSchema = {
  name: 'getFirstFileConnection',
  input: z.object({}),
  output: z.object({
    connection: z.union([types.DropboxConnectionSchema, z.null()]),
  }),
};

export const getFirstFileConnection = createFunction(
  getFirstFileConnectionSchema,
);

export const setFirstFileConnectionFolderSchema = {
  name: 'setFirstFileConnectionFolder',
  input: z.object({
    folderId: z.string(),
    folderPath: z.string(),
  }),
  output: z.object({}),
};

export const setFirstFileConnectionFolder = createFunction(
  setFirstFileConnectionFolderSchema,
);

export const listRemoteFilesSchema = {
  name: 'listRemoteFiles',
  input: z.object({
    path: z.string().optional(),
  }),
  output: z.object({
    files: z.array(types.RemoteFileSchema),
  }),
};

export const listRemoteFiles = createFunction(listRemoteFilesSchema);

export const createFolderSchema = {
  name: 'createFolder',
  input: z.object({}),
  output: z.object({}),
};

export const createFolder = createFunction(createFolderSchema);

export const getFileLinkSchema = {
  name: 'getFileLink',
  input: z.object({
    path: z.string(),
  }),
  output: z.object({
    url: z.union([z.string(), z.null()]),
  }),
};

export const getFileLink = createFunction(getFileLinkSchema);

export const syncFilesSchema = {
  name: 'syncFiles',
  input: z.object({
    connectionId: z.string(),
  }),
};

export const syncFiles = createBackgroundFunction(syncFilesSchema);

export const updateContentFromFileSchema = {
  name: 'updateContentFromFile',
  input: z.object({
    fileId: z.string(),
  }),
};

export const updateContentFromFile = createBackgroundFunction(
  updateContentFromFileSchema,
);

export const createPasswordSchema = {
  name: 'createPassword',
  input: z.object({
    orgId: z.string(),
    name: z.string(),
    description: z.string().optional(),
    passwordText: z.string(),
  }),
  output: z.object({}),
};

export const createPassword = createFunction(createPasswordSchema);

export const updatePasswordSchema = {
  name: 'updatePassword',
  input: z.object({
    orgId: z.string(),
    passwordId: z.string(),
    name: z.string(),
    description: z.string().optional(),
    passwordText: z.string(),
  }),
  output: z.object({}),
};

export const updatePassword = createFunction(updatePasswordSchema);

export const getPasswordSchema = {
  name: 'getPassword',
  input: z.object({
    orgId: z.string(),
    passwordId: z.string(),
  }),
  output: z.object({
    password: z.union([types.PasswordSchema, z.null()]),
  }),
};

export const getPassword = createFunction(getPasswordSchema);

export const listPasswordsSchema = {
  name: 'listPasswords',
  input: z.object({}),
  output: z.object({
    passwords: z.array(types.PasswordSchema),
  }),
};

export const listPasswords = createFunction(listPasswordsSchema);

export const deletePasswordSchema = {
  name: 'deletePassword',
  input: z.object({
    passwordId: z.string(),
  }),
  output: z.object({}),
};

export const deletePassword = createFunction(deletePasswordSchema);

export const createIncidentMessageSchema = {
  name: 'createIncidentMessage',
  input: z.object({
    incidentId: z.string(),
    text: z.string(),
  }),
  output: z.object({
    incidentMessage: types.IncidentMessageExpandedSchema,
  }),
};

export const createIncidentMessage = createFunction(
  createIncidentMessageSchema,
);

export const getIncidentMessageSchema = {
  name: 'getIncidentMessage',
  input: z.object({
    incidentMessageId: z.string(),
  }),
  output: z.object({
    incidentMessage: z.union([types.IncidentMessageExpandedSchema, z.null()]),
  }),
};

export const getIncidentMessage = createFunction(getIncidentMessageSchema);

export const updateIncidentMessageSchema = {
  name: 'updateIncidentMessage',
  input: z.object({
    incidentMessageId: z.string(),
    updateData: z.object({
      text: z.string(),
    }),
  }),
  output: z.object({
    incidentMessage: types.IncidentMessageExpandedSchema,
  }),
};

export const updateIncidentMessage = createFunction(
  updateIncidentMessageSchema,
);

export const listIncidentMessagesSchema = {
  name: 'listIncidentMessages',
  input: z.object({
    incidentId: z.string(),
  }),
  output: z.object({
    incidentMessages: z.array(types.IncidentMessageExpandedSchema),
  }),
};

export const listIncidentMessages = createFunction(listIncidentMessagesSchema);

export const deleteIncidentMessageSchema = {
  name: 'deleteIncidentMessage',
  input: z.object({
    incidentMessageId: z.string(),
  }),
  output: z.object({}),
};

export const deleteIncidentMessage = createFunction(
  deleteIncidentMessageSchema,
);
