import { z } from 'zod';
import * as db from './schema';

// resource
export const ResourceType = z.enum([
  'content',
  'password',
  'board',
  'incident',
  'meeting',
  'reminder',
  'service',
  'asset',
  'file',
  'folder',
]);

export type ResourceType = z.infer<typeof ResourceType>;

// org member
export const OrgMemberExpandedSchema = db.OrgMemberSchema.extend({
  member: db.UserSchema,
});

export type OrgMemberExpanded = z.infer<typeof OrgMemberExpandedSchema>;

// member incident alert
export const MemberIncidentAlertExpandedSchema =
  db.MemberIncidentAlertSchema.extend({
    member: db.UserSchema,
  });

export type MemberIncidentAlertExpanded = z.infer<
  typeof MemberIncidentAlertExpandedSchema
>;

// incident message
export const IncidentMessageExpandedSchema = db.IncidentMessageSchema.extend({
  user: db.UserSchema,
});

export type IncidentMessageExpanded = z.infer<
  typeof IncidentMessageExpandedSchema
>;

// share member
export const ShareMemberExpandedSchema = db.ShareMemberSchema.extend({
  member: db.UserSchema,
});

export type ShareMemberExpanded = z.infer<typeof ShareMemberExpandedSchema>;

// share group
export const ShareGroupExpandedSchema = db.ShareGroupSchema.extend({
  group: db.GroupSchema,
});

export type ShareGroupExpanded = z.infer<typeof ShareGroupExpandedSchema>;

// group member
export const GroupMemberExpandedSchema = db.GroupMemberSchema.extend({
  member: db.UserSchema,
});

export type GroupMemberExpanded = z.infer<typeof GroupMemberExpandedSchema>;

// group
export const GroupExpandedSchema = db.GroupSchema.extend({
  groupMembers: z.array(GroupMemberExpandedSchema),
});

export type GroupExpanded = z.infer<typeof GroupExpandedSchema>;

// board stages input
export const BoardStagesInputSchema = z.array(
  z.union([z.string(), z.number()]),
);

export type BoardStagesInput = z.infer<typeof BoardStagesInputSchema>;

// board stage items
export const BoardStageItemsSchema = z.array(
  z.object({
    id: z.string(),
    title: z.string(),
  }),
);

export type BoardStageItems = z.infer<typeof BoardStageItemsSchema>;

// board items input
export const BoardItemsInputSchema = z.record(
  z.array(z.union([z.string(), z.number()])),
);

export type BoardItemsInput = z.infer<typeof BoardItemsInputSchema>;

// board stage
export const BoardStageExpandedSchema = db.BoardStageSchema.extend({
  boardItems: z.array(db.BoardItemSchema),
});

export type BoardStageExpanded = z.infer<typeof BoardStageExpandedSchema>;

// service
export const ServicesTotalsSchema = z.object({
  month: z.number(),
  monthText: z.string(),
  year: z.number(),
  yearText: z.string(),
  quarter: z.number(),
  quarterText: z.string(),
});

export type ServicesTotals = z.infer<typeof ServicesTotalsSchema>;

// asset
export const AssetsTotalsSchema = z.object({
  month: z.number(),
  monthText: z.string(),
  year: z.number(),
  yearText: z.string(),
  quarter: z.number(),
  quarterText: z.string(),
  oneTime: z.number(),
  oneTimeText: z.string(),
});

export type AssetsTotals = z.infer<typeof AssetsTotalsSchema>;

// file
export const RemoteFileSchema = z.object({
  id: z.string(),
  name: z.string(),
  isFolder: z.boolean(),
  isFile: z.boolean(),
  type: z.string().optional(),
  size: z.number().optional(),
  path: z.string().optional(),
});

export type RemoteFile = z.infer<typeof RemoteFileSchema>;

// finance
export const BankSchema = z.object({
  id: z.string(),
  name: z.string(),
  bic: z.string(),
  transaction_total_days: z.string(),
  countries: z.array(z.string()),
  logo: z.string(),
});

export type Bank = z.infer<typeof BankSchema>;

export const RawTransactionSchema = z.object({
  transactionId: z.string(),
  debtorName: z.string(),
  debtorAccount: z.object({
    iban: z.string(),
  }),
  transactionAmount: z.object({
    currency: z.string(),
    amount: z.string(),
  }),
  currencyExchange: z
    .object({
      instructedAmount: z.object({
        currency: z.string(),
        amount: z.string(),
      }),
      sourceCurrency: z.string(),
      exchangeRate: z.string(),
      unitCurrency: z.string(),
      targetCurrency: z.string(),
    })
    .optional(),
  bookingDate: z.string(),
  valueDate: z.string(),
  remittanceInformationUnstructured: z.string(),
  creditorName: z.string().optional(),
  internalTransactionId: z.string().optional(),
  proprietaryBankTransactionCode: z.string(),
  merchantCategoryCode: z.string().optional(),
});

export type RawTransaction = z.infer<typeof RawTransactionSchema>;

export const RawPendingTransactionSchema = z.object({
  transactionAmount: z.object({
    currency: z.string(),
    amount: z.string(),
  }),
  valueDate: z.string(),
  remittanceInformationUnstructured: z.string(),
});

export type RawPendingTransaction = z.infer<typeof RawPendingTransactionSchema>;

// bank expanded
export const BankAccountExpandedSchema = db.BankAccountSchema.extend({
  bankInstitution: db.BankInstitutionSchema,
  transactions: z.array(db.TransactionSchema),
});

export type BankAccountExpanded = z.infer<typeof BankAccountExpandedSchema>;

// transaction expanded
export const TransactionExpandedSchema = db.TransactionSchema.extend({
  bankAccount: db.BankAccountSchema,
});

export type TransactionExpanded = z.infer<typeof TransactionExpandedSchema>;
