import { defineCopy } from '~/copy';

export default {
  members: defineCopy({
    default: 'Members',
    'de-DE': 'Kontakte',
    'es-ES': 'Memberos',
    'it-IT': 'Contatti',
    'fr-FR': 'Members',
  }),
  invites: defineCopy({
    default: 'Invites',
    'de-DE': 'Einladungen',
    'es-ES': 'Invitaciones',
    'it-IT': 'Inviti',
    'fr-FR': 'Invitations',
  }),
  addMember: defineCopy({
    default: 'Add member',
    'de-DE': 'Kontakt hinzufügen',
    'es-ES': 'Agregar membero',
    'it-IT': 'Aggiungi contatto',
    'fr-FR': 'Ajouter un member',
  }),
  editMember: defineCopy({
    default: 'Edit member',
    'de-DE': 'Kontakt bearbeiten',
    'es-ES': 'Editar membero',
    'it-IT': 'Modifica contatto',
    'fr-FR': 'Modifier le member',
  }),
  inviteMember: defineCopy({
    default: 'Invite member',
    'de-DE': 'Kontakt einladen',
    'es-ES': 'Invitar membero',
    'it-IT': 'Invita contatto',
    'fr-FR': 'Inviter un member',
  }),
  newMember: defineCopy({
    default: 'New member',
    'de-DE': 'Neuer Kontakt',
    'es-ES': 'Nuevo membero',
    'it-IT': 'Nuovo contatto',
    'fr-FR': 'Nouveau member',
  }),
  removeMember: defineCopy({
    default: 'Remove member',
    'de-DE': 'Kontakt entfernen',
    'es-ES': 'Eliminar membero',
    'it-IT': 'Rimuovi contatto',
    'fr-FR': 'Supprimer le member',
  }),
  enterTheirEmail: defineCopy({
    default: 'Enter their email address',
    'de-DE': 'Geben Sie ihre E-Mail-Adresse ein',
    'es-ES': 'Ingrese su dirección de correo electrónico',
    'it-IT': 'Inserisci il loro indirizzo email',
    'fr-FR': 'Entrez leur adresse e-mail',
  }),
  deleteMemberMessage: defineCopy({
    default:
      'This will permanently remove this member from the org. This action cannot be undone.',
    'de-DE':
      'Dies entfernt diesen Kontakt dauerhaft aus der Gruppe. Diese Aktion kann nicht rückgängig gemacht werden.',
    'es-ES':
      'Esto eliminará permanentemente este membero de la organización. Esta acción no se puede deshacer.',
    'it-IT':
      'Questo rimuoverà definitivamente questo contatto dal gruppo. Questa azione non può essere annullata.',
    'fr-FR':
      'Cela supprimera définitivement ce member du orge. Cette action ne peut pas être annulée.',
  }),
  invalidCode: defineCopy({
    default: 'Invalid code',
    'de-DE': 'Ungültiger Code',
    'es-ES': 'Código inválido',
    'it-IT': 'Codice non valido',
    'fr-FR': 'Code invalide',
  }),
  thisCodeInvalid: defineCopy({
    default: 'This code is invalid or has expired',
    'de-DE': 'Dieser Code ist ungültig oder abgelaufen',
    'es-ES': 'Este código es inválido o ha expirado',
    'it-IT': 'Questo codice non è valido o è scaduto',
    'fr-FR': 'Ce code est invalide ou a expiré',
  }),
  youveBeenInvited: defineCopy({
    default: "You've been invited to join this organisation",
    'de-DE': 'Sie wurden eingeladen, dieser Gruppe beizutreten',
    'es-ES': 'Has sido invitado a unirte a esta organización',
    'it-IT': 'Sei stato invitato a unirti a questo gruppo',
    'fr-FR': 'Vous avez été invité à rejoindre ce groupe',
  }),
  acceptInvite: defineCopy({
    default: 'Accept Invite',
    'de-DE': 'Einladung annehmen',
    'es-ES': 'Aceptar invitación',
    'it-IT': 'Accetta invito',
    'fr-FR': "Accepter l'invitation",
  }),
  done: defineCopy({
    default: 'Done',
    'de-DE': 'Erledigt',
    'es-ES': 'Hecho',
    'it-IT': 'Fatto',
    'fr-FR': 'Terminé',
  }),
};
